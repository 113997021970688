
<router-outlet></router-outlet>

<app-alert>w</app-alert>

<div class="loader" id="loader">
  <div class="alert">
    <div class="info">
      <div class="info__header" id="loader-title">Идет загрузка...</div>
    </div>
    <div class="loader-el"></div>
  </div>
</div>