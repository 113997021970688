import { AfterViewInit, Component, HostListener } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
  MenuPointComponent,
  ModalComponent,
  SearchComponent,
  SelectComponent,
  WysiwygComponent,
} from '@core/components';
import {
  AlertService,
  ModalService,
  ClassifiersService,
  RegDocsService,
  ServicesService,
} from '@core/services';
import {
  Classifier,
  ClassifiersWithTotalList,
  ClassifierType,
  Document,
  DocumentsList,
  Service,
  ServicesList,
} from '@core/models';
import { CreateServiceDto, UpdateServiceForm,  ServicesSearchQueryDto } from '@core/models/dto';
import {
  ClassifierTypes,
  getDocumentActualVersionHelper,
  getServiceTypeTranslation,
  parsePeriodHelper,
  SelectedClassifiers,
  shouldLoadDataHelper,
} from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { DateInputComponent } from '@core/components/inputs/date-input/date-input.component';
import { TextareaInputComponent } from '@core/components/inputs/textarea-input/textarea-input.component';
import { debounceTime } from 'rxjs';
import { ServicesPageAttentionComponent } from '@scripter-admin/app/services-page/tabs/services-page-attention/services-page-attention.component';
import { ServicesPageListComponent } from '@scripter-admin/app/services-page/tabs/services-page-list/services-page-list.component';
import { ClassifiersEnum, ServicesTypesEnum } from '@core/utils';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';

export type Tab = {
  title: string;
  class: string;
};

interface CreateServiceForm {
  name: FormControl<string>;
  sierNumber: FormControl<string>;
  isNotForTosp: FormControl<boolean>;
  isNotForUrm: FormControl<boolean>;
  title: FormControl<string>;
  type: FormControl<ServicesTypesEnum>;
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
  information: FormControl<string | null>;
  informationProcedure: FormControl<string | null>;
  informationTransferMethod: FormControl<string | null>;
  informationProvisionMethod: FormControl<string | null>;
  informationApplicantCategory: FormControl<string | null>;
  informationApplicantNationality: FormControl<string | null>;
  informationResidenceRequirement: FormControl<string | null>;
  informationApplicationResult: FormControl<string | null>;
  informationServiceResult: FormControl<string | null>;
  informationReceiptOfTheResult: FormControl<string | null>;
  informationAdditional: FormControl<string | null>;
}

interface SearchForm {
  name: FormControl<string>;
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
  isNotForTosp: FormControl<boolean | null>;
  isNotForUrm: FormControl<boolean | null>;
}

type OrderBy = {
  name: string;
  key: string;
  field: string;
  by: 'asc' | 'desc';
};

@Component({
  selector: 'app-services-page',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconButtonComponent,
    SearchComponent,
    InputComponent,
    BadgeComponent,
    NgClass,
    ModalComponent,
    SelectComponent,
    MenuPointComponent,
    WysiwygComponent,
    ReactiveFormsModule,
    TextInputComponent,
    DateInputComponent,
    TextareaInputComponent,
    ServicesPageAttentionComponent,
    ServicesPageListComponent,
    AutocompleteComponent,
    SelectedDocumentsComponent
  ],
  templateUrl: './service-page.component.html',
  styleUrl: './service-page.component.scss',
  providers: [AlertService, AlertComponent],
})
export class ServicePageComponent implements AfterViewInit {
  public createServiceForm: FormGroup;
  public updateServiceForm: FormGroup;
  public searchForm: FormGroup;
  public cloneServiceForm: FormGroup;
  public isSearchBlocked = false;
  public services: Service[] = [];
  public archivedServices: Service[] = [];
  public needAttention: Service[] = [];
  public openedClassifiersInput: string[] = [];
  public selectedClassifiers: SelectedClassifiers = new SelectedClassifiers();
  public nameError: string = '';
  public titleError: string = '';
  public sierError = '';
  public selectedClassifiersToSearch: SelectedClassifiers =
    new SelectedClassifiers();
  public isFiltered: boolean = false;
  public editingServiceId!: number;
  public countFilters: Map<string, number> = new Map<string, number>();
  public orderByList: OrderBy[] = [
    {
      name: 'Сперва новые',
      field: 'createdAt',
      key: 'createdAt_desc',
      by: 'desc',
    },
    {
      name: 'Сперва старые',
      field: 'createdAt',
      key: 'createdAt_asc',
      by: 'asc',
    },
  ];
  public selectedOrder: OrderBy = this.orderByList[0];
  public isOrderOpened: boolean = false;
  public isBlockedSelectedOrder: boolean = false;
  private searchDto: ServicesSearchQueryDto | null = null;

  public classifiersTypes: ClassifierType[] = [
    {
      name: 'Жизненные ситуации',
      key: ClassifiersEnum.lifeSituations,
      opened: true,
    },
    {
      name: 'Категория заявителей',
      key: ClassifiersEnum.applicantCategories,
      opened: true,
    },
    {
      name: 'Территориальная принадлежность',
      key: ClassifiersEnum.territoriality,
      opened: true,
    },
    {
      name: 'Подразделение',
      key: ClassifiersEnum.departments,
      opened: true,
    },
  ];

  public toggleOpened(key: string): void {
    const type = this.classifiersTypes.find(
      type => type.key === key
    ) as ClassifierType;
    type.opened = !type.opened;
  }

  public parseActivity = parsePeriodHelper;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private readonly servicesService: ServicesService,
    private readonly alertService: AlertService,
    private readonly classifierService: ClassifiersService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly regDocsService: RegDocsService
  ) {
    this.createServiceForm = new FormGroup<CreateServiceForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      sierNumber: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      type: new FormControl(ServicesTypesEnum.Federal, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      isNotForTosp: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      isNotForUrm: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      information: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationProcedure: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationTransferMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationProvisionMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicantCategory: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicantNationality: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationResidenceRequirement: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicationResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationServiceResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationReceiptOfTheResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationAdditional: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });

    this.searchForm = new FormGroup<SearchForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      isNotForTosp: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      isNotForUrm: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });

    this.updateServiceForm = new FormGroup<UpdateServiceForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      sierNumber: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      isNotForTosp: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      isNotForUrm: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      information: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationProcedure: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationTransferMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationProvisionMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicantCategory: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicantNationality: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationResidenceRequirement: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationApplicationResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationServiceResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationReceiptOfTheResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      informationAdditional: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });

    this.cloneServiceForm = new FormGroup<UpdateServiceForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      sierNumber: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      type: new FormControl(ServicesTypesEnum.Federal, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      isNotForTosp: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      isNotForUrm: new FormControl(false, {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });

    this.isFullData.set('tabs__active', false);
    this.isFullData.set('tabs__archive', false);
  }

  private isLoaded: boolean = false;
  private isFullData: Map<string, boolean> = new Map<string, boolean>();
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (
      shouldLoadDataHelper() &&
      !this.isLoaded &&
      !this.isFullData.get(this.currentTab)
    ) {
      this.isLoaded = true;
      this.servicesService
        .getServices({
          ...this.getFilterData(),
          from:
            this.currentTab === 'tabs__actual'
              ? this.services.length
              : this.archivedServices.length,
          to:
            this.currentTab === 'tabs__actual'
              ? this.services.length + 5
              : this.archivedServices.length + 5,
        })
        .subscribe({
          next: (servicesList: ServicesList) => {
            if (this.currentTab === 'tabs__actual') {
              if (servicesList.active.length === 0) {
                this.isFullData.set(this.currentTab, true);
              } else {
                this.services = this.services.concat(servicesList.active);
              }
            } else {
              if (servicesList.archive.length === 0) {
                this.isFullData.set(this.currentTab, true);
              } else {
                this.archivedServices = this.archivedServices.concat(
                  servicesList.archive
                );
              }
            }
            this.isLoaded = false;
          },
        });
    }
  }

  public cloneSearchForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });

  public createServiceDocumentsForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ services }) => {
      this.services = services.active;
      this.archivedServices = services.archive;
      this.needAttention = services.needAttention;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
    this.cloneSearchForm.get('name')!.valueChanges.subscribe((value: any) => {
      this.handleSearchServicesToClone(value);
    });
  }

  public control<T>(
    name: string,
    form:
      | 'search'
      | 'create'
      | 'clone'
      | 'form'
      | 'update'
      | 'cloneService' = 'create'
  ): FormControl<T> {
    switch (form) {
      case 'search':
        return this.searchForm.controls[name] as FormControl<T>;
      case 'create':
        return this.createServiceForm.controls[name] as FormControl<T>;
      case 'clone':
        return this.cloneSearchForm.controls['name'] as FormControl<T>;
      case 'form':
        return this.createServiceDocumentsForm.controls[
          'name'
        ] as FormControl<T>;
      case 'update':
        return this.updateServiceForm.controls[name] as FormControl<T>;
      case 'cloneService':
        return this.cloneServiceForm.controls[name] as FormControl<T>;
    }
  }

  private checkErrors(create: boolean): boolean {
    const values = create
      ? this.createServiceForm.value
      : this.updateServiceForm.value;

    let result = true;
    if (values.name.length === 0 || values.name.replace(' ', '').length === 0) {
      this.nameError = 'Полное наименование не должно быть пустым';
      result = false;
    }
    if (
      values.title.length === 0 ||
      values.title.replace(' ', '').length === 0
    ) {
      this.titleError = 'Краткое наименование не должно быть пустым';
      result = false;
    }
    return result;
  }

  public resetErrors(): void {
    this.nameError = '';
    this.titleError = '';
    this.sierError = '';
  }

  public onFormSubmit() {
    const values = this.createServiceForm.value;
    if (!this.checkErrors(true)) {
      return;
    }

    const requestData: CreateServiceDto = {
      name: values.name,
      sierNumber: values.sierNumber,
      title: values.title,
      type: this.selectedServiceType,
      activityStart: values.activityStart
        ? new Date(values.activityStart).toISOString()
        : undefined,
      activityEnd: values.activityEnd
        ? new Date(values.activityEnd).toISOString()
        : undefined,
      isNotForTosp: values.isNotForTosp,
      isNotForUrm: values.isNotForUrm,
      information: values.information,
      informationProcedure: values.informationProcedure,
      informationTransferMethod: values.informationTransferMethod,
      informationProvisionMethod: values.informationProvisionMethod,
      informationApplicantCategory: values.informationApplicantCategory,
      informationApplicantNationality: values.informationApplicantNationality,
      informationResidenceRequirement: values.informationResidenceRequirement,
      informationApplicationResult: values.informationApplicationResult,
      informationServiceResult: values.informationServiceResult,
      informationReceiptOfTheResult: values.informationReceiptOfTheResult,
      informationAdditional: values.informationAdditional,
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      documentsIds: this.documentsToAdd.map((doc: Document) => doc.id),
    };

    this.servicesService.createService(requestData).subscribe({
      next: (service: Service) => {
        this.services = [service].concat(this.services);
        this.isAbout  = true;
        this.modalService.close();
        this.alertService.toggle('Услуга добавлена');
        this.createServiceForm.reset();
        this.selectedClassifiers.reset();
      },
      error: (err: Error) => console.error(err),
    });
  }

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
    {
      title: 'Требуют внимания',
      class: 'tabs__need-attention',
    },
  ];

  public lifeSituations: Classifier[] = [];
  public lifeSituationsCount: number = 0;
  public applicantCategories: Classifier[] = [];
  public applicantCategoriesCount: number = 0;
  public departments: Classifier[] = [];
  public departmentsCount: number = 0;
  public territoriality: Classifier[] = [];
  public territorialityCount: number = 0;

  public relatedDocuments: Document[] = [];
  public documentsToAdd: Document[] = [];

  public isAbout: boolean = true;

  public activeArray: boolean[] = [
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  public handleMenuItemClick(count: number) {
    this.activeArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.activeArray[count] = true;
  }

  public currentTab: string = this.tabs[0].class;

  public handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__need-attention':
        document
          .querySelector('.tabs__need-attention')
          ?.classList.add('tab_active');
        this.currentTab = 'tabs__need-attention';
        break;
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  public handleModalOpen(id: string) {
    this.selectedClassifiers.reset();
    this.selectedServiceType = ServicesTypesEnum.Federal;
    this.createServiceForm.patchValue({
      isNotForTosp: false,
      isNotForUrm: false,
    });
    this.documentsToAdd = [];
    this.searchDocuments('');
    this.classifierService.getClassifiers().subscribe({
      next: (classifiers: ClassifiersWithTotalList) => {
        this.applicantCategories = classifiers.applicantCategories.data;
        this.applicantCategoriesCount = classifiers.applicantCategories.total;
        this.departments = classifiers.departments.data;
        this.departmentsCount = classifiers.departments.total;
        this.territoriality = classifiers.territoriality.data;
        this.territorialityCount = classifiers.territoriality.total;
        this.lifeSituations = classifiers.lifeSituations.data;
        this.lifeSituationsCount = classifiers.lifeSituations.total;
        this.modalService.open(id);
        this.suggestionClassifiers.lifeSituations = classifiers.lifeSituations.data;
        this.suggestionClassifiers.applicantCategories = classifiers.applicantCategories.data;
        this.suggestionClassifiers.departments = classifiers.departments.data;
        this.suggestionClassifiers.territoriality = classifiers.territoriality.data;

      },
      error: (err: Error) => console.log(err),
    });
  }

  public getWysiwygDisplay(index: number): string {
    return `display: ${this.activeArray[index] ? 'unset' : 'none'}`;
  }

  public handleSearch(query: string): void {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.servicesService
        .getServices({
          name: query,
          from: 0,
          to: 5,
        })
        .subscribe({
          next: (services: ServicesList) => {
            this.services = services.active;
            this.archivedServices = services.archive;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  public getClassifiersListByType(type: ClassifierTypes): Classifier[] {
    return this[type];
  }

  public getClassifiersTotalByType(type: ClassifierTypes): number {
    switch (type) {
      case ClassifiersEnum.applicantCategories:
        return this.applicantCategoriesCount;
      case ClassifiersEnum.lifeSituations:
        return this.lifeSituationsCount;
      case ClassifiersEnum.departments:
        return this.departmentsCount;
      case ClassifiersEnum.territoriality:
        return this.territorialityCount;
    }
    return 0;
  }

  public handleFiltersResetClick(): void {
    this.searchForm.reset();
    this.selectedClassifiersToSearch.reset();
    this.servicesService.getServices({ from: 0, to: 5 }).subscribe({
      next: (services: ServicesList) => {
        this.services = services.active;
        this.archivedServices = services.archive;
        this.isFiltered = false;
        this.isFullData.clear();
        this.isLoaded = false;
        this.alertService.toggle('Фильтр сброшен');
      },
    });
  }

  public handleSelectSearchClassifierClick(
    type: ClassifierTypes,
    classifier: Classifier
  ): void {
    const ids = this.selectedClassifiersToSearch.getIds(type);
    if (!ids.includes(classifier.id)) {
      if (this.countFilters.get(type)) {
        this.countFilters.set(
          type,
          (this.countFilters.get(type) as number) + 1
        );
      } else {
        this.countFilters.set(type, 1);
      }
      this.selectedClassifiersToSearch.add(type, classifier);
    } else {
      if (this.countFilters.get(type)) {
        this.countFilters.set(
          type,
          (this.countFilters.get(type) as number) - 1
        );
      } else {
        this.countFilters.set(type, 0);
      }
      this.selectedClassifiersToSearch.remove(type, classifier);
    }
  }

  public getFilterData(): ServicesSearchQueryDto {
    return {
      ...this.searchForm.value,
      applicantCategoriesIds: this.selectedClassifiersToSearch.getIds(
        'applicantCategories'
      ),
      lifeSituationsIds:
        this.selectedClassifiersToSearch.getIds('lifeSituations'),
      departmentsIds: this.selectedClassifiersToSearch.getIds('departments'),
      territorialityIds:
        this.selectedClassifiersToSearch.getIds('territoriality'),
      orderBy: {
        field: this.selectedOrder.field,
        by: this.selectedOrder.by,
      },
      type: Array.from(this.selectedServicesTypes),
    };
  }

  public handleSearchSubmitClick(): void {
    const data: ServicesSearchQueryDto = this.getFilterData();
    this.searchDto = data;
    this.servicesService.getServices(data).subscribe({
      next: (services: ServicesList) => {
        this.isFiltered = true;
        this.services = services.active;
        this.archivedServices = services.archive;
        this.modalService.close();
        this.alertService.toggle('Фильтр применён');
      },
      error: (err: Error) => console.error(err),
    });
  }

  public handleSearchResetClick(): void {
    this.searchForm.reset();
    this.selectedClassifiersToSearch.reset();
    this.selectedOrder = this.orderByList[0];
    this.countFilters = new Map();
    this.selectedServicesTypes = new Set();
  }

  public handleChangeOrderClick(order?: OrderBy): void {
    if (this.isBlockedSelectedOrder) return;
    this.isOrderOpened = !this.isOrderOpened;
    if (!order || order.key === this.selectedOrder.key) return;
    this.selectedOrder = order;
    this.isBlockedSelectedOrder = true;
    const data: ServicesSearchQueryDto = this.searchDto
      ? {
          ...this.searchDto,
          orderBy: {
            field: this.selectedOrder.field,
            by: this.selectedOrder.by,
          },
        }
      : this.getFilterData();
    this.servicesService.getServices(data).subscribe({
      next: (services: ServicesList) => {
        this.services = services.active;
        this.archivedServices = services.archive;
        this.isBlockedSelectedOrder = false;
        this.alertService.toggle('Сортировка изменена');
      },
      error: (err: Error) => {
        this.isBlockedSelectedOrder = false;
        console.error(err);
      },
    });
  }

  get orders(): OrderBy[] {
    return this.orderByList.filter(
      (orderBy: OrderBy) => orderBy.key !== this.selectedOrder.key
    );
  }

  protected readonly name = name;
  public selectedServiceType: ServicesTypesEnum = ServicesTypesEnum.Federal;
  public handleChangeServiceTypeClick(
    type: ServicesTypesEnum,
    form: 'create' | 'update' | 'clone'
  ): void {
    switch (form) {
      case 'create':
        this.createServiceForm.get('type')?.setValue(type);
        this.selectedServiceType = type;
        break;
      case 'update':
        this.updateServiceForm.get('type')?.setValue(type);
        this.selectedServiceType = type;
        break;
      case 'clone':
        this.cloneServiceForm.get('type')?.setValue(type);
        this.selectedServiceType = type;
    }
  }

  public selectedServicesTypes: Set<ServicesTypesEnum> = new Set();
  public handleSelectServiceType(type: ServicesTypesEnum): void {
    if (!this.selectedServicesTypes.has(type)) {
      this.selectedServicesTypes.add(type);
    } else {
      this.selectedServicesTypes.delete(type);
    }
  }

  public resetForm(): void {
    this.isAbout = true;
    this.createServiceForm.reset();
    this.updateServiceForm.reset();
    this.cloneServiceForm.reset();
    this.serviceToClone = null;
    this.selectedServiceType = ServicesTypesEnum.Federal;
    this.resetErrors();
  }

  public serviceToClone: Service | null = null;
  public servicesToClone: Service[] = [];

  public onCloneClick(): void {
    this.serviceToClone = null;
    this.cloneSearchControl.patchValue('');
    this.handleModalOpen('clone-service-modal');
  }

  private handleSearchServicesToClone(name: string): void {
    this.servicesService
      .getServices({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (services: ServicesList) => {
          this.servicesToClone = services.active;
        },
        error: (err: Error) => console.error(err),
      });
  }

  public handleSelectCloneServiceClick(service: Service): void {
    this.serviceToClone = service;
    this.servicesToClone = [];

    this.cloneServiceForm.patchValue({
      name: service.name,
      title: service.title,
      sierNumber: service.sierNumber,
      activityStart: service.activityStart?.slice(0, 10),
      activityEnd: service.activityEnd?.slice(0, 10),
      type: service.type,
      isNotForTosp: service.isNotForTosp,
      isNotForUrm: service.isNotForUrm,
    });
    this.selectedServiceType = service.type;
    this.documentsToAdd = service!.documents ?? [];

    this.selectedClassifiers.reset();
    this.selectedClassifiers.insert(
      'lifeSituations',
      service!.lifeSituations.map(item => item.lifeSituation) ?? []
    );
    this.selectedClassifiers.insert(
      'applicantCategories',
      service!.applicantCategories.map(item => item.applicantCategory) ?? []
    );
    this.selectedClassifiers.insert(
      'departments',
      service!.departments.map(item => item.department) ?? []
    );
    this.selectedClassifiers.insert(
      'territoriality',
      service!.territoriality.map(item => item.territoriality) ?? []
    );
  }

  public handleResetCloneServiceClick(): void {
    this.serviceToClone = null;
    this.servicesToClone = [];
    this.cloneSearchForm.reset();
  }

  public onCloneSubmitClick(): void {
    const form = this.cloneServiceForm.value;
    const requestData = {
      name: form.name,
      title: form.title,
      sierNumber: form.sierNumber,
      type: form.type,
      activityStart: form.activityStart ? new Date(form.activityStart).toISOString() : undefined,
      activityEnd: form.activityEnd ? new Date(form.activityEnd).toISOString() : undefined,
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      documentsIds: this.documentsToAdd.map((doc: Document) => doc.id),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      isNotForTosp: form.isNotForTosp,
      isNotForUrm: form.isNotForUrm,
    };

    if (this.serviceToClone) {
      this.servicesService
        .cloneService(this.serviceToClone.id, requestData)
        .subscribe({
          next: (service: Service) => {
            this.modalService.close();
            this.alertService.toggle('Услуга скопирована');
            this.router.navigate([`/services/${service.id}`]);
          },
          error: (err: Error) => console.error(err),
        });
    }
  }

  public get cloneSearchControl(): FormControl<string> {
    return this.cloneSearchForm.get('name') as FormControl<string>;
  }

  public isSearchClassifiersBlocked: boolean = false;
  public classifiersCountStep: number = 3;

  public showMoreClick(classifierType: ClassifierType): void {
    if (!this.isSearchClassifiersBlocked) {
      this.isSearchClassifiersBlocked = true;
      this.classifierService
        .getClassifiers({
          [classifierType.key]: {
            from: 0,
            to: this[classifierType.key].length + this.classifiersCountStep,
          },
        })
        .subscribe({
          next: (classifiers: ClassifiersWithTotalList) => {
            this[classifierType.key] = classifiers[classifierType.key].data;
            this.isSearchClassifiersBlocked = false;
          },
        });
    }
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.onFormSubmit();
  }

  openUpdateModal(id: number): void {
    this.handleModalOpen('update-service-modal');
    this.editingServiceId = id;
    const service = this.services.find(service => service.id === id);
    this.updateServiceForm.patchValue({
      name: service!.name,
      title: service!.title,
      type: service?.type,
      sierNumber: service!.sierNumber,
      activityStart: service!.activityStart?.slice(0, 10) ?? '',
      activityEnd: service!.activityEnd?.slice(0, 10) ?? '',
      isNotForTosp: service?.isNotForTosp,
      isNotForUrm: service?.isNotForUrm,
      information: service!.information,
      informationProcedure: service!.informationProcedure,
      informationTransferMethod: service!.informationTransferMethod,
      informationProvisionMethod: service!.informationProvisionMethod,
      informationApplicantCategory: service!.informationApplicantCategory,
      informationApplicantNationality: service!.informationApplicantNationality,
      informationResidenceRequirement: service!.informationResidenceRequirement,
      informationApplicationResult: service!.informationApplicationResult,
      informationServiceResult: service!.informationServiceResult,
      informationReceiptOfTheResult: service!.informationReceiptOfTheResult,
      informationAdditional: service!.informationAdditional,
    });
    this.selectedServiceType = service!.type ?? 'Federal';
    this.documentsToAdd = service!.documents ?? [];

    this.selectedClassifiers.reset();
    this.selectedClassifiers.insert(
      'lifeSituations',
      service!.lifeSituations.map(item => item.lifeSituation) ?? []
    );
    this.selectedClassifiers.insert(
      'applicantCategories',
      service!.applicantCategories.map(item => item.applicantCategory) ?? []
    );
    this.selectedClassifiers.insert(
      'departments',
      service!.departments.map(item => item.department) ?? []
    );
    this.selectedClassifiers.insert(
      'territoriality',
      service!.territoriality.map(item => item.territoriality) ?? []
    );
  }

  handleUpdateService(): void {
    const values = this.updateServiceForm.value;
    if (!this.checkErrors(false)) {
      return;
    }

    const requestData = {
      name: values.name,
      title: values.title,
      sierNumber: values.sierNumber,
      type: this.selectedServiceType,
      activityStart: new Date(values.activityStart),
      activityEnd: new Date(values.activityEnd),
      isNotForTosp: values.isNotForTosp,
      isNotForUrm: values.isNotForUrm,
      information: values.information,
      informationProcedure: values.informationProcedure,
      informationTransferMethod: values.informationTransferMethod,
      informationProvisionMethod: values.informationProvisionMethod,
      informationApplicantCategory: values.informationApplicantCategory,
      informationApplicantNationality: values.informationApplicantNationality,
      informationResidenceRequirement: values.informationResidenceRequirement,
      informationApplicationResult: values.informationApplicationResult,
      informationServiceResult: values.informationServiceResult,
      informationReceiptOfTheResult: values.informationReceiptOfTheResult,
      informationAdditional: values.informationAdditional,
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      documentsIds: this.documentsToAdd.map((doc: Document) => doc.id),
    };

    this.servicesService
      .updateService(this.editingServiceId, requestData)
      .subscribe({
        next: () => {
          this.servicesService.getServices().subscribe({
            next: services => {
              this.services = services.active;
            },
          });
          this.updateServiceForm.reset();
          this.modalService.close();
          this.alertService.toggle('Услуга изменена');
        },
        error: (err: Error) => console.error(err),
      });
  }

  protected readonly Array = Array;
  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;
  protected readonly ServicesTypesEnum = ServicesTypesEnum;
  protected readonly getDocumentActualVersionHelper =
    getDocumentActualVersionHelper;


  suggestionDocuments = new Array<Document>();

  searchDocuments(name: string){
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          const selectedIds = this.documentsToAdd.map((doc) => doc.id);
          this.suggestionDocuments = this.relatedDocuments = documents.active.data.filter(
            doc => !selectedIds.includes(doc.id)
          );
        },
        error: (err: Error) => console.error(err),
      });
  }

  getDocumentName(doc: Document): string {
    return doc.title;
  }

  addDocument(doc: Document){
    if (this.documentsToAdd.indexOf(doc) < 0) {
      this.documentsToAdd.push(doc);
      this.suggestionDocuments.splice(this.suggestionDocuments.indexOf(doc), 1);
    }
  }

  removeDocument(doc: Document){
    if(this.documentsToAdd.indexOf(doc) >= 0){
      this.documentsToAdd.splice(this.documentsToAdd.indexOf(doc), 1);
      this.suggestionDocuments.push(doc);
    }
  }

  suggestionClassifiers = {
    lifeSituations: new Array<Classifier>(),
    applicantCategories: new Array<Classifier>(),
    departments: new Array<Classifier>(),
    territoriality: new Array<Classifier>()
  }

  searchClassifiers(type: ClassifierTypes, name: string){
    this.classifierService.getClassifiers({ name })
      .subscribe((result) => {
        const selectedIds = this.selectedClassifiers.getIds(type);
        this.suggestionClassifiers[type] = result[type].data.filter((item) => !selectedIds.includes(item.id));
      });
  }

  getClassifierName(classifier: Classifier): string {
    return classifier.name;
  }

  addClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.add(type, classifier as Classifier);
    this.suggestionClassifiers[type].splice(this.suggestionClassifiers[type].indexOf(classifier), 1);
  }

  removeClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.remove(type, classifier);
    this.suggestionClassifiers[type].push(classifier);
  }


}
