import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subdivision } from '@core/models';
import { SearchSubdivisionsDto } from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class SubdivisionsService {
  constructor(private readonly http: HttpClient) {}

  getSubdivisions(searchDto: SearchSubdivisionsDto): Observable<Subdivision[]> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }

    let uri = '/subdivisions';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<Subdivision[]>(uri);
  }
}
