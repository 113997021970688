import { ServicesTypesEnum } from '@core/utils';

export const getServiceTypeTranslation = (type: ServicesTypesEnum) => {
  switch (type) {
    case ServicesTypesEnum.Federal:
      return 'Федеральный';
    case ServicesTypesEnum.Regional:
      return 'Региональный';
    default:
      return 'Муниципальный';
  }
};
