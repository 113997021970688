@if (shouldNavigate) {
  @if (link) {
    <div
      class="menu-point"
      [ngClass]="isActive ? 'menu-point_active' : ''"
      [routerLink]="[link]"
      routerLinkActive="true">
      @if (icon) {
        <img
          class="menu-point__icon"
          [ngClass]="isActive ? 'menu-point__icon_active' : ''"
          src="{{ icon }}"
          alt="icon" />
      }
      <p
        class="menu-point__text"
        [ngClass]="isActive ? 'menu-point__text_active' : ''">
        {{ text }}
      </p>
    </div>
  } @else {
    <div
      class="menu-point {{ type }}-padding"
      [ngClass]="isActive ? 'menu-point_active' : ''"
      routerLinkActive="true">
      @if (icon) {
        <img
          class="menu-point__icon"
          [ngClass]="isActive ? 'menu-point__icon_active' : ''"
          src="{{ icon }}"
          alt="icon" />
      }
      <p
        class="menu-point__text"
        [ngClass]="isActive ? 'menu-point__text_active' : ''">
        {{ text }}
      </p>
    </div>
  }
} @else {
  <div
    class="menu-point {{ type }}-padding {{ wrap }}"
    [ngClass]="isActive ? 'menu-point_active' : ''"
    routerLinkActive="true">
    @if (icon) {
      <img
        class="menu-point__icon"
        [ngClass]="isActive ? 'menu-point__icon_active' : ''"
        src="{{ icon }}"
        alt="icon" />
    }
    <p
      class="menu-point__text"
      [ngClass]="isActive ? 'menu-point__text_active' : ''">
      {{ text }}
    </p>
  </div>
}
