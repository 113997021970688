import { Component, ViewEncapsulation } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
  ModalComponent,
  SearchComponent,
  SelectComponent,
} from '@core/components';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { AlertService, ModalService, ClassifiersService } from '@core/services';
import {
  Classifier,
  ClassifiersWithTotalList,
  ClassifierType,
} from '@core/models';
import { ClassifiersEnum, SubdivisionTypesEnum } from '@core/utils';
import { ClassifiersPositionType } from '@core/types';

type CreateClassifierForm = {
  name: FormControl<string>;
};

@Component({
  selector: 'app-classifier-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    SearchComponent,
    InputComponent,
    BadgeComponent,
    NgClass,
    ModalComponent,
    ReactiveFormsModule,
    SelectComponent,
    NgOptimizedImage,
    TextInputComponent,
    FormsModule,
    CdkDrag,
    CdkDropList,
  ],
  templateUrl: './classifiers.component.html',
  styleUrl: './classifiers.component.scss',
  providers: [AlertService, AlertComponent],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ClassifiersComponent {
  public isCreate: boolean = true;
  public selectedClassifier!: Classifier & { type: ClassifierType };
  public selectedClassifierType!: ClassifierType;
  public isSubmitted: boolean = false;
  public inputError: string = '';
  public selectError: string = '';
  public createClassifierForm: FormGroup<CreateClassifierForm>;
  public subdivisionType: FormControl;
  public classifiers: ClassifiersWithTotalList = {
    [ClassifiersEnum.applicantCategories]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.departments]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.lifeSituations]: {
      total: 0,
      data: [],
    },
    [ClassifiersEnum.territoriality]: {
      total: 0,
      data: [],
    },
  };
  public classifiersCountStep: number = 3;
  public isSearchClassifiersBlocked: boolean = false;

  public classifiersTypes: ClassifierType[] = [
    {
      name: 'Жизненные ситуации',
      key: ClassifiersEnum.lifeSituations,
      opened: true,
    },
    {
      name: 'Категория заявителей',
      key: ClassifiersEnum.applicantCategories,
      opened: true,
    },
    {
      name: 'Территориальная принадлежность',
      key: ClassifiersEnum.territoriality,
      opened: true,
    },
    {
      name: 'Подразделение',
      key: ClassifiersEnum.departments,
      opened: true,
    },
  ];

  constructor(
    private readonly modalService: ModalService,
    private readonly classifiersService: ClassifiersService,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.createClassifierForm = new FormGroup<CreateClassifierForm>({
      name: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
    this.subdivisionType = new FormControl<string | undefined>(undefined);
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      ({ classifiers }) => (this.classifiers = classifiers)
    );
  }

  private checkErrors(): boolean {
    let result = true;
    const requestData = this.createClassifierForm.value;
    if (!requestData.name || requestData.name.length < 1) {
      this.inputError = 'Название не может быть пустым';
      result = false;
    }

    return result;
  }

  submitEvent(): void {
    if (!this.checkErrors()) return;

    const requestData = {
      name: this.createClassifierForm.value.name as string,
      type: this.selectedClassifierType.key as ClassifiersEnum,
      extra:
        this.selectedClassifierType.key === ClassifiersEnum.departments
          ? {
              type: this.subdivisionType.value,
            }
          : undefined,
    };
    if (this.isCreate) {
      this.classifiersService.createClassifier(requestData).subscribe({
        next: (classifier: Classifier) => {
          this.classifiers[requestData.type].data.unshift(classifier);
          this.modalService.close();
          this.createClassifierForm.reset();
          this.alertService.toggle('Классификатор добавлен');
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
    } else {
      this.classifiersService
        .updateClassifier(this.selectedClassifier.id, requestData)
        .subscribe({
          next: (classifier: Classifier) => {
            const classifierIndex = this.classifiers[
              this.selectedClassifier.type.key
            ].data.findIndex(c => c.id === this.selectedClassifier.id);

            if (classifierIndex >= 0)
              this.classifiers[this.selectedClassifier.type.key].data[
                classifierIndex
              ] = classifier;
            this.modalService.close();
            this.createClassifierForm.reset();
            this.alertService.toggle('Классификатор изменен');
          },
          error: (err: Error) => {
            console.error(err);
          },
        });
    }
  }

  toggleOpened(key: string): void {
    const type = this.classifiersTypes.find(
      type => type.key === key
    ) as ClassifierType;
    type.opened = !type.opened;
  }

  handleUpdateClassifierClick(
    classifier: Classifier,
    type: ClassifierType
  ): void {
    this.selectedClassifier = {
      ...classifier,
      type: type,
    };
    this.selectedClassifierType = type;
    this.isCreate = false;
    this.resetErrors();
    this.subdivisionType.reset();
    this.createClassifierForm.reset();
    this.createClassifierForm.setValue({
      name: this.selectedClassifier.name,
    });
    this.modalService.open('add-classifier-modal');
  }

  addClick(classifierType: ClassifierType): void {
    this.resetErrors();
    this.subdivisionType.reset();
    this.isCreate = true;
    this.createClassifierForm.reset();
    this.selectedClassifierType = classifierType;
    this.modalService.open('add-classifier-modal');
  }

  showMoreClick(classifierType: ClassifierType): void {
    if (!this.isSearchClassifiersBlocked) {
      this.isSearchClassifiersBlocked = true;
      this.classifiersService
        .getClassifiers({
          [classifierType.key]: {
            from: 0,
            to:
              this.classifiers[classifierType.key].data.length +
              this.classifiersCountStep,
          },
        })
        .subscribe({
          next: (classifiers: ClassifiersWithTotalList) => {
            this.classifiers[classifierType.key] =
              classifiers[classifierType.key];
            this.isSearchClassifiersBlocked = false;
          },
        });
    }
  }

  get name(): FormControl<string> {
    return this.createClassifierForm.get('name') as FormControl<string>;
  }

  resetErrors(): void {
    this.resetInputError();
    this.resetSelectError();
  }

  resetInputError(): void {
    this.inputError = '';
  }

  resetSelectError(): void {
    this.selectError = '';
  }

  onDeleteClick(id: number, type: ClassifiersEnum, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить классификатор "${name}"?`
    );

    if (isAccepted) {
      this.classifiersService.deleteClassifier(id, { type }).subscribe({
        next: () => {
          this.classifiers[type].data = this.classifiers[type].data.filter(
            classifier => classifier.id !== id
          );
          this.alertService.toggle('Классификатор удален');
        },
        error: (err: Error) => console.error(err),
      });
    } else return;
  }

  selectedSubdivisionType: SubdivisionTypesEnum =
    SubdivisionTypesEnum.Department;
  subdivisionsTypesList: { value: SubdivisionTypesEnum; label: string }[] = [
    {
      value: SubdivisionTypesEnum.Affiliate,
      label: 'Филиал',
    },
    {
      value: SubdivisionTypesEnum.Department,
      label: 'Отделение',
    },
    {
      value: SubdivisionTypesEnum.URM,
      label: 'УРМ',
    },
    {
      value: SubdivisionTypesEnum.TOSP,
      label: 'ТОСП',
    },
    {
      value: SubdivisionTypesEnum.BusinessOffice,
      label: 'Бизнес офис',
    },
  ];

  public handleChangeSubdivisionType(type: {
    name: string;
    key: SubdivisionTypesEnum;
  }): void {
    this.selectedSubdivisionType = type.key;
  }

  public getSubdivisionByKey(key: ClassifiersEnum): {
    name: string;
    key: SubdivisionTypesEnum;
  } {
    const element = this.subdivisionsTypesList.find(
      subdivision =>
        key.toUpperCase().indexOf(subdivision.value.toUpperCase()) !== -1
    );

    if (!element) {
      throw new Error();
    }

    return {
      name: element.label,
      key: element.value,
    };
  }

  public isDropBlocked: boolean = false;
  public currentPositions: ClassifiersPositionType[] = [];
  public drop(type: ClassifiersEnum, event: CdkDragDrop<Classifier[]>): void {
    if (this.isDropBlocked) {
      return;
    }
    moveItemInArray(
      this.classifiers[type].data,
      event.previousIndex,
      event.currentIndex
    );
    this.currentPositions = this.classifiers[type].data.map(
      (classifier: Classifier, position: number) => ({
        id: classifier.id,
        position,
      })
    );
    this.isDropBlocked = true;
    this.classifiersService
      .updateClassifiersPositions(type, this.currentPositions)
      .subscribe({
        next: (classifiers: Classifier[]) => {
          this.classifiers[type].data = classifiers.slice(
            0,
            this.classifiers[type].data.length
          );
          this.isDropBlocked = false;
        },
        error: (err: Error) => {
          console.error(err);
          this.isDropBlocked = false;
        },
      });
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.submitEvent();
  }

  protected readonly ClassifiersEnum = ClassifiersEnum;
}
