import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { EMPTY } from 'rxjs';

import { routes } from './app.routes';
import { apiInterceptor, errorInterceptor, tokenInterceptor, loaderInterceptor } from '@core/interceptors';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { JwtService, UserService, LoaderService } from '@core/services';

export function initAuth(jwtService: JwtService, userService: UserService) {
  return () => (jwtService.getToken() ? userService.getCurrentUser() : EMPTY);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(
      withInterceptors([apiInterceptor, tokenInterceptor, errorInterceptor, loaderInterceptor])),
      {
        provide: APP_INITIALIZER,
        useFactory: initAuth,
        deps: [JwtService, UserService, LoaderService],
        multi: true,
      },
  ],
};
