import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { ClientTopMenuComponent } from '@core/components/overlay/client-top-menu/client-top-menu.component';
import { TopMenuComponent } from '@core/components/overlay/top-menu/top-menu.component';
import { LeftMenuComponent } from '@core/components/overlay/left-menu/left-menu.component';
import { UserService } from '@core/services';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-overlay',
  standalone: true,
  imports: [
    LeftMenuComponent,
    TopMenuComponent,
    ClientTopMenuComponent,
    NgClass,
  ],
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent {
  constructor(private readonly userService: UserService) {
    this.userService.isAuthenticated.subscribe({
      next: isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
      },
    });
  }

  isAdmin = input<boolean>(true);
  isAuthenticated: boolean = false;
}

