import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MenuPointComponent } from '@core/components';
import { Service } from '@core/models';

@Component({
  selector: 'app-service-reference',
  standalone: true,
  imports: [
    MenuPointComponent,
  ],
  templateUrl: './service-reference.component.html',
  styleUrl: './service-reference.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceReferenceComponent {
  service = input.required<Service>();
  public activeArray: boolean[] = [
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  handleMenuItemClick(count: number) {
    this.activeArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.activeArray[count] = true;
  }
}
