import { ButtonComponent } from '@core/components';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-header',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title: string = 'Услуга';
  @Input() buttonText: string = 'Сохранить изменения';
  @Input() redirectUrl: string = '/services';

  @Output() saveClick = new EventEmitter();
  @Output() exitClick = new EventEmitter();

  constructor(protected router: Router) {}

  onSaveClick() {
    this.saveClick.emit();
  }
}
