export function ParseDataToFormHelper<T>(data: any, files: File[]) {
  const form = new FormData();
  for (const file of files) {
    form.append('files', file);
  }
  for (const key of Object.keys(data)) {
    if (![undefined, null].includes(data[key as keyof T] as never)) {
      form.set(key, data[key as keyof T] as string);
    }
  }
  return form;
}
