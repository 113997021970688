<div class="wrapper">
  <div class="card-list">
    @for (document of documentHistory(); track document.id) {
      <a class="card-item" (click)="handleRegDocClick(document)">
        <span class="card-item__counter on-container-lowest text-big">
          @if (document.id) {
            {{ (document.id < 10 ? '0' + document.id : document.id) + '.' }}
          }
        </span>
        <span class="card-item__info">
          <span class="info-header">
            <span class="info-title on-container subtitle-big">
              {{ title() }} <span class="actual">{{document.actual ? '(Актуальный)' : ''}}</span>
            </span>
            @if (document.attachments.length) {
              <span class="material-symbols-outlined on-container-lowest">
                    @if (selected && selected.id === document.id) {
                      keyboard_arrow_down
                    } @else {
                      keyboard_arrow_right
                    }
            </span>
            }
          </span>
          <span class="info-footer">
            <span class="info-description">
              <span class="info-description__item">
                      <span class="material-symbols-outlined on-container-lowest">
                        calendar_today
                      </span>
                <span class="on-container">{{ parsePeriodHelper(document.activityStart, document.activityEnd) }}</span>
              </span>
              <span class="info-description__item on-container-lowest">
                      <span class="material-symbols-outlined">
                        attach_file
                      </span>
                <span>Вложения</span>
                <span>{{ document.attachments.length }}</span>
              </span>
              <span class="info-description__item on-container-lowest">
                      <span class="material-symbols-outlined">
                        description
                      </span>
                <span>Связи с документами</span>
                <span>{{ document.relatedDocuments.length }}</span>
              </span>
            </span>
          </span>
          @if (selected && selected.id === document.id) {
            @for (attachment of document.attachments; track attachment; let i = $index) {
              <div class="attachments-list__item">
                <p class="attachments-list__item-title subtitle-big on-container"> {{i + 1}}. {{ attachment.originalName }}</p>
                <div class="attachments-list__item-info">
                  <div class="item-info__details">
                    <a class="item-info__details-link text-default" (click)="openFileInNewTabHelper(attachment.link)">Открыть</a>
                  </div>
                </div>
              </div>
            }
          }
        </span>
      </a>
    }
  </div>
</div>
