<div class="templates-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">Шаблоны</h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="router.navigate(['templates/create'])"></app-button>
    </div>
  </div>
  <div class="search-zone search-zone_templates area_white_bg">
    <div class="search-zone__input">
      <core-text-input
        placeholder="Поиск шаблона"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
  <div class="tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!--        <button class="filter">-->
    <!--            Сперва новые-->
    <!--            <span class="material-symbols-outlined">-->
    <!--                keyboard_arrow_down-->
    <!--            </span>-->
    <!--        </button>-->
  </div>
</div>
<div class="templates">
  @switch (currentTab) {
    @case ('tabs__actual') {
      @if (templates.length === 0) {
        <p class="text-bigger">Нет шаблонов</p>
      }
      @for (template of templates; track template.id) {
        <app-card
          [name]="template.name"
          [period]="
            template.activityStart && template.activityEnd
              ? parseActivity(template.activityStart, template.activityEnd)
              : 'Неограниченное действие'
          "
          [isWarning]="false"
          [whiteBg]="true"
          [isTemplate]="true"
          (showDetection)="handleShowClick(template.id)"
          (editDetection)="handleEditClick(template.id)"
          (deleteDetection)="
            handleDeleteClick(template.id, template.name)
          "></app-card>
      }
    }
    @case ('tabs__archive') {
      @if (archivedTemplates.length === 0) {
        <p class="text-bigger">Нет архивных шаблонов</p>
      }
      @for (template of archivedTemplates; track template.id) {
        <app-card
          [name]="template.name"
          [period]="
            template.activityStart && template.activityEnd
              ? parseActivity(template.activityStart, template.activityEnd)
              : 'Неограниченное действие'
          "
          [isWarning]="false"
          [whiteBg]="true"
          (showDetection)="handleShowClick(template.id)"
          (editDetection)="handleEditClick(template.id)"
          (deleteDetection)="
            handleDeleteClick(template.id, template.name)
          "></app-card>
      }
    }
  }
</div>
