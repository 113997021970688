import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  SearchComponent,
} from '@core/components';
import { Tab } from '../services-page/service-page.component';
import { AlertService, ModalService, TemplatesService } from '@core/services';
import { Template, TemplatesList } from '@core/models';
import { parsePeriodHelper } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { debounceTime } from 'rxjs';

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-templates',
  standalone: true,
  imports: [
    ButtonComponent,
    SearchComponent,
    CardComponent,
    NgClass,
    TextInputComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AlertService, AlertComponent],
})
export class TemplatesComponent implements AfterViewInit {
  constructor(
    private modalService: ModalService,
    protected router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplatesService,
    private readonly alertService: AlertService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
  ];

  public templates: Template[] = [];
  public archivedTemplates: Template[] = [];
  isSearchBlocked: boolean = false;
  searchForm: FormGroup<SearchForm>;

  parseActivity = parsePeriodHelper;

  public currentTab: string = this.tabs[0].class;

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ templates }) => {
      this.templates = templates.active;
      this.archivedTemplates = templates.archive;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  handleModalOpen(id: string) {
    this.modalService.open(id);
  }

  handleShowClick(id: number): void {
    this.router.navigate([`/templates/${id}/schema`]);
  }

  handleEditClick(id: number): void {
    this.router.navigate([`/templates/${id}/schema`]);
  }

  handleDeleteClick(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить шаблон "${name}"?`
    );

    if (isAccepted) {
      this.templateService.deleteTemplate(id).subscribe({
        next: (template: Template) => {
          this.templates = this.templates.filter(
            template => template.id !== id
          );
          this.alertService.toggle('Шаблон удален');
        },
        error: (err: Error) => console.error(err),
      });
    } else return;
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.templateService
        .getTemplates({
          name: query,
        })
        .subscribe({
          next: (templatesList: TemplatesList) => {
            this.templates = templatesList.active;
            this.archivedTemplates = templatesList.archive;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }
}
