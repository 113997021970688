import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Template, TemplateBlock, TemplatesList } from '@core/models';

import {
  CreateTemplateBlockDto,
  CreateTemplateDto,
  SearchQueryDto,
} from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  constructor(private readonly http: HttpClient) {}

  getTemplates(searchDto?: SearchQueryDto): Observable<TemplatesList> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }
    if (typeof searchDto?.isShowBlocks !== 'undefined') {
      params.set('isShowBlocks', searchDto.isShowBlocks + '');
    }
    if (typeof searchDto?.isNotEmpty !== 'undefined') {
      params.set('isNotEmpty', searchDto.isNotEmpty + '');
    }
    let uri = '/templates';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<TemplatesList>(uri);
  }

  getTemplateById(id: number): Observable<Template> {
    return this.http.get<Template>(`/templates/${id}`);
  }

  getTemplateBlocks(id: number): Observable<TemplateBlock[]> {
    return this.http.get<TemplateBlock[]>(`/templates/${id}/blocks`);
  }

  createTemplateBlocks(
    id: number,
    createTemplateBlocksDto: CreateTemplateBlockDto[]
  ): Observable<{ count: number }> {
    return this.http.post<{ count: number }>(
      `/templates/${id}/blocks`,
      createTemplateBlocksDto
    );
  }

  createTemplate(createTemplateDto: CreateTemplateDto): Observable<Template> {
    if (!createTemplateDto.activityStart) {
      createTemplateDto.activityStart = undefined;
    } else {
      createTemplateDto.activityStart = new Date(
        createTemplateDto.activityStart
      ).toISOString();
    }
    if (!createTemplateDto.activityEnd) {
      createTemplateDto.activityEnd = undefined;
    } else {
      createTemplateDto.activityEnd = new Date(
        createTemplateDto.activityEnd
      ).toISOString();
    }
    return this.http.post<Template>(`/templates`, createTemplateDto);
  }

  deleteTemplate(id: number): Observable<Template> {
    return this.http.delete<Template>(`/templates/${id}`);
  }
}
