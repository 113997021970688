<div class="container-login primary-container">
  <div class="container-login__block container-lowest">
    <svg
      width="162"
      height="99"
      viewBox="0 0 162 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_87_26290)">
        <path
          d="M127.265 76.1999V62.0842H131.987V68.6634C131.987 69.1522 131.885 69.6818 131.885 69.6818H132.089L137.238 62.0842H141.248V76.1999H137.035V69.58C137.035 69.1115 137.136 68.643 137.136 68.643H136.933L131.783 76.1999H127.265Z"
          fill="#663A2A" />
        <path
          d="M117.434 72.1871C119.164 72.1871 120.304 70.8428 120.304 69.1725C120.304 67.4615 119.164 66.1172 117.434 66.1172C115.765 66.1172 114.564 67.4615 114.564 69.1725C114.564 70.8428 115.765 72.1871 117.434 72.1871ZM117.434 61.7175C121.932 61.7175 125.29 64.8951 125.29 69.1725C125.29 73.4093 121.932 76.5868 117.434 76.5868C112.976 76.5868 109.618 73.4093 109.618 69.1725C109.618 64.9154 112.976 61.7175 117.434 61.7175Z"
          fill="#663A2A" />
        <path
          d="M23.6628 90.9265C26.5124 90.9265 28.446 88.8488 28.446 86.1194C28.446 83.3492 26.5124 81.2309 23.6628 81.2309C20.8743 81.2309 18.9203 83.3289 18.9203 86.1194C18.9203 88.8488 20.854 90.9265 23.6628 90.9265ZM23.6628 78.644C28.0796 78.644 31.2141 81.8216 31.2141 86.1194C31.2141 90.3561 28.0796 93.5133 23.6628 93.5133C19.2867 93.5133 16.1318 90.3561 16.1318 86.1194C16.1318 81.8419 19.2867 78.644 23.6628 78.644Z"
          fill="#663A2A" />
        <path
          d="M87.269 81.007C89.8133 81.007 91.1363 82.6568 91.3602 84.8159H83.2186C83.6256 82.5754 85.1726 81.007 87.269 81.007ZM93.9451 87.0158C93.9655 86.7306 93.9858 86.4251 93.9858 86.1399C93.9858 81.8421 91.7672 78.6646 87.1265 78.6646C83.1575 78.6646 80.2876 81.8421 80.2876 86.1399C80.2876 86.4455 80.308 86.7306 80.3283 87.0158C80.654 90.7025 83.015 93.5338 87.9407 93.5338C90.3221 93.5338 92.3372 92.8005 93.8026 91.6191L92.7035 89.6026C91.6248 90.4174 89.9761 91.171 88.0221 91.171C84.9487 91.171 83.4628 89.5008 83.1779 87.0361H83.1982L93.9451 87.0158Z"
          fill="#663A2A" />
        <path
          d="M106.28 79.0312V84.816H98.7283V79.0312H95.9194V93.1469H98.7283V86.9955H106.28V93.1469H109.088V79.0312H106.28Z"
          fill="#663A2A" />
        <path
          d="M123.886 79.0312H111.042V81.5366H116.05V93.1469H118.858V81.5366H123.886V79.0312Z"
          fill="#663A2A" />
        <path
          d="M141.777 79.0312H138.968V93.1469H141.777V79.0312Z"
          fill="#663A2A" />
        <path
          d="M39.2947 85.7326V85.6104C39.2947 85.6104 39.5593 85.5289 39.7832 85.2641C40.0274 84.9993 45.1566 79.0516 45.1566 79.0516H42.0018L37.0558 84.8364H35.9363V79.0312H33.1274V93.1469H35.9363V86.9955H37.2186L42.5717 93.1469H46.0726C46.0726 93.1469 40.0682 86.3844 39.8239 86.0992C39.5797 85.8141 39.2947 85.7326 39.2947 85.7326Z"
          fill="#663A2A" />
        <path
          d="M130.297 91.0285C129.422 91.0285 128.628 90.9674 128.628 90.9674V85.8752C128.628 85.8752 129.239 85.7122 130.318 85.7122C132.658 85.7122 133.696 86.6288 133.696 88.3195C133.717 89.9693 132.781 91.0285 130.297 91.0285ZM130.806 83.3495C129.504 83.3495 128.628 83.5124 128.628 83.5124V79.0312H125.819V93.1469C125.819 93.1469 128.119 93.3709 130.521 93.3709C134.938 93.3709 136.383 90.6822 136.383 88.1972C136.383 85.6919 135.081 83.3495 130.806 83.3495Z"
          fill="#663A2A" />
        <path
          d="M7.50173 90.9265C4.67253 90.9265 3.16633 88.8488 3.22739 86.1194C3.28846 83.3492 4.89642 81.2309 7.72563 81.2309C9.29288 81.2309 11.3893 82.6567 11.3893 85.2436V89.134C10.5141 90.2543 9.1504 90.9265 7.50173 90.9265ZM11.5115 79.0311L11.43 80.8031H11.2469C10.3513 79.4792 8.56014 78.644 7.15571 78.644C3.04421 78.644 0.601731 81.8216 0.499961 86.1194C0.398191 90.3561 2.88138 93.5133 6.99288 93.5133C8.8858 93.5133 10.046 92.9837 11.2062 91.619H11.3893V92.8819C11.3893 95.6113 9.49642 96.6909 7.35925 96.6909C5.38492 96.6909 3.85837 95.9576 2.92208 95.3872L2.00616 97.7093C2.90173 98.1778 4.57076 99.1758 7.35925 99.1758C11.3079 99.1758 14.1982 96.8334 14.1982 92.3115V79.0311H11.5115Z"
          fill="#663A2A" />
        <path
          d="M57.2062 79.0312V89.2564C56.3717 90.2952 55.1098 90.9267 53.5222 90.9267C50.9372 90.9267 49.7567 88.849 49.8381 86.1196V79.0312H47.1106V86.1196C47.0089 90.3563 48.6983 93.5135 52.8098 93.5135C54.6823 93.5135 55.9646 92.9635 57.023 91.6192H57.2062V92.8821C57.2062 95.6115 55.3133 96.6911 53.1761 96.6911C51.2018 96.6911 49.6752 95.9578 48.739 95.3874L47.823 97.7095C48.7186 98.178 50.3876 99.1761 53.1761 99.1761C57.1248 99.1761 60.0151 96.8336 60.0151 92.3117V79.0312H57.2062Z"
          fill="#663A2A" />
        <path
          d="M75.6876 79.0312C75.6876 79.0312 71.0469 85.5697 70.9045 85.753C70.762 85.9363 70.7213 86.14 70.7213 86.14H70.6195C70.6195 86.14 70.5584 85.9567 70.416 85.753C70.2735 85.5493 65.2868 79.0312 65.2868 79.0312H62.6407V93.1469H65.4496V84.1438C65.4496 83.5124 65.3275 83.1661 65.3275 83.1661H65.4496L70.6195 90.1119H70.7416L75.5248 83.1661H75.6673C75.6673 83.1661 75.5248 83.5328 75.5248 84.1438V93.1469H78.3337V79.0312H75.6876Z"
          fill="#663A2A" />
        <path
          d="M103.959 62.1046C103.959 62.1046 100.011 67.2376 99.8478 67.4412C99.6646 67.6857 99.6443 67.8079 99.6443 67.8079H99.4407C99.4407 67.8079 99.4 67.6653 99.2168 67.4412C98.9319 67.095 94.8407 62.1046 94.8407 62.1046H91.116V76.2202H95.6753V70.2725C95.6753 69.6411 95.5735 69.2541 95.5735 69.2541H95.777L99.4407 73.4704H99.6443L103.043 69.2541H103.247C103.247 69.2541 103.145 69.6411 103.145 70.2725V76.1999H107.684V62.0842H103.959V62.1046Z"
          fill="#663A2A" />
        <path
          d="M133.31 34.0771V19.7578H122.034V34.0771C122.034 39.1082 124.72 43.7727 129.096 46.2984L141.777 53.6312V40.5952L134.734 36.5214C133.839 36.0122 133.31 35.0956 133.31 34.0771Z"
          fill="#C79B65" />
        <path
          d="M141.757 47.989L154.457 40.6562C158.813 38.1305 161.52 33.466 161.52 28.4349V0H141.777V11.2843H150.224V28.4146C150.224 29.4126 149.695 30.3496 148.819 30.8588L141.777 34.9326V47.989H141.757Z"
          fill="#E84B37" />
        <path d="M141.757 0L122.034 19.7578H141.757V0Z" fill="#663A2A" />
      </g>
      <defs>
        <clipPath id="clip0_87_26290">
          <rect
            width="161"
            height="99"
            fill="white"
            transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

    <form class="container-login__form" [formGroup]="loginForm" (keydown.enter)="onFormSubmit()">
      <fieldset class="container-login__fieldset" [disabled]="isSubmitted">
        <core-email-input
          placeholder="Логин"
          formControlName="login"
          class="mb-16"></core-email-input>
        <core-password-input
          placeholder="Пароль"
          formControlName="password"></core-password-input>

        <a
          [routerLink]="'/login/recover'"
          class="container-login__forgot_password"
          >Забыли пароль?</a
        >

        <p class="container-login__error">{{ error }}</p>
        <app-button
          class="container-login__button"
          size="normal"
          display="inverse"
          type="right"
          icon="arrow_right_alt"
          color="inverse-container"
          sizeText="big"
          [usedBadge]="false"
          buttonContent="Войти"
          typing="submit"
          (click)="onFormSubmit()"
          (window:keypress.enter)="onFormSubmit()"/>
      </fieldset>
    </form>
  </div>
<!--  <p class="container-login__text text-default on-container-lowest">-->
<!--    There is no reality except the one contained within us.<br />-->
<!--    That is why so many people live such an unreal life.<br />-->
<!--    2024.-->
<!--  </p>-->
  <!-- <div class="container-login__help">
    <app-icon-button
      class="container-login__help-button"
      size="normal"
      display="cell"
      type="circle"
      icon="help"
      [fill]="true"
      color="cell">
    </app-icon-button>
  </div> -->
</div>
