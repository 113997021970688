import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { NgClass } from '@angular/common';
import { ButtonComponent } from '@core/components';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [IconButtonComponent, ButtonComponent, NgClass],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  name = input<string>('')
  period = input<string>('');

  attachments = input<any>();
  relations = input<any>();

  isWarning = input<boolean>(false);
  isTemplate = input<boolean>(false);
  whiteBg = input<boolean>(this.isWarning());

  @Output() editDetection = new EventEmitter();
  @Output() showDetection = new EventEmitter();
  @Output() deleteDetection = new EventEmitter();

  onEditClicked() {
    this.editDetection.emit();
  }

  onShowClicked() {
    this.showDetection.emit();
  }

  onDeleteClicked() {
    this.deleteDetection.emit();
  }

  protected errorButtonStatus: string = 'Просмотрен';
}
