import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [
    NgClass,
  ],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  size = input.required<'normal' | 'medium' | 'small'>();
  display = input.required<'fill' | 'tint' | 'outline'>();
  fill = input<boolean>(false);
  color = input.required<
    | 'primary'
    | 'primary-container'
    | 'secondary'
    | 'secondary-container'
    | 'tertiary'
    | 'tertiary-container'
    | 'container-high'
    | 'container'
    | 'inverse-container'
    | 'outline-low'
    | 'container-lowest'
    | 'container-opacity'
  >();
  size_text = input.required<'default' | 'small'>();
  used_left_icon = input<boolean>();
  left_icon = input<'folder_open' | 'add_circle'>();
  badge_content = input<string>();
  used_right_icon = input<boolean>();
  right_icon = input<'cancel'>();
  @Output() cancelClickDetection = new EventEmitter<void>();

  handleCancel() {
    this.cancelClickDetection.emit();
  }
}
