<div class="roles-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">
      Роли
      <span class="top-div__badge">
        <app-badge
          size="normal"
          display="tint"
          color="secondary-container"
          size_text="default"
          badge_content="{{ roles.length }}"></app-badge>
      </span>
    </h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="onAddClick()"></app-button>
    </div>
  </div>
</div>

<div class="roles">
  @if (roles.length === 0) {
    <p class="text-bigger">Нет ролей</p>
  }
  @for (cont of roles; track cont.id) {
    <article class="role">
      <div class="role__row">
        <div class="role__row">
          <h3 class="role__text text-big">
            {{ cont.name }}
          </h3>
          @if (isNew(cont.createdAt)) {
            <app-badge
              size="normal"
              style="margin-left: 20px;"
              display="tint"
              color="secondary-container"
              size_text="default"
              badge_content="Новый" />
          }
        </div>
        @if (cont.type !== RolesTypesEnum.Root) {
          <div class="role__buttons">
            <app-icon-button
            size="small"
              type="circle"
              icon="cancel"
              display="outline"
              color="cell"
              (click)="deleteRole(cont.id, cont.name)"
            ></app-icon-button>
            <app-icon-button
              size="small"
              type="circle"
              icon="edit_square"
              display="outline"
              color="cell"
              (click)="handleClickUpdateRole(cont)"
            ></app-icon-button>
          </div>
        }
      </div>
      <!--      @if (permissions.length) {-->
      <!--        <div class="role__row">-->
      <!--          <div class="role__list">-->
      <!--            <div class="role__row role__list_header">-->
      <!--              <div class="role__list_title">Сущности</div>-->
      <!--              <div class="role__list_status">Доступ</div>-->
      <!--            </div>-->
      <!--            @if (cont.rolesPermissions) {-->
      <!--              @for (-->
      <!--                rolePermission of permissions; track rolePermission; let i = $index-->
      <!--              ) {-->
      <!--                <div class="role__row">-->
      <!--                  <div class="role__list_element">-->
      <!--                    <div class="role__list_title">-->
      <!--                      {{ rolePermission.description }}-->
      <!--                    </div>-->
      <!--                    <div class="role__list_status">-->
      <!--                      @if (hasPermission(cont.id, rolePermission.id)) {-->
      <!--                        Управление-->
      <!--                      } @else {-->
      <!--                        Нет доступа-->
      <!--                      }-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              }-->
      <!--            }-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      }-->
    </article>
  }
</div>

<app-modal
  class="role-modal"
  id="add-role-modal"
  submitButtonText="{{ isCreate ? 'Добавить' : 'Изменить' }}"
  heading="{{ isCreate ? 'Новая роль' : 'Изменить роль' }}"
  width="mini"
  [requestConfirmToCancel]="createRoleForm.dirty"
  (submitAction)="submitEvent()"
  (cancelAction)="clearForm()">
  <form
    [formGroup]="createRoleForm"
    class="area_white_bg"
    id="modal-add-user-form">
    <div class="add-role-input">
      <p class="text-big">Название роли</p>
      <core-text-input
        placeholder="Введите название"
        [formControlName]="'name'"
        (click)="resetErrors()"
        [error]="nameError"
        (keydown.enter)="handlePressEnter($event)"></core-text-input>
    </div>
    <div class="add-role-permissions">
      @for (permission of permissions; track permission; let i = $index) {
        <div class="add-role-permissions__element">
          <div class="title">
            {{ permission.description }}
          </div>
          <div class="selector">
            @if (!hasSelectedPermission(permission.id)) {
              <span class="selected" (click)="onSelectClick(permission)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Нет доступа
              </span>
            } @else {
              <span (click)="onSelectClick(permission)">Нет доступа</span>
            }
            @if (hasSelectedPermission(permission.id) === AccessEnum.VIEW) {
              <span class="selected" (click)="onSelectClick(permission, AccessEnum.VIEW)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Просмотр
                <!-- 3 галочка НЕТ-->
              </span>
            } @else {
              <span (click)="onSelectClick(permission, AccessEnum.VIEW)"
                >Просмотр</span
              >
            }
            @if (hasSelectedPermission(permission.id) === AccessEnum.FULL) {
              <span class="selected" (click)="onSelectClick(permission, AccessEnum.FULL)">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.95801 11L0.208008 6.25002L1.39551 5.06252L4.95801 8.62502L12.6038 0.979187L13.7913 2.16669L4.95801 11Z"
                    fill="#D8402D" />
                </svg>
                Управление
              </span>
            } @else {
              <span (click)="onSelectClick(permission, AccessEnum.FULL)">Управление</span>
            }
          </div>
        </div>
      }
    </div>
  </form>
</app-modal>
