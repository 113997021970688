import { AfterViewInit, Component } from '@angular/core';
import { NgClass, UpperCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  BadgeComponent,
  IconButtonComponent,
  ButtonComponent,
  CardComponent,
  DateInputComponent,
  ModalComponent,
  SelectComponent,
  TextInputComponent,
  AlertComponent,
} from '@core/components';
import { Tab } from '@scripter-admin/app/services-page/service-page.component';
import { RegDocActualComponent } from '@scripter-admin/app/reg-docs-page/reg-doc/tabs/actual/reg-doc-actual.component';
import { RegDocHistoryComponent } from '@scripter-admin/app/reg-docs-page/reg-doc/tabs/history/reg-doc-history.component';
import { Document, DocumentHistory, DocumentsList } from '@core/models';
import { AlertService, ModalService, RegDocsService } from '@core/services';
import {
  getDocumentActualVersionHelper,
  ParseDataToFormHelper,
  parsePeriodHelper,
} from '@core/utils';
import { debounceTime } from 'rxjs';

interface CreateRegDocVersionForm {
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
}

@Component({
  selector: 'app-reg-doc',
  standalone: true,
  imports: [
    IconButtonComponent,
    ButtonComponent,
    NgClass,
    BadgeComponent,
    CardComponent,
    RegDocActualComponent,
    RegDocHistoryComponent,
    DateInputComponent,
    ModalComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
    UpperCasePipe,
  ],
  templateUrl: './reg-doc.component.html',
  styleUrl: './reg-doc.component.scss',
  providers: [AlertService, AlertComponent],
})
export class RegDocComponent implements AfterViewInit {
  public tabs: Tab[] = [
    {
      title: 'Актуальная версия',
      class: 'tabs__actual',
    },
    {
      title: 'История версий',
      class: 'tabs__history',
    },
  ];

  public openEditModalOnInit: boolean = false;
  public currentTab: string = this.tabs[0].class;
  public regDoc!: Document;
  public documentHistory: DocumentHistory[] = [];
  public createRegDocVersionForm = new FormGroup<CreateRegDocVersionForm>({
    activityStart: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
    activityEnd: new FormControl(null, {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });
  public files: File[] = [];
  public updateRegDocForm = new FormGroup({
    title: new FormControl<string>(this.regDoc?.title ?? '', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });
  public relatedDocuments: Document[] = [];
  public titleError: string = '';

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly regDocsService: RegDocsService
  ) {
    this.searchRegDocsForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe((value: any) => {
        if (value !== '') {
          this.handleSearchDocumentsToBind(value);
        } else {
          this.relatedDocuments = [];
        }
      });
  }
  private handleSearchDocumentsToBind(name: string): void {
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          this.relatedDocuments = documents.active.data.filter(
            doc =>
              !this.documentsToAdd.map(add => add.id).includes(doc.id) &&
              this.regDoc.id !== doc.id
          );
        },
        error: (err: Error) => console.error(err),
      });
  }

  public searchRegDocsForm = new FormGroup({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  get regDocNameControl(): FormControl<string> {
    return this.searchRegDocsForm.controls.name;
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ document }) => {
      this.regDoc = document;
    });
    this.documentHistory = [];
  }

  public navigate(): void {
    this.router.navigate(['/reg-docs']);
  }

  public handleTabClick(tabName: string): void {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__history':
        document.querySelector('.tabs__history')?.classList.add('tab_active');
        this.currentTab = 'tabs__history';
        break;
    }
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');

    if (history.state.openEditModal === true) {
      this.handleUpdateRegDocClick();
      history.state.openEditModal = false;
    }
  }

  public handleAddNewVersionClick(): void {
    this.modalService.open('create-reg-doc-version-modal');
  }

  public onCreateNewVersionSubmit(): void {
    const values = this.createRegDocVersionForm.value;
    const requestData = {
      note: '',
      activityStart: values.activityStart
        ? new Date(values.activityStart).toISOString()
        : null,
      activityEnd: values.activityEnd
        ? new Date(values.activityEnd).toISOString()
        : null,
      documentsIds: this.documentsToAdd.map(doc => doc.id),
    };
    const form = ParseDataToFormHelper(requestData, this.files);
    this.regDocsService.createRegDocVersion(this.regDoc.id, form).subscribe({
      next: (doc: Document) => {
        this.regDoc = doc;
        this.modalService.close();
        this.files = [];
        this.createRegDocVersionForm.reset();
        this.alertService.toggle('Добавлена новая версия');
      },
    });
  }

  public onFileSelected(event: any): void {
    const files: File[] = event.target.files;

    for (const file of files) {
      if (!this.files.some((e: File) => e.name === file.name)) {
        this.files.push(file);
      }
    }

    event.target.value = '';
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files: File[] = Array.from(event.dataTransfer.files);

      for (const file of files) {
        if (!this.files.some((e: File) => e.name === file.name)) {
          this.files.push(file);
        }
      }
    }
  }

  public removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  private checkErrors(): boolean {
    return !!this.updateRegDocForm.value.title;
  }

  protected resetErrors(): void {
    this.titleError = '';
  }

  public handleUpdateRegDocClick(): void {
    this.updateRegDocForm.setValue({
      title: this.regDoc.title,
    });
    this.modalService.open('update-reg-doc-modal');
  }

  public onEditFormSubmit(): void {
    const values = this.updateRegDocForm.value;
    if (!this.checkErrors()) return;

    const requestData = {
      title: values.title,
    };
    this.regDocsService.updateRegDoc(this.regDoc.id, requestData).subscribe({
      next: (result: Document) => {
        this.regDoc = result;
        this.modalService.close();
        this.resetErrors();
        this.alertService.toggle('Документ изменен');
        this.updateRegDocForm.reset();
      },
      error: err => console.error(err),
    });
  }

  public documentsToAdd: Document[] = [];

  public selectRegDoc(doc: Document): void {
    if (!this.documentsToAdd.find((addDoc: Document) => addDoc.id === doc.id)) {
      this.documentsToAdd.push(doc);
      this.searchRegDocsForm.get('name')!.setValue('');
      this.relatedDocuments = [];
    }
  }

  public removeRegDoc(doc: Document): void {
    this.documentsToAdd = this.documentsToAdd.filter(
      (addDoc: Document) => doc.id !== addDoc.id
    );
  }

  protected readonly parseActivity = parsePeriodHelper;
  protected readonly getDocumentActualVersionHelper =
    getDocumentActualVersionHelper;
}
