import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  ButtonComponent,
  IconButtonComponent,
  SearchComponent,
  InputComponent,
  BadgeComponent,
  SelectComponent,
  AlertComponent,
} from '@core/components';
import { ModalComponent } from '@core/components/modal/modal.component';
import { AlertService, ModalService, UsersService } from '@core/services';
import { ActivatedRoute } from '@angular/router';
import { Role, User, UserRole } from '@core/models';
import { CreateUserDto, UpdateUserDto } from '@core/models/dto';
import { isNew } from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { EmailInputComponent } from '@core/components/inputs/email-input/email-input.component';
import { debounceTime } from 'rxjs';

type Tab = {
  title: string;
  class: string;
};

interface CreateUserForm {
  firstName: FormControl<string>;
  middleName: FormControl<string>;
  lastName: FormControl<string>;
  login: FormControl<string>;
  isBlocked: FormControl<boolean>;
}

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-users-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    SearchComponent,
    InputComponent,
    BadgeComponent,
    NgClass,
    FormsModule,
    ModalComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
    EmailInputComponent,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [AlertService, AlertComponent],
})
export class UsersComponent implements AfterViewInit {
  public isCreate: boolean = true;
  public selectedUser!: User;
  public firstNameError: string = '';
  public middleNameError: string = '';
  public lastNameError: string = '';
  public loginError: string = '';
  public blockedError: string = '';

  public createUserForm: FormGroup<CreateUserForm>;
  public roles: Role[] = [];
  public selectedRoles: Role[] = [];
  public isSearchBlocked: boolean = false;
  public searchForm: FormGroup<SearchForm>;
  public role: FormControl;

  public tabs: Tab[] = [
    {
      title: 'С Доступом',
      class: 'tabs__available',
    },
    {
      title: 'Доступ остановлен',
      class: 'tabs__blocked',
    },
  ];

  public blockedUsers: User[] = [];
  public users: User[] = [];

  public currentTab: string = this.tabs[0].class;

  public isNew = isNew;

  constructor(
    private readonly modalService: ModalService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly usersService: UsersService
  ) {
    this.createUserForm = new FormGroup<CreateUserForm>({
      firstName: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      middleName: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      login: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      isBlocked: new FormControl(false, {
        validators: Validators.required,
        nonNullable: true,
      }),
    });
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
    this.role = new FormControl<string | undefined>(undefined);
  }

  get firstName(): FormControl<string> {
    return this.createUserForm.controls.firstName as FormControl<string>;
  }
  get middleName(): FormControl<string> {
    return this.createUserForm.controls.middleName as FormControl<string>;
  }
  get lastName(): FormControl<string> {
    return this.createUserForm.controls.lastName as FormControl<string>;
  }
  get login(): FormControl<string> {
    return this.createUserForm.controls.login as FormControl<string>;
  }
  get isBlocked(): FormControl<boolean> {
    return this.createUserForm.controls.isBlocked as FormControl<boolean>;
  }

  get rolesList(): { value: number; label: string }[] {
    const list: { value: number; label: string }[] = [];
    const selectedIdsList: number[] = this.selectedRoles.map(
      selected => selected.id
    );
    for (const role of this.roles) {
      if (!selectedIdsList.includes(role.id)) {
        list.push({
          value: role.id,
          label: role.name,
        });
      }
    }

    return list;
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ users, roles }) => {
      this.users = users.filter((user: User) => !user.isBlocked);
      this.blockedUsers = users.filter((user: User) => user.isBlocked);
      this.roles = roles;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
    this.role.valueChanges.subscribe(value => {
      if (value) {
        const role = this.roles.find(x => x.id === value);
        if (role) {
          this.selectedRoles.push(role);
        }
        this.role.setValue(undefined);
      }
    });
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__available':
        document.querySelector('.tabs__available')?.classList.add('tab_active');
        this.currentTab = 'tabs__available';
        break;
      case 'tabs__blocked':
        document.querySelector('.tabs__blocked')?.classList.add('tab_active');
        this.currentTab = 'tabs__blocked';
        break;
    }
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.usersService
        .getUsers({
          name: query,
        })
        .subscribe({
          next: (users: User[]) => {
            this.users = users;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  private checkErrors(): boolean {
    let result = true;
    const values = this.createUserForm.value;
    if (!values.firstName || values.firstName.length < 2) {
      this.firstNameError =
        'Имя не может быть пустым и быть короче двух символов';
      result = false;
    }
    if (!values.middleName || values.middleName.length < 2) {
      this.middleNameError =
        'Отчество не может быть пустым и быть короче двух симоволов';
      result = false;
    }
    if (!values.lastName || values.lastName.length < 2) {
      this.lastNameError =
        'Фамилия не можеть быть пустой и быть короче двух символов';
      result = false;
    }
    if (
      this.isCreate &&
      (!values.login || !/^\S+@\S+\.\S+$/.test(values.login))
    ) {
      this.loginError = 'Неверный формат email';
      result = false;
    }
    if (!this.isCreate && values.isBlocked) {
      const currentUser = JSON.parse(
        localStorage.getItem('user') as string
      ) as User;
      if (currentUser.id === this.selectedUser.id) {
        this.blockedError = 'Нельзя заблокировать самого себя';
        result = false;
      }
    }
    return result;
  }

  resetErrors() {
    this.firstNameError = '';
    this.middleNameError = '';
    this.lastNameError = '';
    this.loginError = '';
    this.blockedError = '';
  }

  submitEvent() {
    if (!this.checkErrors()) return;

    if (this.isCreate) {
      this.usersService
        .createUser({
          ...this.createUserForm.value,
          rolesIds: this.selectedRoles.map(role => role.id),
        } as CreateUserDto)
        .subscribe({
          next: (result: User) => {
            this.users = [result].concat(this.users);
            this.modalService.close();
            this.resetErrors();
            this.resetForm();
            this.alertService.toggle('Пользователь добавлен');
          },
          error: (error: any) => {
            if (error.statusCode === 409) {
              this.loginError = 'Пользователь с таким email уже существует';
            } else {
              console.error(error);
            }
          },
        });
    } else {
      this.usersService
        .updateUser(this.selectedUser.id, {
          ...this.createUserForm.value,
          rolesIds: this.selectedRoles.map(role => role.id),
        } as UpdateUserDto)
        .subscribe({
          next: (result: User) => {
            const index = this.users.findIndex(user => user.id === result.id);
            if (index >= 0 && result.isBlocked) {
              this.users = this.users.filter(user => user.id !== result.id);
              this.blockedUsers = [result].concat(this.blockedUsers);
            } else if (index < 0 && result.isBlocked) {
              const blockedIndex = this.blockedUsers.findIndex(
                user => user.id === result.id
              );
              this.blockedUsers[blockedIndex] = result;
            } else if (index >= 0 && !result.isBlocked) {
              this.users[index] = result;
              this.blockedUsers = this.blockedUsers.filter(
                user => user.id !== result.id
              );
            } else if (index < 0 && !result.isBlocked) {
              this.blockedUsers = this.blockedUsers.filter(
                user => user.id !== result.id
              );
              this.users = [result].concat(this.users);
            }
            this.modalService.close();
            this.resetErrors();
            this.resetForm();
            this.alertService.toggle('Данные пользователя изменены');
          },
          error: (error: any) => console.error(error),
        });
    }
  }

  handleClickDeleteUser(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить пользователя ${name}?`
    );

    if (isAccepted) {
      this.usersService.deleteUser(id).subscribe({
        next: (result: User) => {
          this.users = this.users.filter(user => user.id !== id);
          this.blockedUsers = this.blockedUsers.filter(user => user.id !== id);
          this.alertService.toggle('Пользователь удален');
        },
        error: (error: any) => console.error(error),
      });
    } else return;
  }

  handleClickUpdateUser(user: User): void {
    this.isCreate = false;
    this.selectedUser = user;
    this.createUserForm.setValue({
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      login: user.login,
      isBlocked: user.isBlocked,
    });
    this.selectedRoles =
      user.usersRoles?.map((userRole: UserRole) => userRole.role) ?? [];
    this.resetErrors();
    this.modalService.open('add-user-modal');
  }

  handleChangeSelectRole(option: any): void {
    const index = this.selectedRoles.findIndex(role => role.id === option.id);
    if (index < 0) this.selectedRoles.push(option);
  }

  handleClickDeleteSelectedRole(id: number): void {
    this.selectedRoles = this.selectedRoles.filter(role => role.id !== id);
  }

  onAddClick() {
    this.resetForm();
    this.isCreate = true;
    this.modalService.open('add-user-modal');
  }

  test() {
    console.log(JSON.parse(JSON.stringify(this.role)));
  }

  private resetForm(): void {
    this.createUserForm.reset();
    this.selectedRoles = [];
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.submitEvent();
  }
}
