import { CommonModule } from '@angular/common';
import { Component, Input, Signal, ViewEncapsulation } from '@angular/core';
import { Service } from '@core/models';
import { RouterLink } from '@angular/router';
import { BadgeComponent } from '@core/components';

@Component({
  selector: 'app-search-block',
  standalone: true,
  imports: [CommonModule, RouterLink, BadgeComponent],
  templateUrl: './search-block.component.html',
  styleUrl: './search-block.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class SearchBlockComponent {
  @Input() services!: Signal<Service[]>;
}
