import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Role } from '@core/models';
import { CreateRoleDto, UpdateRoleDto } from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class RolesService {
  constructor(private readonly http: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>('/admin/roles');
  }

  createRole(createRoleDto: CreateRoleDto): Observable<Role> {
    return this.http.post<Role>('/admin/roles', createRoleDto);
  }

  updateRole(id: number, updateRoleDto: UpdateRoleDto): Observable<Role> {
    return this.http.put<Role>(`/admin/roles/${id}`, updateRoleDto);
  }

  deleteRole(id: number): Observable<Role> {
    return this.http.delete<Role>(`/admin/roles/${id}`)
  }
}
