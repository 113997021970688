<div class="left-menu">
  <nav>
    <img src="assets/icons/leftMenuLogo.svg" alt="Мои документы">
<!--    <app-menu-point text="Главная" link="/"-->
<!--                    icon="assets/icons/leftMenuIcons/mainPageIcon.svg"-->
<!--                    (clickEvent)="onClick(1)"-->
<!--                    [isActive]="isActive(1)"></app-menu-point>-->
    <app-menu-point text="Услуги" link="/services"
                    icon="assets/icons/leftMenuIcons/servicesPageIcon.svg"
                    [isActive]="isActive(2)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.SERVICES),
                      show: hasPermission(PermissionsEnum.SERVICES)
                    }"></app-menu-point>
    <app-menu-point text="Шаблоны" link="/templates"
                    icon="assets/icons/leftMenuIcons/templatesPageIcon.svg"
                    [isActive]="isActive(3)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.TEMPLATES),
                      show: hasPermission(PermissionsEnum.TEMPLATES)
                    }"></app-menu-point>
    <app-menu-point text="Рег. документы" link="/reg-docs"
                    icon="assets/icons/leftMenuIcons/regDocsPageIcon.svg"
                    [isActive]="isActive(4)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.DOCUMENTS),
                      show: hasPermission(PermissionsEnum.DOCUMENTS)
                    }"></app-menu-point>
    <app-menu-point text="Образцы документов" link="/docs-sample"
                    icon="assets/icons/leftMenuIcons/docSamplePageIcon.svg"
                    [isActive]="isActive(5)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.DOCUMENTS_TEMPLATES),
                      show: hasPermission(PermissionsEnum.DOCUMENTS_TEMPLATES)
                    }"></app-menu-point>
    <app-menu-point text="Способы передачи" link="/docs-transfer-methods"
                    icon="assets/icons/leftMenuIcons/compare_arrows.svg"
                    [isActive]="isActive(7)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.SERVICES),
                      show: hasPermission(PermissionsEnum.SERVICES)
                    }"></app-menu-point>
    <app-menu-point text="Пользователи" link="/users"
                    icon="assets/icons/leftMenuIcons/usersPageIcon.svg"
                    [isActive]="isActive(8)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.USERS),
                      show: hasPermission(PermissionsEnum.USERS)
                    }"></app-menu-point>
    <app-menu-point text="Роли" link="/roles"
                    icon="assets/icons/leftMenuIcons/rolesPageIcon.svg"
                    [isActive]="isActive(9)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.ROLES),
                      show: hasPermission(PermissionsEnum.ROLES)
                    }"></app-menu-point>
    <app-menu-point text="Классификаторы" link="/classificators"
                    icon="assets/icons/leftMenuIcons/classifiersPageIcon.svg"
                    [isActive]="isActive(10)"
                    [ngClass]="{
                      hide: !hasPermission(PermissionsEnum.CLASSIFIERS),
                      show: hasPermission(PermissionsEnum.CLASSIFIERS)
                    }"></app-menu-point>
  </nav>
  <div>
    <button class="left-menu__exit-button" (click)="onLogoutClick()"></button>
  </div>
</div>
