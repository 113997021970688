import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Document, DocumentsList } from '@core/models';
import { UpdateRegDocDto, SearchDocumentsDto } from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class RegDocsService {
  constructor(private readonly http: HttpClient) {}

  getRegDocs(searchDto?: SearchDocumentsDto): Observable<DocumentsList> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }
    if (searchDto?.from) {
      params.set('from', searchDto.from.toString());
    }
    if (searchDto?.to) {
      params.set('to', searchDto?.to.toString());
    }
    let uri = '/documents';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<DocumentsList>(uri);
  }

  getRegDocById(id: number): Observable<Document> {
    return this.http.get<Document>(`/documents/${id}`);
  }

  createRegDoc(form: FormData): Observable<Document> {
    return this.http.post<Document>('/admin/documents', form, {
      headers: {
        contentType: 'multipart/form-data',
      },
    });
  }

  createRegDocVersion(id: number, form: FormData): Observable<Document> {
    return this.http.post<Document>(`/admin/documents/${id}/version`, form, {
      headers: {
        contentType: 'multipart/form-data',
      },
    });
  }

  updateRegDoc(
    id: number,
    updateRegDocDto: UpdateRegDocDto
  ): Observable<Document> {
    return this.http.put<Document>(`/admin/documents/${id}`, updateRegDocDto);
  }

  deleteRegDoc(id: number): Observable<Document> {
    return this.http.delete<Document>(`/admin/documents/${id}`);
  }
}
