import { AfterViewInit, Component } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import {
  ButtonComponent,
  IconButtonComponent,
  BadgeComponent,
  MenuPointComponent,
  CardComponent,
  WysiwygComponent,
  SearchComponent,
  ModalComponent,
  SelectComponent,
  AlertComponent,
  TextInputComponent,
} from '@core/components';
import { Tab } from '@scripter-admin/app/services-page/service-page.component';
import {
  ApplicantCategoryClassifier,
  ClassifiersWithTotalList,
  DepartmentClassifier,
  Document,
  DocumentsList,
  LifeSituationClassifier,
  Service,
  TerritorialityClassifier,
} from '@core/models';
import { ButtonType } from '@core/types';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ModalService, AlertService, ClassifiersService, ServicesService, RegDocsService } from '@core/services';
import { Classifier } from '@core/models';
import {
  ClassifierTypes,
  getDocumentActualVersionHelper,
  getServiceTypeTranslation,
  isNew,
  SelectedClassifiers,
} from '@core/utils';
import { ChartComponent } from '@core/components/chart/chart.component';
import { SectionHeaderComponent } from '@core/components/section-header/section-header.component';
import { ServiceAboutComponent } from '@scripter-admin/app/services-page/service/tabs/service-about/service-about.component';
import { ServiceReferenceComponent } from '@scripter-admin/app/services-page/service/tabs/service-reference/service-reference.component';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';

interface UpdateServiceForm {
  name: FormControl<string>;
  title: FormControl<string>;
  sierNumber: FormControl<string>;
  activityStart?: FormControl<Date | null>;
  activityEnd?: FormControl<Date | null>;
  information?: FormControl<string | null>;
  informationProcedure?: FormControl<string | null>;
  informationTransferMethod?: FormControl<string | null>;
  informationProvisionMethod?: FormControl<string | null>;
  informationApplicantCategory?: FormControl<string | null>;
  informationApplicantNationality?: FormControl<string | null>;
  informationResidenceRequirement?: FormControl<string | null>;
  informationApplicationResult?: FormControl<string | null>;
  informationServiceResult?: FormControl<string | null>;
  informationReceiptOfTheResult?: FormControl<string | null>;
  informationAdditional?: FormControl<string | null>;
}

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    BadgeComponent,
    NgClass,
    MenuPointComponent,
    CardComponent,
    RouterLink,
    WysiwygComponent,
    SearchComponent,
    ModalComponent,
    SelectComponent,
    ReactiveFormsModule,
    ChartComponent,
    TextInputComponent,
    SectionHeaderComponent,
    ServiceAboutComponent,
    ServiceReferenceComponent,
    CommonModule,
    AutocompleteComponent,
    SelectedDocumentsComponent
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss',
  providers: [AlertService, AlertComponent],
})
export class ServiceComponent {
  selectedClassifiers: SelectedClassifiers = new SelectedClassifiers();
  updateServiceForm: FormGroup;
  openedClassifiersInput: string[] = [];

  public buttons: ButtonType[] = [
    {
      color: 'container-lowest',
      icon: 'arrow_left_alt',
      display: 'overlay',
      click: this.navigate.bind(this),
    },
    {
      color: 'inverse-container',
      icon: 'explore',
      display: 'inverse',
      content: 'Схема',
      routerLink: 'schema',
    },
    {
      color: 'inverse-container',
      icon: 'edit_document',
      display: 'inverse',
      content: 'Редактировать услугу',
      click: this.openUpdateModal.bind(this),
    },
    {
      color: 'outline-low',
      display: 'outline',
      content: 'Закрыть',
      click: this.navigate.bind(this),
    },
  ];

  public service!: Service;
  public serviceTitle: string = '';
  public shortServiceTitle: string = '';

  public dateStart?: string;
  public dateEnd?: string;

  public lifeSituations: Classifier[] = [];
  public applicantCategories: Classifier[] = [];
  public departments: Classifier[] = [];
  public territoriality: Classifier[] = [];

  public isChartOpen: boolean = false;

  isNew = isNew;

  public relatedDocuments: any[] = [];

  public isAbout: boolean = true;
  public modalIsAbout: boolean = true;

  public activeArray: boolean[] = [
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  public nameError = '';
  public titleError = '';
  public sierError = '';

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute,
    private readonly servicesService: ServicesService,
    private readonly classifiersService: ClassifiersService,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly regDocsService: RegDocsService
  ) {
    this.updateServiceForm = new FormGroup<UpdateServiceForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      sierNumber: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      information: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationProcedure: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationTransferMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationProvisionMethod: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationApplicantCategory: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationApplicantNationality: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationResidenceRequirement: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationApplicationResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationServiceResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationReceiptOfTheResult: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      informationAdditional: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ service }) => {
      this.serviceTitle = service.name;
      this.shortServiceTitle = service.title;
      this.dateStart = service.activityStart;
      this.dateEnd = service.activityEnd;
      this.service = service;
      this.applicantCategories = service.applicantCategories;
      this.departments = service.departments;
      this.territoriality = service.territoriality;
      this.lifeSituations = service.lifeSituations;
      this.selectedDocuments = service.documents;

      this.selectedClassifiers.insert(
        'applicantCategories',
        (service.applicantCategories as ApplicantCategoryClassifier[]).map(
          s => ({
            id: s.applicantCategoryId,
            name: s.name,
            createdAt: s.createdAt,
          })
        )
      );
      this.selectedClassifiers.insert(
        'territoriality',
        (service.territoriality as TerritorialityClassifier[]).map(s => ({
          id: s.territorialityId,
          name: s.name,
          createdAt: s.createdAt,
        }))
      );
      this.selectedClassifiers.insert(
        'departments',
        (service.departments as DepartmentClassifier[]).map(s => ({
          id: s.departmentId,
          name: s.name,
          createdAt: s.createdAt,
        }))
      );
      this.selectedClassifiers.insert(
        'lifeSituations',
        (service.lifeSituations as LifeSituationClassifier[]).map(s => ({
          id: s.lifeSituationId,
          name: s.name,
          createdAt: s.createdAt,
        }))
      );
      this.updateServiceForm.setValue({
        name: service.name,
        title: service.title,
        sierNumber: service.sierNumber,
        activityStart: service.activityStart?.slice(0, 10) ?? '',
        activityEnd: service.activityEnd?.slice(0, 10) ?? '',
        information: service.information,
        informationProcedure: service.informationProcedure,
        informationTransferMethod: service.informationTransferMethod,
        informationProvisionMethod: service.informationProvisionMethod,
        informationApplicantCategory: service.informationApplicantCategory,
        informationApplicantNationality:
          service.informationApplicantNationality,
        informationResidenceRequirement:
          service.informationResidenceRequirement,
        informationApplicationResult: service.informationApplicationResult,
        informationServiceResult: service.informationServiceResult,
        informationReceiptOfTheResult: service.informationReceiptOfTheResult,
        informationAdditional: service.informationAdditional,
      });
    });
  }

  resetErrors(): void {
    this.nameError = '';
    this.titleError = '';
    this.sierError = '';
  }

  get activityStart(): string {
    if (this.dateStart)
      return this.dateStart.substring(0, this.dateStart.indexOf('T'));
    return '';
  }

  get activityEnd(): string {
    if (this.dateEnd)
      return this.dateEnd.substring(0, this.dateEnd.indexOf('T'));
    return '';
  }

  getWysiwygDisplay(index: number): string {
    return `display: ${this.activeArray[index] ? 'unset' : 'none'}`;
  }

  navigate() {
    this.router.navigate(['/services']);
  }

  control<T>(name: string): FormControl<T> {
    return this.updateServiceForm.controls[name] as FormControl<T>;
  }

  openUpdateModal() {
    this.searchDocuments('');
    this.classifiersService
      .getClassifiers()
      .subscribe((classifiers: ClassifiersWithTotalList) => {
        this.applicantCategories = classifiers.applicantCategories.data;
        this.departments = classifiers.departments.data;
        this.lifeSituations = classifiers.lifeSituations.data;
        this.territoriality = classifiers.territoriality.data;
        this.suggestionClassifiers.lifeSituations = classifiers.lifeSituations.data;
        this.suggestionClassifiers.applicantCategories = classifiers.applicantCategories.data;
        this.suggestionClassifiers.departments = classifiers.departments.data;
        this.suggestionClassifiers.territoriality = classifiers.territoriality.data;
        this.modalService.open('update-service-modal');
      });
  }

  closeUpdateModal() {
    this.modalService.close();
  }

  onFormSubmit() {
    const values = this.updateServiceForm.value;
    if (values.name.length < 5 || values.title.length < 5) {
      return;
    }

    const requestData = {
      name: values.name,
      title: values.title,
      sierNumber: values.sierNumber,
      activityStart: new Date(values.activityStart),
      activityEnd: new Date(values.activityEnd),
      information: values.information,
      informationProcedure: values.informationProcedure,
      informationTransferMethod: values.informationTransferMethod,
      informationProvisionMethod: values.informationProvisionMethod,
      informationApplicantCategory: values.informationApplicantCategory,
      informationApplicantNationality: values.informationApplicantNationality,
      informationResidenceRequirement: values.informationResidenceRequirement,
      informationApplicationResult: values.informationApplicationResult,
      informationServiceResult: values.informationServiceResult,
      informationReceiptOfTheResult: values.informationReceiptOfTheResult,
      informationAdditional: values.informationAdditional,
      applicantCategoriesIds: this.selectedClassifiers.getIds(
        'applicantCategories'
      ),
      lifeSituationsIds: this.selectedClassifiers.getIds('lifeSituations'),
      territorialityIds: this.selectedClassifiers.getIds('territoriality'),
      departmentsIds: this.selectedClassifiers.getIds('departments'),
      documentsIds: this.selectedDocuments.map((doc) => doc.id),
    };

    this.activatedRoute.params.subscribe(params => {
      this.servicesService.updateService(params['id'], requestData).subscribe({
        next: () => {
          this.modalService.close();
          this.alertService.toggle('Услуга изменена');
          this.servicesService.getServiceById(params['id']).subscribe({
            next: (data: Service) => {
              this.serviceTitle = data.name;
              this.shortServiceTitle = data.title;
              this.dateStart = data.activityStart;
              this.dateEnd = data.activityEnd;
              this.service = data;
            },
          });
        },
        error: (err: Error) => console.error(err),
      });
    });
  }

  handleMenuItemClick(count: number) {
    this.activeArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.activeArray[count] = true;
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.onFormSubmit();
  }

  get isNeedAttention(): boolean {
    return !!this.service.documents?.filter(
      d =>
        getDocumentActualVersionHelper(d)?.activityEnd &&
        new Date(getDocumentActualVersionHelper(d)?.activityEnd as string) <
          new Date()
    ).length;
  }

  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;

  suggestionDocuments = new Array<Document>();
  selectedDocuments = new Array<Document>();

  searchDocuments(name: string){
    this.regDocsService
      .getRegDocs({
        name,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (documents: DocumentsList) => {
          const selectedIds = this.selectedDocuments.map((doc) => doc.id);
          this.suggestionDocuments = this.relatedDocuments = documents.active.data.filter(
            doc => !selectedIds.includes(doc.id)
          );
        },
        error: (err: Error) => console.error(err),
      });
  }

  getDocumentName(doc: Document): string {
    return doc.title;
  }

  addDocument(doc: Document){
    if (this.selectedDocuments.indexOf(doc) < 0) {
      this.selectedDocuments.push(doc);
      this.suggestionDocuments.splice(this.suggestionDocuments.indexOf(doc), 1);
    }
  }

  removeDocument(doc: Document){
    if(this.selectedDocuments.indexOf(doc) >= 0){
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(doc), 1);
      this.suggestionDocuments.push(doc);
    }
  }

  suggestionClassifiers = {
    lifeSituations: new Array<Classifier>(),
    applicantCategories: new Array<Classifier>(),
    departments: new Array<Classifier>(),
    territoriality: new Array<Classifier>()
  }

  searchClassifiers(type: ClassifierTypes, name: string){
    this.classifiersService.getClassifiers({ name })
      .subscribe((result) => {
        const selectedIds = this.selectedClassifiers.getIds(type);
        this.suggestionClassifiers[type] = result[type].data.filter((item) => !selectedIds.includes(item.id));
      });
  }

  getClassifierName(classifier: Classifier): string {
    return classifier.name;
  }

  addClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.add(type, classifier as Classifier);
    this.suggestionClassifiers[type].splice(this.suggestionClassifiers[type].indexOf(classifier), 1);
  }

  removeClassifier(type: ClassifierTypes, classifier: Classifier): void {
    this.selectedClassifiers.remove(type, classifier);
    this.suggestionClassifiers[type].push(classifier);
  }
}
