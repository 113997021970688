@if (attentionServices().length === 0) {
  <p class="text-bigger">Нет требующих внимания услуг</p>
} @else {
  @for (service of attentionServices(); track service.id) {
    <article class="service">
      <div class="service__row service__row_bordered">
        <p class="service__text service__text_error text-big">
          <span class="material-symbols-outlined">
            warning
          </span>
          Изменены регулирующие документы. Подтвердите актуальность услуги
        </p>
        <app-button size="smallest" type="no" display="outline" color="outline-low" sizeText="default"
                    buttonContent="Посмотреть" (click)="handleServiceVisibilityClick(service.id, 'needAttention')"></app-button>
      </div>
      <div class="service__row">
        <p class="service__text text-big">
          {{ service.title }}
          <span class="service-type">
            ({{ getServiceTypeTranslation(service.type) }})
          </span>
        </p>
      </div>
      <div class="service__row">
        <div class="service__badges">
          @for (badge of Array.from(service.territoriality); track badge.id) {
            <app-badge size="normal" display="tint" color="primary-container" size_text="default"
                       badge_content="{{ badge.name }}"></app-badge>
          }
          @for (badge of Array.from(service.lifeSituations); track badge.id) {
            <app-badge size="normal" display="tint" color="primary-container" size_text="default"
                       badge_content="{{ badge.name }}"></app-badge>
          }
          @for (badge of Array.from(service.departments); track badge.id) {
            <app-badge size="normal" display="tint" color="container-lowest" size_text="default"
                       badge_content="{{ badge.name }}"></app-badge>
          }
          @for (badge of Array.from(service.applicantCategories); track badge.id) {
            <app-badge size="normal" display="tint" color="secondary" size_text="default"
                       badge_content="{{ badge.name }}"></app-badge>
          }
        </div>
        <p class="service__period text-default">
          <span class="material-symbols-outlined on-container-lowest">
            calendar_today
          </span>
          {{ parseActivity(service.activityStart, service.activityEnd) }}
        </p>
      </div>
    </article>
  }
}
