import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MenuPointComponent } from '@core/components/menu-point/menu-point.component';
import { UserService } from '@core/services';
import { PermissionsEnum } from '@core/utils';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-left-menu',
  standalone: true,
  imports: [MenuPointComponent, NgClass],
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftMenuComponent {
  active: number = 0;
  permissions: PermissionsEnum[] = [];

  constructor(
    private readonly userService: UserService,
  ) {
    this.userService.permissions.subscribe(
      (permissions: PermissionsEnum[]) => (this.permissions = permissions)
    );
  }

  onLogoutClick() {
    this.userService.logout();
  }

  isActive(index: number) {
    return this.active === index;
  }

  hasPermission(permission: PermissionsEnum): boolean {
    return this.permissions.includes(permission);
  }

  protected readonly PermissionsEnum = PermissionsEnum;
}
