import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { CheckRestoreQueryDto, RestorePasswordDto } from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class RestorePasswordService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  restorePassword(restoreDto: RestorePasswordDto): Observable<void> {
    return this.http.post<void>('/auth/restore-password', restoreDto);
  }

  checkIsRestorable(checkRestoreDto: CheckRestoreQueryDto): Observable<boolean> {
    const query = new URLSearchParams(checkRestoreDto as Record<string, any>).toString();
    return this.http.get<boolean>(`/auth/restore-password?${query}`).pipe(tap({
      next: (res: boolean) => res,
      error: () => this.router.navigateByUrl('/404'),
    }));
  }
}
