<div class="selected-documents">
    @for (item of items; track item) {
      <div class="selected-documents__item">
        <app-icon-button
          class="selected-documents__item-remove"
          size="small"
          type="circle"
          icon="link_off"
          display="overlay"
          color="container-lowest"
          (click)="remove.emit(item)"
        ></app-icon-button>
        <div class="selected-documents__item-body">
          <h5 class="selected-documents__item-title">{{ item.title }}</h5>
          <div class="selected-documents__item-activity">
            <span class="material-symbols-outlined">calendar_today</span>
            {{ getActivity(item) }}
          </div>
        </div>
      </div>
    }
  </div>