import { ControlValueAccessor } from '@angular/forms';
import { Component, input, signal } from '@angular/core';

@Component({
  template: ''
})
export abstract class InputComponent<T> implements ControlValueAccessor {
  touched: boolean = false;
  disabled: boolean = false;
  focused = signal(false);
  error = input<string>();

  value = signal<T>(this.defaultValue());

  abstract defaultValue(): T;

  onChange = (value: T) => {};
  onTouched = () => {};

  writeValue(value: T) {
    this.value.set(value);
  }

  registerOnChange(onChanged: any) {
    this.onChange = onChanged;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onInput(value: T) {
    this.markAsTouched();
    if(!this.disabled) {
      this.value.set(value);
      this.onChange(this.value());
    }
  }
}
