<div class="content">
  <div class="chart__header text-default">
    <button class="chart__back" (click)="exitClick.emit()">
      <span class="material-symbols-outlined">keyboard_backspace</span>
    </button>
    <span class="chart-header__title">{{title}}</span>
  </div>
  <div class="left-block">
    <app-button buttonContent="Сохранить изменения" icon="check" size="smallest" type="left" display="inverse"
      color="inverse-container" sizeText="default" (click)="onSaveClick()"></app-button>
    <app-button buttonContent="Закрыть" size="smallest" type="no" display="outline" color="outline-low"
      sizeText="default" (click)="exitClick.emit()"></app-button>
  </div>
</div>
