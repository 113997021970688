import { Subdivision } from '@core/models';
import { SubdivisionTypesEnum } from '@core/utils';

export const getSubdivisionTypeTranslationsHelper = (
  subdivision: Subdivision
): string => {
  switch (subdivision.type) {
    case SubdivisionTypesEnum.Affiliate:
      return 'Филиал';
    case SubdivisionTypesEnum.BusinessOffice:
      return 'Бизнес офис';
    case SubdivisionTypesEnum.Department:
      return 'Отдел';
    case SubdivisionTypesEnum.TOSP:
      return 'ТОСП';
    case SubdivisionTypesEnum.URM:
      return 'УРМ';
  }
};
