@if (!modalVersion) {
  <div class="add-root-button" *ngIf="!this.chartElementList.length">
    <span (click)="showAddOptions()">+</span>
  </div>

  <div #chart class="container">
    @if (type === 'template' && !template) {
      <app-service-header title="Создание шаблона"
                          buttonText="Сохранить шаблон"
                          redirectUrl="/templates"
                          (exitClick)="onExit()"
                          (saveClick)="onSaveTemplateClick()">
      </app-service-header>
    } @else {
      <app-service-header title="{{title}}"
                          buttonText="Сохранить изменения"
                          redirectUrl="{{ redirectUri }}"
                          (exitClick)="onExit()"
                          (saveClick)="onSaveClick()">
      </app-service-header>
    }
    <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="WIDTH" [attr.height]="HEIGHT" [attr.viewBox]="viewBox"
      (wheel)="onMouseScroll($event)"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      class="chart">
      <defs>
        <marker id='head' orient="auto-start-reverse" viewBox="0 0 14 12" markerWidth='14' markerHeight='12'
          markerUnits="userSpaceOnUse" refX='3' refY='5'>
          <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
        </marker>
        <marker id="round" markerWidth="14" markerHeight="14" refX="5" refY="5" orient="auto">
          <circle cx="5" cy="5" r="3" fill="black" />
          <circle cx="5" cy="5" r="2" fill="white" />
        </marker>
      </defs>
      <g *ngFor="let e of chartElementList">
        <path *ngFor="let connection of getTopConnections(e)" marker-start="url(#round)" marker-end="url(#head)" [attr.d]="connection"
          [style.stroke]="'#211F1F'" class="connection" />
        <g *ngIf="e.item.type === ServiceBlockTypes.Template">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[0]"
            [style.stroke]="colors[0]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
          <g class="item-menu-group" (click)="toggleBlockMenu(e.item)">
            <g *ngIf="this.menuOpened && e.item.id === this.selectedItem?.id" class="item-menu-bar">
              <g *ngIf="e.item.childrens!.size > 0" (click)="handleRemoveChilds()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 46"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 34" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/link_off.svg" class="item-menu-el">
                </image>
              </g>
              <g (click)="handleDeleteBlock()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + (e.item.childrens!.size ? 94 : 46)"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + (e.item.childrens!.size ? 82 : 34)" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/delete.svg" class="item-menu-el">
                </image>
              </g>
            </g>
            <circle r="22" class="item-menu-el" [attr.cx]="e.x + ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
              stroke="#EFDBD7" [ngClass]="{'item-menu-el_more': menuOpened && e.item.id === this.selectedItem?.id}">
            </circle>
            <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
              href="../../../assets/icons/chart-icons/more.svg" class="more-icon"
              [ngClass]="{'more-icon_active': menuOpened && e.item.id === this.selectedItem?.id}"></image>
          </g>
          @if (e.item.documents && e.item.documents.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#f" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
          }
          <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 + 10" [attr.y]="e.y-40"
            href="../../../assets/icons/leftMenuIcons/templatesPageIcon.svg" >
          </image>
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Intermediate">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[0]"
            [style.stroke]="colors[0]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
          <g class="item-menu-group" (click)="toggleBlockMenu(e.item)">
            <g *ngIf="this.menuOpened && e.item.id === this.selectedItem?.id" class="item-menu-bar">
              <g (click)="openEditForm()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 46"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 34" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/edit_square.svg" class="item-menu-el">
                </image>
              </g>
              <g (click)="handleDeleteBlock()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 94"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 82" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/delete.svg" class="item-menu-el">
                </image>
              </g>
            </g>
            <circle r="22" class="item-menu-el" [attr.cx]="e.x + ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
              stroke="#EFDBD7" [ngClass]="{'item-menu-el_more': menuOpened && e.item.id === this.selectedItem?.id}">
            </circle>
            <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
              href="../../../assets/icons/chart-icons/more.svg" class="more-icon"
              [ngClass]="{'more-icon_active': menuOpened && e.item.id === this.selectedItem?.id}"></image>
          </g>
          @if (e.item.documents && e.item.documents.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#f" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Answer">
          <rect class="item answer" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[3]"
                [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2" [attr.width]="ELEMENT_WIDTH"
                [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>

          <g class="item-menu-group" (click)="toggleBlockMenu(e.item)">
            <g *ngIf="this.menuOpened && e.item.id === this.selectedItem?.id" class="item-menu-bar">
              
              <g *ngIf="e.item.childrens!.size > 0" (click)="handleRemoveChilds()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 46"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 34" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/link_off.svg" class="item-menu-el">
                </image>
              </g>

              <g (click)="handleDeleteBlock()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + (e.item.childrens!.size ? 94 : 46)"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + (e.item.childrens!.size ? 82 : 34)" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/delete.svg" class="item-menu-el">
                </image>
              </g>
            </g>

            <circle r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                    fill="#000" stroke="#EFDBD7" class="item-menu-el"
                    [ngClass]="{'item-menu-el_more': menuOpened && e.item.id === this.selectedItem?.id}">
            </circle>
            <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
              href="../../../assets/icons/chart-icons/more.svg" class="more-icon"
              [ngClass]="{'more-icon_active': menuOpened && e.item.id === this.selectedItem?.id}">
            </image>
          </g>

          @if (e.item && e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-default" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Result">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[1]"
            [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2" [attr.width]="ELEMENT_WIDTH"
            [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
          <g class="item-menu-group" (click)="toggleBlockMenu(e.item)">
            <g *ngIf="this.menuOpened && e.item.id === this.selectedItem?.id" class="item-menu-bar">
              <g (click)="handleDeleteBlock()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 46"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 34" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/delete.svg" class="item-menu-el">
                </image>
              </g>
            </g>

            <circle r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                    fill="#000" stroke="#EFDBD7" class="item-menu-el"
                    [ngClass]="{'item-menu-el_more': menuOpened && e.item.id === this.selectedItem?.id}">
            </circle>
            <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
              href="../../../assets/icons/chart-icons/more.svg" class="more-icon"
              [ngClass]="{'more-icon_active': menuOpened && e.item.id === this.selectedItem?.id}"></image>
          </g>
          @if (e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
            
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-succ" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Closed">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[2]"
            [style.stroke]="colors[2]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
          <g class="item-menu-group" (click)="toggleBlockMenu(e.item)">
            <g *ngIf="this.menuOpened && e.item.id === this.selectedItem?.id" class="item-menu-bar">
              <g (click)="openEditForm()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 46"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 34" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/edit_square.svg" class="item-menu-el">
                </image>
              </g>
              <g (click)="handleDeleteBlock()">
                <circle fill="#fff" stroke="#EFDBD7" r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2 + 94"
                  [attr.cy]="e.y - ELEMENT_HEIGHT/5" class="item-menu-el">
                </circle>
                <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 + 82" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                  href="../../../assets/icons/chart-icons/delete.svg" class="item-menu-el">
                </image>
              </g>
            </g>
            <circle r="22" [attr.cx]="e.x + ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                    fill="#000" stroke="#EFDBD7" class="item-menu-el"
                    [ngClass]="{'item-menu-el_more': menuOpened && e.item.id === this.selectedItem?.id}">
            </circle>
            <image height="24" width="24" [attr.x]="e.x + ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
              href="../../../assets/icons/chart-icons/more.svg" class="more-icon"
              [ngClass]="{'more-icon_active': menuOpened && e.item.id === this.selectedItem?.id}"></image>
          </g>
          @if (e.item && e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-false" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
        <g class="add-button" (click)="showAddOptions(e.item)" *ngIf="!e.item.childrens?.size && ![ServiceBlockTypes.Intermediate, ServiceBlockTypes.Closed, ServiceBlockTypes.Result].includes(e.item.type)">
          <circle fill="#D8402D" stroke="#EFDBD7" r="22" [attr.cx]="e.x" [attr.cy]="e.y + ELEMENT_HEIGHT + 20"></circle>
          <image href="../../assets/icons/plus-icon.svg" height="20" width="20" [attr.x]="e.x - 10" [attr.y]="e.y + ELEMENT_HEIGHT + 10"></image>
          <path marker-start='url(#round)' marker-end="url(#head)" [attr.d]="getBottomConnection(e)"
                [style.stroke]="'#211F1F'" class="connection" />
        </g>
      </g>
    </svg>
    <div class="toolbox">
      <button (click)="zoomOut()" class="button-centered" aria-label="ZoomOut"
        [disabled]="zoomValue == minZoom || !chartElementList.length" [ngClass]="{'button-disabled': zoomValue == minZoom || !chartElementList.length}">
        <span class="material-symbols-outlined span">
          remove
        </span>
      </button>
      <span class="span2">
        {{zoomValue + '%'}}
      </span>
      <button (click)="zoomIn()" class="button-centered" aria-label="ZoomIn"
        [disabled]="zoomValue == maxZoom || !chartElementList.length" [ngClass]="{'button-disabled': zoomValue == maxZoom || !chartElementList.length }">
        <span class="material-symbols-outlined span">
          add
        </span>
      </button>

    </div>
  </div>

  <app-chart-menu-list [showAddBar]="showAddBar"
                       [isFull]="!!chartElementList.length"
                       [type]="type"
                       [canAddChild]="possibleChildBlocks.length > 0"
                       (handleClick)="openAddForm($event)"
                       (handleAddChildClick)="openAddChildForm()"
                       (handleCloseClick)="hideAddOptions()">
  </app-chart-menu-list>

  <div class="action">
    <app-modal id="about-modal" heading="О шаблоне" width="mini"></app-modal>

    <app-modal class="service-modal"
               width="mini"
               id="add-modal"
               [heading]="isNewBlock ? 'Добавление блока' : 'Редактирование блока'"
               [requestConfirmToCancel]="blockEditForm.dirty"
               (submitAction)="handleSubmitForm()"
               (cancelAction)="handleCancelForm()"
               >
      <div class="information">
        <h2 class="title-bold-h5">1. Общая информация</h2>

        <form class="information__form" action="" id="modal-block-form" [formGroup]="blockEditForm">
          <h3 class="information__input-title">Заголовок (Обязательно)</h3>
          <app-search
                  [isShowClear]="false"
                  placeholder="Введите заголовок" [whiteBg]="true" [searchIcon]="false"
                  [control]="blockTitle"></app-search>
          @if (showEditBlockError) {<p class="input-error mt-24">Заголовок не должен быть пустым</p>}

          <h3 class="information__input-title">Примечание</h3>
          <app-search
                  [isShowClear]="false"
                  placeholder="Введите примечания"
                  [whiteBg]="true"
                  [searchIcon]="false"
                  [control]="blockNote"></app-search>

          <h3 class="information__input-title"></h3>
        </form>

        @if (editBlockType === ServiceBlockTypes.Intermediate) {
          <h2 class="title-bold-h5" style="margin-top: 20px">2. Варианты ответов</h2>
          <div class="add-answer">
            <app-button size="small"
                        type="left"
                        icon="add_circle"
                        [fill]="true"
                        display="fill"
                        color="cell"
                        sizeText="default"
                        buttonContent="Добавить вариант ответа"
                        (click)="addNewAnswer()">
            </app-button>
          </div>
          <div class="variants" [formGroup]="blockAnswersForm">
            <div class="variant-block" formArrayName="answers" *ngFor="let fg of blockAnswers.controls; let i = index">
              <div class="variant-block-header">
                <div class="variant-block-header-buttons">
                  <app-icon-button size="small"
                                   type="circle"
                                   icon="keyboard_arrow_up"
                                   display="fill"
                                   color="outline-low"
                                   (click)="handleAnswerSortTopClick(i)"/>
                  <app-icon-button size="small"
                                   type="circle"
                                   icon="keyboard_arrow_down"
                                   display="fill"
                                   color="outline-low"
                                   (click)="handleAnswerSortBottomClick(i)"/>
                </div>
                <div class="variant-block-header-input" [formGroup]="fg">
                  <input placeholder="Название варианта ответа" formControlName="name">
                </div>
              </div>
              <div class="variant-block-documents">
                @if (getDocumentsControlsByAnswerIndex(i)) {
                  <div class="variant-block-documents-element" *ngFor="let dg of getDocumentsControlsByAnswerIndex(i); let j = index">
                    <div class="variant-block-documents-element-header">Документ</div>
                    <div class="variant-block-documents-element-body" [formGroup]="dg">
                      <div class="variant-block-documents-element-body-input">
                        <app-select type="list"
                                    bg="white"
                                    placeholder="Укажите образец"
                                    [options]="documentsTemplates"
                                    formControlName="documentTemplateId"
                        ></app-select>
                      </div>
                      <div class="variant-block-documents-element-body-input">
                        <core-text-input formControlName="title" placeholder="Название документа"></core-text-input>
                      </div>
                      <div class="variant-block-documents-element-body-input">
                        <core-text-input formControlName="note" placeholder="Примечание"></core-text-input>
                      </div>
                      <div class="variant-block-documents-element-body-input">
                        <app-select type="list"
                                    bg="white"
                                    placeholder="Способ передачи"
                                    [options]="documentsTransferMethods"
                                    formControlName="documentTransferMethodId"
                        ></app-select>
                        <!-- <app-button size="normal"
                                    type="left"
                                    icon="add_circle"
                                    display="fill"
                                    color="primary"
                                    sizeText="default"
                                    buttonContent="Добавить"
                        ></app-button> -->
                      </div>

                      <div class="variant-block-documents-element-body-input">
                        <h5>В каком виде предъявляется?</h5>
                        <div class="variant-block-documents-element-body-input-badges">
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedForm(i, j, PresendedFormEnum.Original)}"
                                (click)="handleDocumentPresendedFormClick(i, j, PresendedFormEnum.Original)">
                            Оригинал
                          </span>
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedForm(i, j, PresendedFormEnum.Copy)}"
                                (click)="handleDocumentPresendedFormClick(i, j, PresendedFormEnum.Copy)">
                            Копия
                          </span>
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedForm(i, j, PresendedFormEnum.CertifiedCopy)}"
                                (click)="handleDocumentPresendedFormClick(i, j, PresendedFormEnum.CertifiedCopy)">
                            Заверенная копия
                          </span>
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedForm(i, j, PresendedFormEnum.Information)}"
                                (click)="handleDocumentPresendedFormClick(i, j, PresendedFormEnum.Information)">
                            Информация
                          </span>
                        </div>
                      </div>

                      <div class="variant-block-documents-element-body-input">
                        <h5>В каком виде прикладывается к пакету документов?</h5>
                        <div class="variant-block-documents-element-body-input-badges">
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedPack(i, j, PresendedPackEnum.Original)}"
                                (click)="handleDocumentPresendedPackClick(i, j, PresendedPackEnum.Original)">
                            Оригинал
                          </span>
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedPack(i, j, PresendedPackEnum.Scan)}"
                                (click)="handleDocumentPresendedPackClick(i, j, PresendedPackEnum.Scan)">
                            Копия
                          </span>
                          <span class="variant-block-documents-element-body-input-badges-element"
                                [ngClass]="{'active': isEqualDocumentPresendedPack(i, j, PresendedPackEnum.NotIncluded)}"
                                (click)="handleDocumentPresendedPackClick(i, j, PresendedPackEnum.NotIncluded)">
                            Не предусмотренно
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="variant-block-documents-element-footer">
                      <app-button size="small"
                                  type="left"
                                  icon="delete_forever"
                                  display="cell"
                                  color="cell-lowest"
                                  sizeText="default"
                                  buttonContent="Удалить документ"
                                  (click)="removeDocumentClick(i, j)">
                      </app-button>
                    </div>
                  </div>
                }
              </div>
              <div class="variant-block-footer">
                <div class="variant-block-footer-add-doc">
                  <app-button size="small"
                              type="left"
                              icon="add_circle"
                              [fill]="true"
                              display="fill"
                              color="cell"
                              sizeText="default"
                              buttonContent="Добавить документ"
                              (click)="addDocumentClick(i)">
                  </app-button>
                </div>
                <div class="variant-block-footer-delete-variant">
                  <app-button size="small"
                              type="left"
                              icon="delete_forever"
                              display="fill"
                              color="cell-lowest"
                              sizeText="default"
                              buttonContent="Удалить вариант"
                              (click)="removeAnswer(i)">
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </app-modal>

    <app-modal
      width="mini"
      heading="Сохранение шаблона"
      id="save-modal"
      (submitAction)="handleSaveButtonClick()"
      >
      <div class="information">
        <form class="information__form" [formGroup]="createTemplateForm">
            <div class="modal__general csm_container">
              <p class="modal__input-title">Название шаблона</p>
              <div class="modal__search_general modal__search_general_multiline">
                  <core-textarea-input placeholder="Название шаблона"
                                       [formControlName]="'name'"
                                       (click)="resetErrors()"
                                       [error]="createTemplateError"
                                       formControlName="'name'"
                  ></core-textarea-input>
              </div>
              <div class="modal__general-period">
                  <p class="modal__input-title">Дата начала активности</p>
                  <core-date-input class="input-grid1"
                                   [formControlName]="'activityStart'"
                  ></core-date-input>
                  <p class="modal__input-title">Дата окончания активности</p>
                  <core-date-input class="input-grid2"
                                   [formControlName]="'activityEnd'"
                  ></core-date-input>
              </div>
          </div>
        </form>
      </div>
    </app-modal>
  </div>
} @else {
  <div class="container-modal">
    <svg #chart xmlns="http://www.w3.org/2000/svg" [attr.width]="WIDTH" [attr.height]="HEIGHT" [attr.viewBox]="viewBox"
      (wheel)="onMouseScroll($event)"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      class="chart-modal">
      <defs>
        <marker id='head' orient="auto-start-reverse" viewBox="0 0 14 12" markerWidth='14' markerHeight='12'
          markerUnits="userSpaceOnUse" refX='3' refY='5'>
          <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
        </marker>
        <marker id="round" markerWidth="14" markerHeight="14" refX="5" refY="5" orient="auto">
          <circle cx="5" cy="5" r="3" fill="black" />
          <circle cx="5" cy="5" r="2" fill="white" />
        </marker>
      </defs>
      <g *ngFor="let e of chartElementList">
        <path *ngFor="let connection of getTopConnections(e)" marker-start="url(#round)" marker-end="url(#head)" [attr.d]="connection"
          [style.stroke]="'#211F1F'" class="connection" />
        <g *ngIf="e.item.type === ServiceBlockTypes.Template">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[0]"
            [style.stroke]="colors[0]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6">
          </rect>
          @if (e.item.documents && e.item.documents.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#f" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="../../../assets/icons/chart-icons/document.svg">
              </image>
            </g>
          }
          <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 + 10" [attr.y]="e.y-40"
            href="../../../assets/icons/leftMenuIcons/templatesPageIcon.svg" >
          </image>
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Intermediate">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[0]"
            [style.stroke]="colors[0]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6">
          </rect>
          @if (e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="assets/icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
          {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Answer">
          <rect class="item answer" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[3]"
                [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2" [attr.width]="ELEMENT_WIDTH"
                [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6">
          </rect>
          @if (e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="assets/icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-default" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Result">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[1]"
            [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2" [attr.width]="ELEMENT_WIDTH"
            [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
            @if (e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="assets/icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-succ" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
        <g *ngIf="e.item.type === ServiceBlockTypes.Closed">
          <rect class="item" [class.selected]="e.item.id===selectedItem?.id" [style.fill]="colors[2]"
            [style.stroke]="colors[2]" [attr.x]="e.x - ELEMENT_WIDTH/2" [attr.y]="e.y - ELEMENT_HEIGHT/2"
            [attr.width]="ELEMENT_WIDTH" [attr.height]="ELEMENT_HEIGHT" rx="6" ry="6"></rect>
            @if (e.item.documents && e.item.documents!.length > 0) {
            <g id="documents" (click)="showBlockDocuments(e.item)">
              <circle r="22" [attr.cx]="e.x - ELEMENT_WIDTH/2" [attr.cy]="e.y - ELEMENT_HEIGHT/5"
                      fill="#fff" stroke="#EFDBD7" class="item-menu-el">
              </circle>
              <image height="24" width="24" [attr.x]="e.x - ELEMENT_WIDTH/2 - 12" [attr.y]="e.y - ELEMENT_HEIGHT/5 - 12"
                     href="assets/icons/document.svg">
              </image>
            </g>
          }
          <text [attr.x]="e.x" [attr.y]="e.y" class="item-header">
            <tspan class="item-badge-false" [attr.x]="e.x" [attr.y]="e.y - 20" dy="1.2em">
              {{e.item.name.length > 20 ? e.item.name.slice(0, 20) + '...' : e.item.name}}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
    <div class="toolbox toolbox-modal">
      <button (click)="zoomOut()" class="button-centered" aria-label="ZoomOut"
        [disabled]="zoomValue == minZoom || !chartElementList.length" [ngClass]="{'button-disabled': zoomValue == minZoom || !chartElementList.length}">
        <span class="material-symbols-outlined span">
          remove
        </span>
      </button>
      <span class="span2">
        {{zoomValue + '%'}}
      </span>
      <button (click)="zoomIn()" class="button-centered" aria-label="ZoomIn"
        [disabled]="zoomValue == maxZoom || !chartElementList.length" [ngClass]="{'button-disabled': zoomValue == maxZoom || !chartElementList.length }">
        <span class="material-symbols-outlined span">
          add
        </span>
      </button>
    </div>
  </div>
}

<app-modal id="documents-modal" heading="Документы блока">
  @for (document of selectedItem?.documents; track document) {
    <div class="service-module__block-content-final-document">
      <div class="service-module__block-content-final-document-center">
        <div class="service-module__block-content-final-document-title">
          {{document.title}}
        </div>
        <div class="service-module__block-content-final-document-badges">
          <app-badge size="normal" display="outline" color="outline-low" size_text="default"
                     [badge_content]="'Проверка: ' + presendedFormString(document.presendedFormType)"></app-badge>
          <app-badge size="normal" display="outline" color="outline-low" size_text="default"
                     [badge_content]="'В пакет: ' + presendedPackString(document.presendedPackType)"></app-badge>
        </div>
        <div class="service-module__block-content-final-document-description">
          {{document.note}}
        </div>
      </div>
      <!-- <div class="service-module__block-content-final-document-right">
        <app-icon-button size="small" type="square" display="outline" icon="visibility" color="cell"></app-icon-button>
      </div> -->
    </div>
  }
</app-modal>

<app-modal id="add-template-modal" heading="Привязать шаблон">
  <h5 class="title-bold-h5 modal__title modal__title_info modal__title_relations">Выберите шаблон</h5>
  <core-text-input placeholder="Поиск шаблона"
                   leftIcon="search"
                   [formControl]="templateSearchControl"
  ></core-text-input>
  <ol class="clone-list">
    @for (template of templatesToClone; track template) {
      <li class="clone-list-element" (click)="handleSelectTemplate(template)">{{template.name}}</li>
    }
  </ol>
</app-modal>

<app-modal id="add-child-modal" heading="Привязать блок">
  <h5 class="title-bold-h5 modal__title modal__title_info modal__title_relations">Выберите блок</h5>
  <ol class="clone-list">
    @for (item of possibleChildBlocks; track item) {
      <li class="clone-list-element" (click)="handleSelectChild(item)">{{item.name}}</li>
    }
  </ol>
</app-modal>
