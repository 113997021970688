export const convertFileSizeHelper = (bytes: number) => {
  const marker = 1024;
  const decimal = 2;
  const kiloBytes = marker;
  const megaBytes = marker * marker;
  const gigaBytes = marker * marker * marker;

  if (bytes < kiloBytes) return bytes + ' Байт';
  else if (bytes < megaBytes)
    return (bytes / kiloBytes).toFixed(decimal) + ' кБ';
  else if (bytes < gigaBytes)
    return (bytes / megaBytes).toFixed(decimal) + ' мБ';
  else return (bytes / gigaBytes).toFixed(decimal) + ' гБ';
};
