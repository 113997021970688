<div class="search">
  <div class="search__container">
    @if (!services().length) {
      <div class="search__not-found">
        Услуги не найдены
      </div>
    } @else {
      @for (service of services(); track service) {
        <a [routerLink]="'/services/' + service.id">
          <div class="search__element">
            <div class="search__element-title">
              {{service.title}}
            </div>
            <div class="search__element-name">
              @if (service.name.length < 100) {
                {{service.name}}
              } @else {
                {{service.name.slice(0, 99) + '...'}}
              }
            </div>
            <div class="search__element-bagdes">
              @for (badge of service.departments; track badge) {
                <div class="search__element-bagdes-badge">
                  <app-badge size="small" display="fill" size_text="small" color="container" badge_content="{{badge.name}}"></app-badge>
                </div>
              }
              @for (badge of service.lifeSituations; track badge) {
                <div class="search__element-bagdes-badge">
                  <app-badge size="small" display="fill" size_text="small" color="container" badge_content="{{badge.name}}"></app-badge>
                </div>
              }
              @for (badge of service.territoriality; track badge) {
                <div class="search__element-bagdes-badge">
                  <app-badge size="small" display="fill" size_text="small" color="container" badge_content="{{badge.name}}"></app-badge>
                </div>
              }
              @for (badge of service.applicantCategories; track badge) {
                <div class="search__element-bagdes-badge">
                  <app-badge size="small" display="fill" size_text="small" color="container" badge_content="{{badge.name}}"></app-badge>
                </div>
              }
            </div>
          </div>
        </a>
      }
    }
  </div>
</div>
