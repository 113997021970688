<div class="services-ui">
  <div class="docs-sample-header">
    <div class="docs-sample-header__left">
      <p class="docs-sample-header__title title-bold-h1">Образцы документов</p>
      <app-badge
        class="docs-sample-header__badge"
        size="normal"
        display="tint"
        color="secondary-container"
        size_text="default"
        badge_content="{{ docsSamples.length }}">
      </app-badge>
    </div>
    <app-button
      size="normal"
      type="left"
      icon="add_circle"
      [fill]="true"
      display="fill"
      color="primary"
      sizeText="default"
      buttonContent="Добавить"
      (click)="onAddClick()">
    </app-button>
  </div>
  <div class="docs-sample-search area_white_bg">
    <div class="docs-sample-search__input">
      <core-text-input
        placeholder="Поиск документа"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
  <div class="docs-sample-tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!-- <button class="filter">
      Сперва новые
      <span class="material-symbols-outlined"> keyboard_arrow_down </span>
    </button> -->
  </div>
</div>
<div class="docs-sample-main">
  @if (currentTab === 'tabs__actual') {
    @if (docsSamples.length) {
      <div class="docs-sample-main__list">
        @for (docsSample of docsSamples; track docsSample.id) {
          <div class="docs-sample-main__item">
            <div
              class="item-header"
              [ngClass]="{
                border_bottom:
                  docsSample.documentsTemplatesAttachments.length > 0
              }">
              <p class="item-header__title title-bold-h5 on-container">
                {{ docsSample.title }}
              </p>
              <div class="actions">
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="cancel"
                  display="outline"
                  color="outline-low"
                  (click)="deleteDocSample(docsSample.id, docsSample.title)"
                ></app-icon-button>
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="edit_square"
                  display="outline"
                  color="container-lowest"
                  (click)="openEditModal(docsSample.id)"
                ></app-icon-button>
              </div>
            </div>
            @if (
              docsSample.documentsTemplatesAttachments &&
              docsSample.documentsTemplatesAttachments.length
            ) {
              <div class="item-main">
                @for (
                  container of docsSample.documentsTemplatesAttachments;
                  track container.id
                ) {
                  <div class="item-main__point">
                    <p class="item-main__title text-default on-container">
                      {{ container.originalName }}
                    </p>
                    <!--                  <p class="item-main__author">{{ container.author }}</p>-->
                    <div class="item-main__info">
                      <!--                    <div class="item-main__info-date text-default on-container-low">от {{ container.dateStart }}</div>-->
                      <app-badge
                        size="small"
                        display="tint"
                        color="tertiary-container"
                        size_text="small"
                        badge_content="{{
                          getFileExtensionHelper(container)
                        }}"></app-badge>
                      <div
                        class="item-main__info-size text-default on-container-low">
                        {{ convertFileSizeHelper(container.size) }}
                      </div>
                      <a
                        class="item-main__info-link text-default"
                        (click)="openFileInNewTabHelper(container.link)"
                        >Открыть</a
                      >
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    } @else {
      <p class="text-bigger">Нет актуальных образцов документов</p>
    }
  } @else {
    @if (archived.length > 0) {
      <div class="docs-sample-main__list">
        @for (docsSample of archived; track docsSample.id) {
          <div class="docs-sample-main__item">
            <div
              class="item-header"
              [ngClass]="{
                border_bottom:
                  docsSample.documentsTemplatesAttachments.length > 0
              }">
              <p class="item-header__title title-bold-h5 on-container">
                {{ docsSample.title }}
              </p>
              <div class="actions">
                <!-- <app-icon-button
                  size="small"
                  type="circle"
                  icon="cancel"
                  display="outline"
                  color="outline-low"
                  (click)="
                    deleteDocSample(docsSample.id, docsSample.title)
                  "></app-icon-button>
                <app-icon-button
                  size="small"
                  type="circle"
                  icon="edit_square"
                  display="outline"
                  color="container-lowest"
                  (click)="openEditModal(docsSample.id)"></app-icon-button> -->
              </div>
            </div>
            @if (
              docsSample.documentsTemplatesAttachments &&
              docsSample.documentsTemplatesAttachments.length
            ) {
              <div class="item-main">
                @for (
                  container of docsSample.documentsTemplatesAttachments;
                  track container.id
                ) {
                  <div class="item-main__point">
                    <p class="item-main__title text-default on-container">
                      {{ container.originalName }}
                    </p>
                    <!--                  <p class="item-main__author">{{ container.author }}</p>-->
                    <div class="item-main__info">
                      <!--                    <div class="item-main__info-date text-default on-container-low">от {{ container.dateStart }}</div>-->
                      <app-badge
                        size="small"
                        display="tint"
                        color="tertiary-container"
                        size_text="small"
                        badge_content="{{
                          getFileExtensionHelper(container)
                        }}"></app-badge>
                      <div
                        class="item-main__info-size text-default on-container-low">
                        {{ convertFileSizeHelper(container.size) }}
                      </div>
                      <a
                        class="item-main__info-link text-default"
                        (click)="openFileInNewTabHelper(container.link)"
                        >Открыть</a
                      >
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    } @else {
      <p class="text-bigger">Нет архивных образцов документов</p>
    }
  }
</div>

<app-modal
  class="sample-modal area_white_bg"
  id="add-sample-modal"
  submitButtonText="Добавить"
  heading="Новый образец"
  [requestConfirmToCancel]="sampleForm.dirty"
  (submitAction)="submitEvent()">
  <form
    class="docs-sample-form"
    [formGroup]="sampleForm"
    (ngSubmit)="submitEvent()">
    <fieldset class="docs-sample-form__fieldset">
      <div class="docs-sample-form__input area_white_bg">
        <p class="text-big">Название документа</p>
        <core-text-input
          placeholder="Введите название"
          [formControlName]="'title'"
          [error]="error"
          (click)="resetErrors()"></core-text-input>
      </div>
      <div
        class="docs-sample-form__upload container-low"
        [ngClass]="{ 'docs-sample-form__upload_error': fileUploadError }"
        (click)="fileInput.click()"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)">
        <input #fileInput
          type="file"
          class="form-upload-hidden"
          (change)="onFileSelected($event)"
          multiple 
        />
        <div class="form-upload">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.66663 25.9999C6.82392 25.9999 5.25213 25.3607 3.95127 24.0823C2.65042 22.8039 2 21.2414 2 19.3948C2 17.7384 2.53291 16.2816 3.59873 15.0243C4.66456 13.7671 5.98635 13.0222 7.5641 12.7898C7.99146 10.7966 8.99359 9.16667 10.5705 7.9C12.1474 6.63333 13.9572 6 16 6C18.4142 6 20.4622 6.84088 22.144 8.52263C23.8257 10.2044 24.6666 12.2524 24.6666 14.6666V15.3333H25.0769C26.482 15.4427 27.6538 16.0077 28.5922 17.0282C29.5307 18.0487 29.9999 19.2615 29.9999 20.6666C29.9999 22.1538 29.4871 23.4145 28.4615 24.4487C27.4358 25.4828 26.1794 25.9999 24.6923 25.9999H17.4102C16.7367 25.9999 16.1666 25.7666 15.7 25.2999C15.2333 24.8333 15 24.2632 15 23.5897V16.2871L12.5333 18.7128L11.1282 17.3205L16 12.4487L20.8717 17.3205L19.4666 18.7128L16.9999 16.2871V23.5897C16.9999 23.6922 17.0427 23.7863 17.1282 23.8718C17.2136 23.9572 17.3077 24 17.4102 24H24.6666C25.6 24 26.3889 23.6777 27.0333 23.0333C27.6777 22.3889 28 21.6 28 20.6666C28 19.7333 27.6777 18.9444 27.0333 18.3C26.3889 17.6555 25.6 17.3333 24.6666 17.3333H22.6666V14.6666C22.6666 12.8222 22.0166 11.25 20.7166 9.94997C19.4166 8.64997 17.8444 7.99997 16 7.99997C14.1555 7.99997 12.5833 8.64997 11.2833 9.94997C9.9833 11.25 9.3333 12.8222 9.3333 14.6666H8.641C7.37776 14.6666 6.28843 15.1222 5.37303 16.0333C4.45766 16.9444 3.99997 18.0444 3.99997 19.3333C3.99997 20.6222 4.45552 21.7222 5.36663 22.6333C6.27775 23.5444 7.37774 24 8.66663 24H12V25.9999H8.66663Z"
              fill="#251917" />
          </svg>
          <p class="form-upload__text">
            Перетащите файлы в эту область или
            <span class="form-upload__span">выберите файлы на устройстве</span>
          </p>
        </div>
      </div>
      @if (fileUploadError){
        <p class="docs-sample-form__upload-error text-small">{{ fileUploadError }}</p>
      }
      <div class="docs-sample-form__list">
        @if (files.length > 0) {
          @for (file of files; track file.name; let i = $index) {
            <div class="docs-sample-form__item">
              <button (click)="removeFile(i)">
                <span class="material-symbols-outlined on-container-lowest">
                  delete_forever
                </span>
              </button>
              <div class="item-form-content">
                <p class="item-form-content__title text-default">
                  {{ file.name }}
                </p>
                <div class="item-form-content__info">
                  <app-badge
                    size="small"
                    display="fill"
                    color="container-lowest"
                    size_text="small"
                    badge_content="Загружен"></app-badge>
                  <app-badge
                    size="small"
                    display="tint"
                    color="tertiary-container"
                    size_text="small"
                    [badge_content]="
                      file.name.substring(file.name.lastIndexOf('.') + 1)
                        | uppercase
                    "></app-badge>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </fieldset>
  </form>
</app-modal>

<app-modal
  id="edit-modal"
  submitButtonText="Редактировать"
  heading="Редактирование образца"
  [requestConfirmToCancel]="editForm.dirty"
  (submitAction)="editDocSample()">
  <form
    class="docs-sample-form"
    [formGroup]="editForm"
    (ngSubmit)="editDocSample()">
    <fieldset class="docs-sample-form__fieldset">
      <div class="docs-sample-form__input area_white_bg">
        <p class="text-big">Название документа</p>
        <core-text-input
          placeholder="Введите название"
          [formControlName]="'title'"
          [error]="error"
          (click)="resetErrors()"></core-text-input>
      </div>
      <div
        class="docs-sample-form__upload container-low"
        (click)="fileInput.click()"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)">
        <input
          type="file"
          class="form-upload-hidden"
          (change)="onFileSelected($event)"
          #fileInput
          multiple />
        <div class="form-upload">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.66663 25.9999C6.82392 25.9999 5.25213 25.3607 3.95127 24.0823C2.65042 22.8039 2 21.2414 2 19.3948C2 17.7384 2.53291 16.2816 3.59873 15.0243C4.66456 13.7671 5.98635 13.0222 7.5641 12.7898C7.99146 10.7966 8.99359 9.16667 10.5705 7.9C12.1474 6.63333 13.9572 6 16 6C18.4142 6 20.4622 6.84088 22.144 8.52263C23.8257 10.2044 24.6666 12.2524 24.6666 14.6666V15.3333H25.0769C26.482 15.4427 27.6538 16.0077 28.5922 17.0282C29.5307 18.0487 29.9999 19.2615 29.9999 20.6666C29.9999 22.1538 29.4871 23.4145 28.4615 24.4487C27.4358 25.4828 26.1794 25.9999 24.6923 25.9999H17.4102C16.7367 25.9999 16.1666 25.7666 15.7 25.2999C15.2333 24.8333 15 24.2632 15 23.5897V16.2871L12.5333 18.7128L11.1282 17.3205L16 12.4487L20.8717 17.3205L19.4666 18.7128L16.9999 16.2871V23.5897C16.9999 23.6922 17.0427 23.7863 17.1282 23.8718C17.2136 23.9572 17.3077 24 17.4102 24H24.6666C25.6 24 26.3889 23.6777 27.0333 23.0333C27.6777 22.3889 28 21.6 28 20.6666C28 19.7333 27.6777 18.9444 27.0333 18.3C26.3889 17.6555 25.6 17.3333 24.6666 17.3333H22.6666V14.6666C22.6666 12.8222 22.0166 11.25 20.7166 9.94997C19.4166 8.64997 17.8444 7.99997 16 7.99997C14.1555 7.99997 12.5833 8.64997 11.2833 9.94997C9.9833 11.25 9.3333 12.8222 9.3333 14.6666H8.641C7.37776 14.6666 6.28843 15.1222 5.37303 16.0333C4.45766 16.9444 3.99997 18.0444 3.99997 19.3333C3.99997 20.6222 4.45552 21.7222 5.36663 22.6333C6.27775 23.5444 7.37774 24 8.66663 24H12V25.9999H8.66663Z"
              fill="#251917" />
          </svg>
          <p class="form-upload__text">
            Перетащите файлы в эту область или
            <span class="form-upload__span">выберите файлы на устройстве</span>
          </p>
        </div>
      </div>
      <div class="docs-sample-form__list">
        @if (files.length > 0) {
          @for (file of files; track file.name; let i = $index) {
            <div class="docs-sample-form__item">
              <button (click)="removeFile(i)">
                <span class="material-symbols-outlined on-container-lowest">
                  delete_forever
                </span>
              </button>
              <div class="item-form-content">
                <p class="item-form-content__title text-default">
                  {{ file.name }}
                </p>
                <div class="item-form-content__info">
                  <app-badge
                    size="small"
                    display="fill"
                    color="container-lowest"
                    size_text="small"
                    badge_content="Загружен"></app-badge>
                  <app-badge
                    size="small"
                    display="tint"
                    color="tertiary-container"
                    size_text="small"
                    [badge_content]="
                      file.name.substring(file.name.lastIndexOf('.') + 1)
                        | uppercase
                    "></app-badge>
                </div>
              </div>
            </div>
          }
        }

        @if (editingElementAttachments.length > 0) {
          @for (attachment of editingElementAttachments; track attachment.id) {
            <div class="docs-sample-form__item">
              <button (click)="deleteAttachment($event, attachment.id)">
                <span class="material-symbols-outlined on-container-lowest">
                  delete_forever
                </span>
              </button>
              <div class="item-form-content">
                <p class="item-form-content__title text-default">
                  {{ attachment.originalName }}
                </p>
                <div class="item-form-content__info">
                  <app-badge
                    size="small"
                    display="fill"
                    color="container-lowest"
                    size_text="small"
                    badge_content="Загружен"></app-badge>
                  <app-badge
                    size="small"
                    display="tint"
                    color="tertiary-container"
                    size_text="small"
                    [badge_content]="
                      attachment.fileName.substring(
                        attachment.fileName.lastIndexOf('.') + 1
                      ) | uppercase
                    "></app-badge>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </fieldset>
  </form>
</app-modal>
