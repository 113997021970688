@if (display() === 'outline') {
  <button type="{{typing()}}" [class]="size() + ' ' + color() + ' outline'">
    @if (type() === 'left') {
      <span
        class="material-symbols-outlined icon-{{ iconSize() }}"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
      >
        {{ icon() }}
      </span>
    }
    @if (color() === 'inverse-container') {
      <span [class]="display() + '-inner-button on-inverse-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container on-container-lowest subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else if (color() === 'outline-low') {
      <span [class]="display() + '-inner-button on-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge inverse-container on-inverse-container subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else if (color() === 'cell-lowest') {
      <span [class]="display() + '-inner-button on-container subtitle-' + sizeText() + ' ' + color()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container on-container-lowest subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else if (color() === 'container-lowest') {
      <span [class]="display() + '-inner-button on-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container on-container-lowest subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else {
      <span [class]="display() + '-inner-button subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container-low subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    }
    @if (type() === 'right') {
      <span
        class="material-symbols-outlined"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
      >
        {{ icon() }}
      </span>
    }
  </button>
} @else {
  <button type="{{typing()}}" [class]="size() + ' ' + color() + ' outline-low'" [ngClass]="{'overlay': display() === 'overlay'}">
    @if (type() === 'left') {
      <span
        class="material-symbols-outlined"
        [ngClass]="{
          'material-symbols-filled': fill(),
          'overlay-icon': display() === 'overlay'
        }"
      >
        {{ icon() }}
      </span>
    }
    @if (color() === 'inverse-container') {
      <span [class]="display() + '-inner-button on-inverse-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container on-container-lowest subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else if (color() === 'outline-low') {
      <span [class]="display() + '-inner-button on-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge inverse-container on-inverse-container subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else if (color() === 'container-lowest') {
      <span [class]="display() + '-inner-button on-container subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container on-container-lowest subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    } @else {
      <span [class]="display() + '-inner-button subtitle-' + sizeText()">{{ buttonContent() }}</span>
      @if (usedBadge()) {
        <span [class]="display() + '-inner-badge container-low subtitle-small inner-badge'">{{ badgeContent() }}</span>
      }
    }
    @if (type() === 'right') {
      <span
        class="material-symbols-outlined icon-{{iconSize()}}"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
      >
        {{ icon() }}
      </span>
    }
  </button>
}
