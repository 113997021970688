import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconButtonComponent } from '@core/components';
import { InputComponent } from '@core/components/inputs/input.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'core-textarea-input',
  standalone: true,
  imports: [
    FormsModule,
    IconButtonComponent,
    NgClass,
  ],
  templateUrl: './textarea-input.component.html',
  styleUrl: './textarea-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaInputComponent
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaInputComponent extends InputComponent<string> {
  placeholder = input<string>();
  tooltip = input<string>();
  rows = input<number>(4);

  clear() {
    this.onInput(this.defaultValue());
  }

  override defaultValue(): string {
    return '';
  }
}
