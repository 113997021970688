import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { Service } from '@core/models';
import { ButtonComponent } from '@core/components';
import { BadgeComponent } from '@core/components';
import { Router } from '@angular/router';
import { getServiceTypeTranslation, parsePeriodHelper } from '@core/utils';

@Component({
  selector: 'app-attention',
  standalone: true,
  imports: [
    ButtonComponent,
    BadgeComponent,
  ],
  templateUrl: './services-page-attention.component.html',
  styleUrl: './services-page-attention.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesPageAttentionComponent {
  attentionServices = input.required<Service[]>();
  constructor(private router: Router) {};

  parseActivity = parsePeriodHelper;

  handleServiceVisibilityClick(id: number, type: string) {
    this.router.navigate(['/services/' + id], { queryParams: { type: type } });
  }

  protected readonly Array = Array;
  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;
}
