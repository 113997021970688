import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-list-item',
  standalone: true,
  imports: [],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() topline?: string;
  @Input() heading!: string;
  @Input() subtext?: string;

  @Input() icon?: any; // Компонент картинки либо иконки слева
  @Input() button?: any; // Компонент кнопки справа
}
