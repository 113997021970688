import { Component, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { AlertComponent, LoaderComponent } from '@core/components';
import { LoaderService, UserService } from '@core/services';
import { OverlayComponent } from '@core/components/overlay/overlay.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgClass,
    AlertComponent,
    LoaderComponent,
    OverlayComponent
  ],
  providers: [LoaderComponent, LoaderService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public isLayoutVisible: boolean = true;
  public isShowing: boolean = true;

  constructor(
    private readonly userService: UserService,
    protected readonly loaderService: LoaderService
  ) {
    this.userService.isAuthenticated.subscribe((isAuthenticated: boolean) => {
      this.isLayoutVisible = isAuthenticated;
    });
  }
}
