import { ButtonComponent } from '@core/components';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceBlockTypes } from '@core/utils';

@Component({
  selector: 'app-chart-menu-list',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './menu-list.component.html',
  styleUrl: './menu-list.component.scss',
})
export class MenuListComponent {
  @Input() isFull = false;
  @Input() showAddBar = false;
  @Input() type: 'service' | 'template' = 'service';
  @Input() canAddChild = false;
  @Output() handleClick = new EventEmitter();
  @Output() handleCloseClick = new EventEmitter();
  @Output() handleOutsideClick = new EventEmitter();
  @Output() handleAddChildClick = new EventEmitter();


  constructor(protected router: Router) {}

  onClick(type: ServiceBlockTypes): void {
    this.handleClick.emit(type);
  }

  onCloseClick(): void {
    this.handleCloseClick.emit();
  }

  onAddChildClick(){
    this.handleAddChildClick.emit();
  }

  protected readonly ServiceBlockTypes = ServiceBlockTypes;
}
