<div class="service-container">
  <core-section-header title="Услуга" [buttons]="buttons"></core-section-header>
  <div class="tabs">
    <button
      class="tab"
      [ngClass]="{ tab_active: isAbout }"
      (click)="isAbout = true">
      Об услуге
    </button>
    <button
      class="tab"
      [ngClass]="{ tab_active: !isAbout }"
      (click)="isAbout = false">
      Справка
    </button>
    @if (route.snapshot.queryParams['type'] === 'needAttention' || isNeedAttention) {
      <p class="text_error text-big">
        <img src="../../../assets/icons/error-icon.svg" alt="Ошибка" />
        Изменены регулирующие документы. Подтвердите актуальность услуги
      </p>
    }
  </div>
  @if (isAbout) {
    <app-service-about [service]="service"></app-service-about>
  }
  @else {
    <app-service-reference [service]="service"></app-service-reference>
  }
</div>

<app-modal
  id="update-service-modal"
  heading="Обновить услугу"
  [requestConfirmToCancel]="updateServiceForm.dirty"
  (submitAction)="onFormSubmit()">
  <div class="modal">
    <div class="tabs tabs_border">
      <button
        class="tab"
        [ngClass]="{ tab_active: modalIsAbout }"
        (click)="modalIsAbout = true">
        Об услуге
      </button>
      <button
        class="tab"
        [ngClass]="{ tab_active: !modalIsAbout }"
        (click)="modalIsAbout = false">
        Справка
      </button>
    </div>
    @if (modalIsAbout) {
      <form [formGroup]="updateServiceForm">
        <h5
          class="title-bold-h5 modal__title modal__title_info modal__title_relations">
          1. Общая информация
        </h5>
        <div class="modal__general csm_container area_beige_bg">
          <p class="modal__input-title">Полное наименование услуги</p>
          <div class="modal__search_general modal__search_general_multiline">
            <core-text-input
              placeholder="Полное наименование услуги"
              [formControlName]="'name'"
              (click)="resetErrors()"
              [error]="sierError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <p class="modal__input-title">Краткое наименование</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Краткое наименование"
              [formControlName]="'title'"
              (click)="resetErrors()"
              [error]="sierError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <p class="modal__input-title">Номер СИЭР</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Номер СИЭР"
              [formControlName]="'sierNumber'"
              (click)="resetErrors()"
              [error]="sierError"
              (keydown.enter)="handlePressEnter($event)">
            </core-text-input>
          </div>
          <div class="modal__general-period">
            <p class="modal__input-title title-grid1">Дата начала активности</p>
            <app-search
              [whiteBg]="true"
              [searchIcon]="false"
              placeholder="День / Месяц / Год"
              class="input-grid1"
              type="date"
              [control]="control('activityStart')"></app-search>
            <p class="modal__input-title title-grid2">
              Дата окончания активности
            </p>
            <app-search
              [whiteBg]="true"
              [searchIcon]="false"
              placeholder="День / Месяц / Год"
              class="input-grid2"
              type="date"
              [control]="control('activityEnd')"></app-search>
          </div>
        </div>

        <div class="modal__relations">
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            2. Связи с документами
          </h5>

          <div class="modal__relations-select-container">
            <app-autocomplete
              placeholder="Выбрать документы для связи"
              [suggestions]="suggestionDocuments"
              [getName]="getDocumentName"
              [fill]="true"
              (inputValueChange)="searchDocuments($event)"
              (selected)="addDocument($event)"
            ></app-autocomplete>

            @if (selectedDocuments.length) {
              <div class="modal__relations-list">
                <app-selected-documents
                  [items]="selectedDocuments"
                  (remove)="removeDocument($event)"
                ></app-selected-documents>
              </div>
            }
          </div>
        </div>

        <div class="modal__classifiers">
          <h5 class="title-bold-h5 modal__title modal__title_info">
            3. Классификаторы
          </h5>
          @if (lifeSituations.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              <div *ngIf="selectedClassifiers.get('lifeSituations').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('lifeSituations')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['lifeSituations']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                 (selected)="addClassifier('lifeSituations', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (applicantCategories.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              <div *ngIf="selectedClassifiers.get('applicantCategories').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('applicantCategories')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['applicantCategories']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                 (selected)="addClassifier('applicantCategories', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (departments.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              <div *ngIf="selectedClassifiers.get('departments').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('departments')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('departments', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['departments']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('departments', $event)"
                 (selected)="addClassifier('departments', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (territoriality.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              <div *ngIf="selectedClassifiers.get('territoriality').length" class="badges">
                 <div *ngFor="let classifier of selectedClassifiers.get('territoriality')" class="badge">
                   <app-badge
                     badge_content="{{ classifier.name }}"
                     size="normal"
                     display="fill"
                     color="inverse-container"
                     size_text="default"
                     right_icon="cancel"
                     [fill]="true"
                     [used_right_icon]="true"
                     (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                    ></app-badge>
                 </div>
              </div>

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          }
        </div>
      </form>
    }

    @if (!modalIsAbout) {
      <div class="ref-info">
        <nav class="left-navigation left-navigation_wysiwyg">
          <app-menu-point
            text="Общее описание"
            [shouldNavigate]="false"
            [isActive]="activeArray[0]"
            (click)="handleMenuItemClick(0)"></app-menu-point>

          <app-menu-point
            text="Порядок действий"
            [shouldNavigate]="false"
            [isActive]="activeArray[1]"
            (click)="handleMenuItemClick(1)"></app-menu-point>

          <app-menu-point
            text="Способ передачи заявления и документов"
            [shouldNavigate]="false"
            [isActive]="activeArray[2]"
            (click)="handleMenuItemClick(2)"></app-menu-point>

          <app-menu-point
            text="Уровень предоставления"
            [shouldNavigate]="false"
            [isActive]="activeArray[3]"
            (click)="handleMenuItemClick(3)"></app-menu-point>

          <app-menu-point
            text="Категория заявителей"
            [shouldNavigate]="false"
            [isActive]="activeArray[4]"
            (click)="handleMenuItemClick(4)"></app-menu-point>

          <app-menu-point
            text="Гражданство заявителя"
            [shouldNavigate]="false"
            [isActive]="activeArray[5]"
            (click)="handleMenuItemClick(5)"></app-menu-point>

          <app-menu-point
            text="Требование проживания"
            [shouldNavigate]="false"
            [isActive]="activeArray[6]"
            (click)="handleMenuItemClick(6)"></app-menu-point>

          <app-menu-point
            text="Результат приема заявления"
            [shouldNavigate]="false"
            [isActive]="activeArray[7]"
            (click)="handleMenuItemClick(7)"></app-menu-point>

          <app-menu-point
            text="Результат предоставления услуги"
            [shouldNavigate]="false"
            [isActive]="activeArray[8]"
            (click)="handleMenuItemClick(8)"></app-menu-point>

          <app-menu-point
            text="Уровень предоставления"
            [shouldNavigate]="false"
            [isActive]="activeArray[9]"
            (click)="handleMenuItemClick(9)"></app-menu-point>

          <app-menu-point
            text="Поступление результата в МФЦ"
            [shouldNavigate]="false"
            [isActive]="activeArray[10]"
            (click)="handleMenuItemClick(10)"></app-menu-point>

          <app-menu-point
            text="Дополнительные сведения"
            [shouldNavigate]="false"
            [isActive]="activeArray[11]"
            (click)="handleMenuItemClick(11)"></app-menu-point>
        </nav>
        <div class="texts-container texts-container_wysiwyg">
          <app-wysiwyg
            heading="Общее описание"
            [control]="control('information')"
            style="{{ getWysiwygDisplay(0) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Порядок действий"
            [control]="control('informationProcedure')"
            style="{{ getWysiwygDisplay(1) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Способ передачи заявления и документов"
            [control]="control('informationTransferMethod')"
            style="{{ getWysiwygDisplay(2) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Уровень предоставления"
            [control]="control('informationProvisionMethod')"
            style="{{ getWysiwygDisplay(3) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Категория заявителей"
            [control]="control('informationApplicantCategory')"
            style="{{ getWysiwygDisplay(4) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Гражданство заявителя"
            [control]="control('informationApplicantNationality')"
            style="{{ getWysiwygDisplay(5) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Требование проживания"
            [control]="control('informationResidenceRequirement')"
            style="{{ getWysiwygDisplay(6) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат приема заявления"
            [control]="control('informationApplicationResult')"
            style="{{ getWysiwygDisplay(7) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат предоставления услуги"
            [control]="control('informationServiceResult')"
            style="{{ getWysiwygDisplay(8) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Поступление результата в МФЦ"
            [control]="control('informationReceiptOfTheResult')"
            style="{{ getWysiwygDisplay(10) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Дополнительные сведения"
            [control]="control('informationAdditional')"
            style="{{ getWysiwygDisplay(11) }}"></app-wysiwyg>
        </div>
      </div>
    }
  </div>
</app-modal>
