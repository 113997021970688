import { inject } from '@angular/core';
import { UserService } from '@core/services';
import { PermissionsEnum } from '@core/utils';

export const getFirstPermissionRouteHelper = async (
  redirectLink?: string
): Promise<string> => {
  const userService = inject(UserService);
  const permissions = await userService.getPermissions();
  if (permissions.length === 0) {
    userService.logout();
    return 'login';
  } else {
    const firstPermission = permissions[0];
    switch (firstPermission) {
      case PermissionsEnum.SERVICES:
        return redirectLink ?? 'services';
      case PermissionsEnum.CLASSIFIERS:
        return redirectLink ?? 'classifiers';
      case PermissionsEnum.DOCUMENTS:
        return redirectLink ?? 'reg-docs';
      case PermissionsEnum.ROLES:
        return redirectLink ?? 'roles';
      case PermissionsEnum.DOCUMENTS_TEMPLATES:
        return redirectLink ?? 'docs-sample';
      case PermissionsEnum.TEMPLATES:
        return redirectLink ?? 'templates';
      case PermissionsEnum.USERS:
        return redirectLink ?? 'users';
    }
  }
};
