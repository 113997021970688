import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

@Component({
  selector: 'core-loader',
  standalone: true,
  imports: [],
  providers: [LoaderService],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoaderComponent {
  public isShowing: boolean = true;
  title: string = 'Идет загрузка...';
  
  public open() {
    this.isShowing = true;
  }

  public close() {
    this.isShowing = false;
  }
}
