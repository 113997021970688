import { Component } from '@angular/core';
import { AlertService } from '@core/services';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [],
  providers: [AlertService],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  public isShowing: boolean = true;
  title: string = '';
  data: string = '';

  constructor(private readonly alertService: AlertService) {
    this.alertService.currentTitle.subscribe((title) => this.setTitle(title));
    this.alertService.currentData.subscribe((data) => this.setData(data));
  }

  private setTitle(title: string): void {
    this.title = title;
    const titleElement = document.getElementById('alert-title');
    if (titleElement)
      titleElement.innerHTML = title;
  }

  private setData(data: string): void {
    this.data = data;
    const dataElement = document.getElementById('alert-data');
    if (dataElement)
      dataElement.innerHTML = data;
  }

  public open() {
    this.isShowing = true;
  }

  public close() {
    this.isShowing = false;
  }
}
