import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { IconButtonComponent } from '@core/components';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { InputComponent } from '@core/components/inputs/input.component';

@Component({
  selector: 'core-password-input',
  standalone: true,
  imports: [IconButtonComponent, ReactiveFormsModule, NgClass, FormsModule],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordInputComponent,
    },
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent extends InputComponent<string> {
  placeholder = input<string>();
  tooltip = input<string>();
  height = input<number>(48);
  public visible = false;

  clear() {
    this.onInput(this.defaultValue());
  }

  override defaultValue(): string {
    return '';
  }
}
