import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import { SearchComponent } from '@core/components';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [NgClass, SearchComponent, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  type = input.required<'normal' | 'mail' | 'password' | 'checkbox'>();
  inputType = input<'text' | 'tel' | 'password' | 'email'>('text');
  placeholder = input<string>();
  labelVersion = input<'regular' | 'medium' | 'bold'>('bold');
  @Input() control: FormControl = new FormControl();
  @Input() classes: string = '';
  @Input() formControlName!: string;
  label = input<string>();
  @Output() changeEvent = new EventEmitter();
  public isFocused: boolean = false;
  public isError: boolean = false;

  onChange = (event: any) => {};
  onTouch = (event: any) => {};

  registerOnChange(fn: () => any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => any): void {
    this.onTouch = fn;
  }

  writeValue(input: string) {}
}
