<div class="users-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">
      Пользователи
      <span class="top-div__badge">
        <app-badge
          size="normal"
          display="tint"
          color="secondary-container"
          size_text="default"
          badge_content="{{ users.length }}"></app-badge>
      </span>
    </h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="onAddClick()"></app-button>
    </div>
  </div>
  <div class="search-zone area_white_bg">
    <div class="search-zone__input">
      <core-text-input
        placeholder="Поиск пользователя"
        leftIcon="search"
        [formControl]="name"></core-text-input>
    </div>
  </div>
  <div class="tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!--    <button class="filter">-->
    <!--      В алфавитном порядке-->
    <!--      <img src="../../assets/icons/keyboard_arrow_down.svg" alt="↓">-->
    <!--    </button>-->
  </div>
</div>

<div class="users">
  @switch (currentTab) {
    @case ('tabs__available') {
      @if (users.length === 0) {
        <p class="text-bigger">Нет пользователей</p>
      }
      @for (cont of users; track cont.id) {
        <article class="user">
          <div class="user__row">
            <h3 class="user__text text-big">
              {{ cont.firstName + ' ' + cont.middleName + ' ' + cont.lastName }}
            </h3>
            <div class="user__buttons">
              <app-icon-button
              size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="handleClickDeleteUser(cont.id, cont.firstName + ' ' + cont.lastName)"
              ></app-icon-button>
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="handleClickUpdateUser(cont)"
              ></app-icon-button>
            </div>
          </div>
          <div class="user__row">
            <div class="user__badges">
              @if (isNew(cont.createdAt)) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="secondary-container"
                  size_text="default"
                  badge_content="Новый"></app-badge>
              }
              @for (badge of cont.usersRoles; track badge; let i = $index) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="tertiary-container"
                  size_text="default"
                  badge_content="{{ badge.role.name }}"></app-badge>
              }
            </div>
          </div>
        </article>
      }
    }
    @case ('tabs__blocked') {
      @if (blockedUsers.length === 0) {
        <p class="text-bigger">Нет пользователей с приостановленным доступом</p>
      }
      @for (cont of blockedUsers; track cont.id) {
        <article class="user">
          <div class="user__row">
            <h3 class="user__text text-big">
              {{ cont.firstName + ' ' + cont.middleName + ' ' + cont.lastName }}
            </h3>
            <div class="user__buttons">
              <app-icon-button
                size="small"
                type="circle"
                icon="cancel"
                display="outline"
                color="outline-low"
                (click)="
                  handleClickDeleteUser(
                    cont.id,
                    cont.firstName + ' ' + cont.lastName
                  )
                "></app-icon-button>
              <app-icon-button
                size="small"
                type="circle"
                icon="edit_square"
                display="outline"
                color="outline-low"
                (click)="handleClickUpdateUser(cont)"></app-icon-button>
            </div>
          </div>
          <div class="user__row">
            <div class="user__badges">
              @if (isNew(cont.createdAt)) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="secondary-container"
                  size_text="default"
                  badge_content="Новый"></app-badge>
              }
              @for (badge of cont.usersRoles; track badge; let i = $index) {
                <app-badge
                  size="normal"
                  display="tint"
                  color="tertiary-container"
                  size_text="default"
                  badge_content="{{ badge.role.name }}"></app-badge>
              }
            </div>
          </div>
        </article>
      }
    }
  }
</div>

<app-modal
  class="user-modal"
  id="add-user-modal"
  submitButtonText="{{ isCreate ? 'Добавить' : 'Изменить' }}"
  heading="{{
    isCreate ? 'Новый пользователь' : 'Изменить данные пользователя'
  }}"
  width="mini"
  [requestConfirmToCancel]="createUserForm.dirty"
  (submitAction)="submitEvent()">
  <form
    [formGroup]="createUserForm"
    class="area_white_bg"
    id="modal-add-user-form"
    (keydown.enter)="handlePressEnter($event)">
    <div class="add-user-role-select">
      <app-select
        type="picked-list"
        [formControl]="role"
        [options]="rolesList"
        placeholder="Выберите роли"></app-select>
      @for (role of selectedRoles; track role.id) {
        <div class="add-user-role-select__picked">
          {{ role.name }}
          <div
            class="add-user-role-select__cancel"
            (click)="handleClickDeleteSelectedRole(role.id)"></div>
        </div>
      }
    </div>
    <div class="add-user-input">
      <p class="text-big">Фамилия</p>
      <core-text-input
        placeholder="Введите фамилию"
        [formControlName]="'lastName'"
        (click)="resetErrors()"
        [error]="lastNameError"></core-text-input>
    </div>
    <div class="add-user-input">
      <p class="text-big">Имя</p>
      <core-text-input
        placeholder="Введите имя"
        [formControlName]="'firstName'"
        (click)="resetErrors()"
        [error]="firstNameError"></core-text-input>
    </div>
    <div class="add-user-input">
      <p class="text-big">Отчество</p>
      <core-text-input
        placeholder="Введите отчество"
        [formControlName]="'middleName'"
        (click)="resetErrors()"
        [error]="middleNameError"></core-text-input>
    </div>
    @if (isCreate) {
      <div class="add-user-input">
        <p class="text-big">Email</p>
        <core-email-input
          placeholder="Введите email"
          [formControlName]="'login'"
          (click)="resetErrors()"
          [error]="loginError"></core-email-input>
      </div>
    } @else {
      <div class="add-user-input">
        <app-input
          type="checkbox"
          label="Заблокировать пользователя"
          [control]="isBlocked"
          (click)="resetErrors()"></app-input>
        <p class="input-error">{{ blockedError }}</p>
      </div>
    }
  </form>
</app-modal>
