@if (type !== 'date'){<hr class="search__focused-line"
    [ngClass]="{'search__focused-line_active':isFocused, 'search__focused-line_active_no-search': !searchIcon}"
    [ngStyle]="{'top.px': height + 22}">}
@if (!multiline) {
<input type="{{type}}" class="search text-big on-container {{width}} borders-{{borderRadius}}" placeholder="{{placeholder}}" (focus)="isFocused = true"
    (blur)="isFocused = false" (keyup)="onInput($event)"
    [ngClass]="{'search_white-bg search_no-search-icon': whiteBg, 'search_date search_no-search-icon': type === 'date'}"
    [ngStyle]="{'padding-right': type === 'date'? '12px':''}" (click)="handleClick(type, $event)" [formControl]="control"
    (input)="changeEvent.emit()" />
}
@if (multiline) {
<textarea type="text" class="search text-big on-container" placeholder="{{placeholder}}" (focus)="isFocused = true"
    (blur)="isFocused = false" (keyup)="onInput($event)"
    [ngClass]="{'search_white-bg': whiteBg, 'search_no-search-icon': !searchIcon}" [ngStyle]="{'height': height + 'px'}" [formControl]="control"
    ></textarea>
}
@if (type !== 'date') {
    <div class="search__icons" [ngClass]="searchIcon? '':'search__icons_no-search'"
        [ngStyle]="{'height': height + 'px', 'margin-top': '-'+height * 1.5+'px'}">
        <button class="search__magnifier-icon" [ngClass]="searchIcon? '':'hidden-magnifier'"></button>
        @if (isShowClear && control.dirty){<button class="search__cross-icon" (click)="cleanInput()"></button>}
    </div>
}
