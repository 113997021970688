@if (showAddBar) {
  <div class="add-bar">
    <ul>
      <li (click)="onClick(ServiceBlockTypes.Intermediate)">
      <div>
        <span class="material-symbols-outlined primary-action">
          page_info
        </span>
        <span class="primary-action">Промежуточный шаг</span>
      </div>
      <span class="material-symbols-outlined primary-action">
        keyboard_arrow_right
      </span>
      </li>
      @if (isFull) {
        <li (click)="onClick(ServiceBlockTypes.Result)">
          <div>
            <span class="material-symbols-outlined">
              check_circle
            </span>
            Успешное окончание
          </div>
          <span class="material-symbols-outlined">
            keyboard_arrow_right
          </span>
        </li>
        <li (click)="onClick(ServiceBlockTypes.Closed)">
          <div>
            <span class="material-symbols-outlined">
              warning
            </span>
            Отказ
          </div>
          <span class="material-symbols-outlined">
            keyboard_arrow_right
          </span>
        </li>
      }
      @if (type === 'service') {
        <li (click)="onClick(ServiceBlockTypes.Template)">
          <div>
            <span class="material-symbols-outlined">
              view_comfy_alt
            </span>
            Привязать шаблон
          </div>
          <span class="material-symbols-outlined">
            keyboard_arrow_right
          </span>
        </li>
      }
      @if (canAddChild) {
        <li (click)="onAddChildClick()">
          <div>
            <span class="material-symbols-outlined">
              link
            </span>
            Привязать блок
          </div>
          <span class="material-symbols-outlined">
            keyboard_arrow_right
          </span>
        </li>
      }
      <li (click)="onCloseClick()">
        <div>
          <span class="material-symbols-outlined">
            arrow_left_alt
          </span>
          Отменить
        </div>
        <span class="material-symbols-outlined">
          keyboard_arrow_right
        </span>
      </li>
    </ul>
  </div>
  <div class="layer" (click)="onCloseClick()"></div>
}
