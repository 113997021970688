<div [ngClass]="{
    hide: !isAuthenticated,
    show: isAuthenticated,
  }">
    @if (isAdmin()) {
      <app-left-menu></app-left-menu>
      <app-top-menu></app-top-menu>
    } @else {
      <app-client-menu></app-client-menu>
    }
  </div>
  