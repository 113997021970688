import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { BadgeComponent, IconButtonComponent } from '@core/components';
import { Service } from '@core/models';
import { Router } from '@angular/router';
import { getServiceTypeTranslation, parsePeriodHelper } from '@core/utils';

@Component({
  selector: 'app-services-page-list',
  standalone: true,
  imports: [BadgeComponent, IconButtonComponent],
  templateUrl: './services-page-list.component.html',
  styleUrl: './services-page-list.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesPageListComponent {
  services = input.required<Service[]>();
  constructor(private router: Router) {}
  @Output('edit') editEvent = new EventEmitter<number>();

  parseActivity = parsePeriodHelper;

  handleServiceEditClick(id: number) {
    this.editEvent.emit(id);
  }

  handleServiceVisibilityClick(id: number, type: string) {
    this.router.navigate(['/services/' + id], { queryParams: { type: type } });
  }

  protected readonly Array = Array;
  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;
}
