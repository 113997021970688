import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  ModalComponent,
  SelectComponent,
  TextInputComponent,
} from '@core/components';
import { AlertService, ModalService } from '@core/services';
import { DocumentTransferMethod } from '@core/models';
import { DocumentsTransferMethodsService } from '@core/services';

interface CreateTransferMethodForm {
  name: FormControl<string>;
}

interface UpdateTransferMethodForm {
  name: FormControl<string>;
}

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-docs-transfer-method-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    TextInputComponent,
    ModalComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    IconButtonComponent,
  ],
  templateUrl: './docs-transfer-method-page.component.html',
  styleUrl: './docs-transfer-method-page.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AlertService, AlertComponent],
})
export class DocsTransferMethodPageComponent {
  public createForm: FormGroup<CreateTransferMethodForm> =
    new FormGroup<CreateTransferMethodForm>({
      name: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  public editForm: FormGroup<UpdateTransferMethodForm> =
    new FormGroup<UpdateTransferMethodForm>({
      name: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  public nameError: string = '';
  private editableElementId!: number;
  public isSearchBlocked: boolean = false;
  public isCreated: boolean = true;
  public searchForm: FormGroup<SearchForm>;
  public documentsTransferMethods: DocumentTransferMethod[] = [];

  constructor(
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly documentsTransferMethodsService: DocumentsTransferMethodsService
  ) {
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ documentsTransferMethods }) => {
      this.documentsTransferMethods = documentsTransferMethods;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  public get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  public onAddClick(): void {
    this.modalService.open('add-transfer-method-modal');
  }

  public resetErrors(): void {
    this.nameError = '';
  }

  public submitEvent(): void {
    if ((this.createForm.value.name?.length as number) < 4) {
      this.nameError =
        'Название способа передачи не может быть короче 4 символов';
      return;
    }

    if (this.isCreated) {
      this.documentsTransferMethodsService
        .createDocumentTransferMethod({
          name: this.createForm.get('name')?.value as string,
        })
        .subscribe({
          next: (documentTransferMethod: DocumentTransferMethod) => {
            this.documentsTransferMethods.push(documentTransferMethod);
            this.modalService.close();
            this.resetErrors();
            this.createForm.reset();
            this.alertService.toggle('Способ передачи добавлен');
          },
          error: (e: Error) => console.error(e),
        });
    } else {
      this.documentsTransferMethodsService
        .updateDocumentTransferMethod(this.editableElementId, {
          name: this.createForm.get('name')?.value as string,
        })
        .subscribe({
          next: method => {
            const index = this.documentsTransferMethods.findIndex(
              s => s.id === method.id
            );
            if (index >= 0) {
              this.documentsTransferMethods[index] = method;
            }
            this.modalService.close();
            this.alertService.toggle('Способ передачи измененен');
            this.editForm.reset();
            this.resetErrors();
          },
          error: (error: Error) => {
            console.log(error);
          },
        });
    }
  }

  handleSearch(query: string) {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.documentsTransferMethodsService
        .getDocumentTransferMethods({
          name: query,
        })
        .subscribe({
          next: (docTransferMethodList: DocumentTransferMethod[]) => {
            this.documentsTransferMethods = docTransferMethodList;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  public handleClickUpdateTransferMethod(id: number): void {
    this.resetErrors();
    this.editableElementId = id;
    this.isCreated = false;
    this.createForm.setValue({
      name: this.documentsTransferMethods.find(
        elem => elem.id === this.editableElementId
      )!.name as string,
    });
    this.modalService.open('add-transfer-method-modal');
  }
  public handleClickDeleteTransferMethod(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить способ передачи документов "${name}"?`
    );

    if (isAccepted) {
      this.documentsTransferMethodsService
        .deleteDocumentTransferMethod(id)
        .subscribe({
          next: () => {
            this.documentsTransferMethods =
              this.documentsTransferMethods.filter(method => method.id !== id);
            this.alertService.toggle('Метод удален');
          },
          error: err => console.error(err),
        });
    } else return;
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.submitEvent();
  }
}
