import { NgStyle } from '@angular/common';
import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { EventType, Router } from '@angular/router';

import { ModalService } from '@core/services';

@Component({
  selector: 'app-modal',
  imports: [NgStyle],
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id?: string;
  @Input() heading!: string;
  @Input() cancelButtonText: string = 'Отменить';
  @Input() submitButtonText: string = 'Сохранить';
  @Input() placeholder!: string;
  @Input() width: 'mini' | 'medium' | 'large' = 'medium';
  @Input() bodyPadding?: string = '';
  @Input() isButtons = true; 
  @Input() requestConfirmToCancel = false;

  @Output() submitAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();
  isOpen = false;
  private readonly element: any;

  constructor(
    private readonly modalService: ModalService,
    private readonly router: Router,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
    this.router.events.subscribe(event => {
      // if (event.type === EventType.NavigationStart) {
      //   this.modalService.reset();
      // }
    });
  }

  ngOnInit() {
    this.modalService.add(this);
    document.body.appendChild(this.element);

    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'app-modal') {
        this.cancel();
      }
    });
  }

  ngOnDestroy() {
    this.modalService.remove(this);
    this.element.remove();
  }

  open() {
    this.element.style.display = 'block';
    document.body.classList.add('app-modal-open');
    document.body.style.overflowY = 'hidden';
    this.isOpen = true;
  }

  close() {
    this.element.style.display = 'none';
    document.body.style.overflowY = 'auto';
    document.body.classList.remove('app-modal-open');
    this.isOpen = false;
  }

  submit() {
    this.submitAction.emit();
  }

  cancel() {
    if(this.requestConfirmToCancel && !confirm(`Вы действительно хотите удалить закрыть модальное окно?`)){
      return;
    }
    
    this.cancelAction.emit();
    this.close();
    
  }
}
