import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AsyncPipe, NgClass, UpperCasePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
  ModalComponent,
  SearchComponent,
} from '@core/components';
import { AlertService, ModalService, DocsSampleService } from '@core/services';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import {
  Attachment,
  DocumentsTemplatesList,
  DocumentTemplate,
} from '@core/models';
import { CreateDocSampleDto } from '@core/models/dto';
import {
  convertFileSizeHelper,
  getFileExtensionHelper,
  openFileInNewTabHelper,
  ParseDataToFormHelper,
} from '@core/utils';

interface SampleForm {
  title: FormControl<string>;
}

interface SearchForm {
  name: FormControl<string>;
}

type Tab = {
  title: string;
  class: string;
};

export type DocsSample = {
  id: number;
  title: string;
};

@Component({
  selector: 'app-docs-sample-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    SearchComponent,
    NgClass,
    IconButtonComponent,
    ModalComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    AsyncPipe,
    UpperCasePipe,
    TextInputComponent,
  ],
  templateUrl: './docs-sample-page.component.html',
  styleUrl: './docs-sample-page.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AlertService, AlertComponent],
})
export class DocsSamplePageComponent implements OnInit, AfterViewInit {
  searchForm: FormGroup<SearchForm>;
  isSearchBlocked: boolean = false;
  sampleForm: FormGroup;
  editForm: FormGroup;
  files: File[] = [];
  fileUploadError = '';
  public error: string = '';
  public editingElement!: DocumentTemplate;
  public editingElementAttachments: Attachment[] = [];
  public attachmentsIdsToRemove: number[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private docSampleService: DocsSampleService,
    private readonly alertService: AlertService
  ) {
    this.sampleForm = new FormGroup<SampleForm>({
      title: new FormControl('', {
        validators: [Validators.required, Validators.min(1)],
        nonNullable: true,
      }),
    });
    this.editForm = new FormGroup<SampleForm>({
      title: new FormControl('', {
        validators: [Validators.required, Validators.min(1)],
        nonNullable: true,
      }),
    });
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ docsSamples }) => {
      this.docsSamples = docsSamples.active.data;
      this.archived = docsSamples.archive.data;
    });
    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  get title(): FormControl<string> {
    return this.sampleForm.get('title') as FormControl<string>;
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
  ];

  public currentTab: string = this.tabs[0].class;

  public docsSamples: DocumentTemplate[] = [];
  public archived: DocumentTemplate[] = [];

  resetErrors(): void {
    this.error = '';
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files: File[] = Array.from(event.dataTransfer.files);

      for (const file of files) {
        if (!this.files.some((e: File) => e.name === file.name)) {
          this.files.push(file);
        }
      }
    }
  }

  onAddClick(): void {
    this.modalService.open('add-sample-modal');
  }

  onFileSelected(event: any): void {
    this.files = [...event.target.files];
    this.fileUploadError = '';
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  handleTabClick(tabName: string): void {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  ngAfterViewInit(): void {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  protected submitEvent() {
    if(this.files.length == 0){
      this.fileUploadError = 'Список вложений не может быть пустым';
      return;
    }

    const requestData = {
      title: this.sampleForm.value.title as string,
    };

    if (this.sampleForm.value.title.trim().length > 0) {
      const form = ParseDataToFormHelper<CreateDocSampleDto>(
        requestData,
        this.files
      );
      this.docSampleService.createDocSample(form).subscribe({
        next: (document: DocumentTemplate) => {
          this.docsSamples.unshift(document);
          this.modalService.close();
          this.sampleForm.reset();
          this.files = [];
          this.alertService.toggle('Образец документа добавлен');
          this.resetErrors();
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
    } else {
      this.error = 'Заголовок не должен быть пустым';
    }
  }

  openEditModal(id: number): void {
    this.resetErrors();
    this.files = [];
    this.attachmentsIdsToRemove = [];
    this.editingElement = this.docsSamples.find(
      doc => doc.id === id
    ) as DocumentTemplate;
    this.editForm.setValue({
      title: this.docsSamples.find(elem => elem.id === this.editingElement.id)!
        .title as string,
    });
    this.editingElementAttachments =
      this.editingElement.documentsTemplatesAttachments;
    this.modalService.open('edit-modal');
  }

  editDocSample(): void {
    const updateData = {
      title: this.editForm.value.title,
      attachmentsIdsToRemove: this.attachmentsIdsToRemove,
    };
    const form = ParseDataToFormHelper<CreateDocSampleDto>(
      updateData,
      this.files
    );
    if (updateData.title.trim().length > 0) {
      this.docSampleService
        .updateDocSample(this.editingElement.id, form)
        .subscribe({
          next: docSample => {
            const updatedDocSample = this.docsSamples.find(
              a => a.id === this.editingElement.id
            );
            updatedDocSample!.title = docSample.title;
            updatedDocSample!.documentsTemplatesAttachments =
              docSample.documentsTemplatesAttachments;
            this.modalService.close();
            this.editForm.reset();
            this.resetErrors();
          },
        });
    } else {
      this.error = 'Название образца не должно быть пустым';
    }
  }

  deleteDocSample(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить образец "${name}"?`
    );

    if (isAccepted) {
      this.docSampleService.deleteDocSample(id).subscribe({
        next: () => {
          this.docSampleService.getDocSamples().subscribe({
            next: docs => {
              this.docsSamples = docs.active.data;
              this.archived = docs.archive.data;
            },
          });
          this.alertService.toggle('Образец документа удален');
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
    } else return;
  }

  handleSearch(query: string): void {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.docSampleService
        .getDocSamples({
          name: query,
        })
        .subscribe({
          next: (docSampleList: DocumentsTemplatesList) => {
            this.docsSamples = docSampleList.active.data;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  deleteAttachment(event: any, id: number): void {
    event.stopPropagation();
    event.preventDefault();
    this.attachmentsIdsToRemove.push(id);
    this.editingElementAttachments = this.editingElementAttachments.filter(
      e => e.id !== id
    );
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.submitEvent();
  }

  protected readonly getFileExtensionHelper = getFileExtensionHelper;
  protected readonly convertFileSizeHelper = convertFileSizeHelper;
  protected readonly openFileInNewTabHelper = openFileInNewTabHelper;
}
