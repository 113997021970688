<div class="app-modal">
  <div class="app-modal-container {{width}} {{isButtons ? '' : 'min-height-100'}}">
    <div class='app-modal-container__header'>
      <h4 class="app-modal-container__title title-bold-h4">{{heading}}</h4>
      <button class="app-modal-container__close" (click)="cancel()"></button>
    </div>
    <div class="app-modal-container__body" [ngStyle]="{'padding': bodyPadding}">
      @if (placeholder) {
        <p class="modal-placeholder">{{placeholder}}</p>
      }
      <ng-content></ng-content>
    </div>
    @if (isButtons) {
      <div class="app-modal-container__footer">
        <button class="app-modal-container__cancel modal-button subtitle-default" (click)="cancel()">
          {{cancelButtonText}}
        </button>
        <button class="app-modal-container__submit modal-button subtitle-default" (click)="submit()">
          {{submitButtonText}}
        </button>
      </div>
    }
  </div>
</div>
