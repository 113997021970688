import { HttpInterceptorFn } from '@angular/common/http';
import { finalize } from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
    let timeoutID = setTimeout(() => {
        document.getElementById('loader')?.classList.add('loader_active')
    }, 400)

    return next(req).pipe(finalize(() => {
        document.getElementById('loader')?.classList.remove('loader_active')
        clearInterval(timeoutID);
    }));
};

