@if (isShowing) {
  <div id="alert">
    <div class="alert">
      <img src="assets/icons/accept-filled-icon.svg" alt="OK">
      <div class="info">
        <div class="info__header" id="alert-title">{{ title }}</div>
        <div class="info__data" id="alert-data">{{ data }}</div>
      </div>
      <div class="loader"></div>
    </div>
  </div>
}
