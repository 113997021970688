import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ButtonComponent } from '@core/components';
import { RouterLink } from '@angular/router';
import { ButtonType } from '@core/types';

@Component({
  selector: 'core-section-header',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
  ],
  templateUrl: './section-header.component.html',
  styleUrl: './section-header.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent {
  title = input.required<string>();
  buttons = input.required<ButtonType[]>();
}
