<div class="container-login primary-container">
  <div class="container-login__block container-lowest">
    <a class="container-login__back-arrow" [routerLink]="'/login'"></a>

    <h1>Восстановление доступа</h1>
    <p class="container-login__sub-title">Вспомнили пароль? <a [routerLink]="'/login'" class="container-login__forgot_password">Войти</a></p>

    @if (showSuccess) {
      <div class="container-login__success-recover">
        На почту {{hideLogin()}} отправлена ссылка для создания нового пароля.
      </div>

      <app-button class="container-login__button"
                  size="normal"
                  display="inverse"
                  type="no"
                  icon="arrow_right_alt"
                  color="inverse-container"
                  sizeText="big"
                  [usedBadge]="false"
                  buttonContent="Понятно"
                  typing="submit"
                  [routerLink]="'/login'"
      />
    } @else {
      <form
        class="container-login__form"
        [formGroup]="recoverForm"
        (ngSubmit)="onFormSubmit()"
      >
        <fieldset class="container-login__fieldset" [disabled]="isSubmitted">
          <input type="email"
                 class="login-input text-big"
                 placeholder="Email"
                 [formControl]="login"
                 (click)="resetError()"
          />

          <p class="container-login__error">{{error}}</p>

          <app-button class="container-login__button"
                      size="normal"
                      display="inverse"
                      type="right"
                      icon="arrow_right_alt"
                      color="inverse-container"
                      sizeText="big"
                      [usedBadge]="false"
                      buttonContent="Восстановить доступ"
                      typing="submit"
          />
        </fieldset>
      </form>
    }
  </div>
<!--  <p class="container-login__text text-default on-container-lowest">-->
<!--    There is no reality except the one contained within us.<br/>-->
<!--    That is why so many people live such an unreal life.<br/>-->
<!--    2024.-->
<!--  </p>-->
  <div class="container-login__help">
    <app-icon-button class="container-login__help-button"
                     size="normal"
                     display="cell"
                     type="circle"
                     icon="help"
                     color="cell">
    </app-icon-button>
  </div>
</div>
