<div class="badges">
  @if (service() && isNew(service().createdAt)) {
    <app-badge
      size="normal"
      display="tint"
      color="secondary-container"
      size_text="small"
      badge_content="Новая услуга"></app-badge>
  }
  @if (service() && service().isNotForTosp) {
    <app-badge
      size="normal"
      display="tint"
      color="secondary-container"
      size_text="small"
      badge_content="Не для ТОСП"></app-badge>
  }
  @if (service() && service().isNotForUrm) {
    <app-badge
      size="normal"
      display="tint"
      color="tertiary-container"
      size_text="small"
      badge_content="Не для УРМ"></app-badge>
  }
</div>
<div class="main-info">
  @if (service() && service().parent) {
    <p class="text-bigger on-container-low">
      Это дочерняя услуга услуги #{{ service().parentId }}
      <a routerLink="/services/{{ service().parentId }}"> (перейти) </a>
    </p>
  }
  <p class="text-bigger on-container-low">Полное наименование услуги</p>
  <p class="main-info__title title-bold-h5">{{ service().name }}</p>

  <p class="text-bigger on-container-low">Краткое наименование</p>
  <p class="on-container text-bigger">{{ service().title }}</p>
  <p class="text-bigger on-container-low">Номер СИЭР</p>
  <p class="on-container text-bigger" style="margin-bottom: 30px">
    {{ service().sierNumber }}
  </p>
  <p class="text-bigger on-container-low">Способ предоставления</p>
  <p class="on-container text-bigger" style="margin-bottom: 30px">
    {{ getServiceTypeTranslation(service().type) }}
  </p>
  @if (dateStart && dateEnd) {
    <div class="main-info__period">
      <p class="text-bigger on-container-low">
        Дата начала активности <br />
        <span class="main-info__span">{{ activityStart }}</span>
      </p>
      <p class="text-bigger on-container-low">
        Дата окончания активности<br />
        <span class="main-info__span">{{ activityEnd }}</span>
      </p>
    </div>
  }

  <div class="documents">
    <p class="documents__title text-bigger on-container-low">
      Привязка к регламентирующим документам
    </p>
    <div class="documents__container">
      @for (doc of service().documents; track doc) {
        <app-card
          name="{{ doc.title }}"
          period="{{
            parsePeriodHelper(
              getDocumentActualVersionHelper(doc)?.activityStart,
              getDocumentActualVersionHelper(doc)?.activityEnd
            )
          }}"
          [relations]="
            getDocumentActualVersionHelper(doc)?.relatedDocuments
              ? getDocumentActualVersionHelper(doc)?.relatedDocuments
              : []
          "
          [attachments]="
            getDocumentActualVersionHelper(doc)?.attachments
              ? getDocumentActualVersionHelper(doc)?.attachments
              : []
          "
          [isWarning]="false"
          (showDetection)="handleShowRegDocClick(doc)"
          (deleteDetection)="deleteRegDoc(service().id, doc.id)"
          (editDetection)="editRegDoc(doc.id)"></app-card>
      }
    </div>
  </div>

  <p class="text-bigger on-container-low">Жизненные ситуации</p>
  <div class="cards-list">
    @if (!service() || service().lifeSituations.length === 0) {
      Нет данных
    } @else {
      @for (card of service().lifeSituations; track card) {
        <article class="cards-list__card subtitle-big">
          {{ card.name }}
        </article>
      }
    }
  </div>

  <p class="text-bigger on-container-low">Категория заявителей</p>
  <div class="cards-list">
    @if (!service() || service().applicantCategories.length === 0) {
      Нет данных
    } @else {
      @for (card of service().applicantCategories; track card) {
        <article class="cards-list__card subtitle-big">
          {{ card.name }}
        </article>
      }
    }
  </div>

  <p class="text-bigger on-container-low">Ведомства</p>
  <div class="cards-list">
    @if (!service() || service().departments.length === 0) {
      Нет данных
    } @else {
      @for (card of service().departments; track card) {
        <article class="cards-list__card subtitle-big">
          {{ card.name }}
        </article>
      }
    }
  </div>

  <p class="text-bigger on-container-low">Территориальность</p>
  <div class="cards-list">
    @if (!service() || service().territoriality.length === 0) {
      Нет данных
    } @else {
      @for (card of service().territoriality; track card) {
        <article class="cards-list__card subtitle-big">
          {{ card.name }}
        </article>
      }
    }
  </div>
</div>
