import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { IconButtonComponent } from '@core/components';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { InputComponent } from '@core/components/inputs/input.component';

export type LeftIconType =
  | 'search'

@Component({
  selector: 'core-text-input',
  standalone: true,
  imports: [
    IconButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent extends InputComponent<string>  {
  placeholder = input<string>();
  leftIcon = input<LeftIconType>();
  tooltip = input<string>();
  height = input<number>(48);

  clear() {
    this.onInput(this.defaultValue());
  }

  override defaultValue(): string {
    return '';
  }
}
