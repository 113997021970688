<div class="reg-docs-container__badges">
<!--  <app-badge size="normal" display="tint" color="secondary-container" size_text="default" badge_content="Badge"></app-badge>-->
</div>
<div class="main-info">
  <p class="text-bigger on-container-low">Название документа</p>
  <p class="main-info__title title-bold-h5 on-container">{{ regDoc().title }}</p>

  @if (getDocumentActualVersionHelper(regDoc())?.activityStart && getDocumentActualVersionHelper(regDoc())?.activityEnd) {
    <div class="main-info__period">
      <div class="main-info__period-start">
        <p class="date-paragraph text-bigger on-container-low">Дата начала активности</p>
        <span class="on-container">{{ activityStart }}</span>
      </div>
      <div class="main-info__period-end">
        <p class="date-paragraph text-bigger on-container-low">Дата окончания активности</p>
        <span class="on-container">{{ activityEnd }}</span>
      </div>
    </div>
  }

  <div class="main-info__attachments">
    <div class="main-info__attachments-header">
      <p class="main-info__attachments-title text-bigger on-container-low">Вложения</p>
      <div class="main-info__attachments-counter inverse-container on-inverse-container">{{ getDocumentActualVersionHelper(regDoc())?.attachments?.length }}</div>
    </div>
    @if (!getDocumentActualVersionHelper(regDoc())?.attachments?.length) {
      <p class="main-info__title title-bold-h5 on-container">Вложений нет</p>
    } @else {
      <div class="attachments-list container-low">
        @for (attachment of getDocumentActualVersionHelper(regDoc())?.attachments; track attachment.id) {
          <div class="attachments-list__item">
            <p class="attachments-list__item-title subtitle-big on-container">{{ attachment.originalName }}</p>
            <div class="attachments-list__item-info">
              <!--            <div class="item-info__author text-default on-container-low">{{ attachment.author }}</div>-->
              <div class="item-info__details">
                <app-badge size="small" display="tint" color="tertiary-container" size_text="small" badge_content="{{ getFileExtension(attachment) }}"></app-badge>
                <div class="item-info__details-size text-default on-container-low">{{ convertFileSizeHelper(attachment.size) }}</div>
                <a class="item-info__details-link text-default" (click)="handleOpenFileClick(attachment.link)">Открыть</a>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>

  <div class="main-info__relations">
    <div class="main-info__relations-header">
      <p class="main-info__relations-title text-bigger on-container-low">Связи с документами</p>
      <div class="main-info__relations-counter inverse-container on-inverse-container">{{ getDocumentActualVersionHelper(regDoc())?.relatedDocuments?.length }}</div>
    </div>
    <div class="main-info__relations-list">
      @for (relation of getDocumentActualVersionHelper(regDoc())?.relatedDocuments; track relation.id) {
        <app-card
          name="{{ relation.document?.title }}"
          period="{{ getActivityByDocumentVersion(relation) }}"
          [relations]="[1,2,3,4]" [attachments]="[1,2,3,4]"
          [isWarning]="false">
        </app-card>
      }
    </div>
  </div>
</div>
