import { Component, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  AlertComponent,
  BadgeComponent,
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
  ModalComponent,
  SearchComponent,
  SelectComponent,
} from '@core/components';
import { Permission, Role } from '@core/models';
import { CreateRoleDto, UpdateRoleDto } from '@core/models/dto';
import { AlertService, ModalService, RolesService } from '@core/services';
import { AccessEnum, isNew, RolesTypesEnum } from '@core/utils';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';

interface CreateRoleForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-roles-page',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    SearchComponent,
    InputComponent,
    BadgeComponent,
    NgClass,
    FormsModule,
    ModalComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextInputComponent,
  ],
  providers: [AlertService, AlertComponent],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RolesComponent {
  public isCreate: boolean = true;
  public selectedRole!: Role;
  public createRoleForm: FormGroup<CreateRoleForm>;
  public nameError: string = '';
  public permissions: Permission[] = [];
  public selectedPermissions: Permission[] = [];
  public roles: Role[] = [];
  public isNew = isNew;

  constructor(
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly rolesService: RolesService
  ) {
    this.createRoleForm = new FormGroup<CreateRoleForm>({
      name: new FormControl('', {
        validators: Validators.required,
        nonNullable: true,
      }),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ roles, permissions }) => {
      this.roles = roles;
      this.permissions = permissions;
    });
  }

  get name(): FormControl<string> {
    return this.createRoleForm.controls['name'] as FormControl<string>;
  }

  protected submitEvent() {
    if (this.name.value.length < 4) {
      this.nameError =
        'Название роли не должно быть пустым или быть меньше 4 символов';
      return;
    }

    if (this.isCreate) {
      this.rolesService
        .createRole({
          name: this.createRoleForm.value.name,
          permissionsIds: this.selectedPermissions.map(permission => ({
            id: permission.id,
            access: permission.access,
          })),
        } as CreateRoleDto)
        .subscribe({
          next: (role: Role) => {
            this.roles.push(role);
            this.modalService.close();
            this.createRoleForm.reset();
            this.alertService.toggle('Роль добавлена');
          },
          error: (err: Error) => {
            this.nameError =
              'Произошла незвестная ошибка, пожалуйста, попробуйте позже';
          },
        });
    } else {
      this.rolesService
        .updateRole(this.selectedRole.id, {
          ...this.createRoleForm.value,
          permissionsIds: this.selectedPermissions.map(permission => ({
            id: permission.id,
            access: permission.access,
          })),
        } as UpdateRoleDto)
        .subscribe({
          next: (role: Role) => {
            const roleIndex = this.roles.findIndex(r => r.id === role.id);
            if (roleIndex >= 0) {
              this.roles[roleIndex] = role;
            }
            this.selectedPermissions = [];
            this.modalService.close();
            this.createRoleForm.reset();
            this.alertService.toggle('Роль изменена');
          },
          error: (err: Error & { statusCode: number }) => {
            if (err.statusCode === 409) {
              this.nameError = 'Роль с таким именем уже существует';
            } else {
              this.nameError =
                'Произошла незвестная ошибка, пожалуйста, попробуйте позже';
            }
          },
        });
    }
  }

  clearForm(): void {
    this.createRoleForm.reset();
    this.selectedPermissions = [];
  }

  handleClickUpdateRole(role: Role): void {
    this.createRoleForm.setValue({ name: role.name });
    this.resetErrors();
    this.isCreate = false;
    this.selectedRole = role;
    this.selectedPermissions =
      role.rolesPermissions?.map(rP => ({
        ...rP.permission,
        access: rP.access,
      })) ?? [];
    console.log(this.selectedPermissions);
    this.modalService.open('add-role-modal');
  }

  deleteRole(id: number, name: string) {
    const isAccepted = confirm(
      `Вы действительно хотите удалить роль "${name}"?`
    );

    if (isAccepted) {
      this.rolesService.deleteRole(id).subscribe({
        next: role => {
          this.roles = this.roles.filter(r => r.id !== role.id);
          this.alertService.toggle('Роль удалена');
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
    } else return;
  }

  onAddClick() {
    this.createRoleForm.reset();
    this.resetErrors();
    this.isCreate = true;
    this.modalService.open('add-role-modal');
  }

  hasPermission(id: number | undefined, permissionId: number): boolean {
    if (!id) return false;
    const role = this.roles.find(r => r.id === id);
    if (!role) return false;
    const permissions = role.rolesPermissions?.map(p => p.permission);
    if (!permissions || permissions.length === 0) return false;
    return !!permissions.find(permission => permission.id === permissionId);
  }

  hasSelectedPermission(id: number): AccessEnum | null {
    const permission = this.selectedPermissions.find(
      selectedPermission => selectedPermission.id === id
    );

    if (!permission) return null;
    return permission.access;
  }

  onSelectClick(permission: Permission, access?: AccessEnum): void {
    this.selectedPermissions = this.selectedPermissions.filter(
      sP => sP.id !== permission.id
    );
    if (access) {
      this.selectedPermissions.push({
        ...permission,
        access,
      });
    }
  }

  resetErrors() {
    this.nameError = '';
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.submitEvent();
  }

  protected readonly RolesTypesEnum = RolesTypesEnum;
  protected readonly AccessEnum = AccessEnum;
}
