import { Component, ViewEncapsulation, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { BadgeComponent, CardComponent } from '@core/components';
import { Document, Service } from '@core/models';
import {
  getDocumentActualVersionHelper,
  getServiceTypeTranslation,
  isNew,
  parsePeriodHelper,
} from '@core/utils';
import { ServicesService } from '@core/services';

@Component({
  selector: 'app-service-about',
  standalone: true,
  imports: [BadgeComponent, CardComponent, RouterLink],
  templateUrl: './service-about.component.html',
  styleUrl: './service-about.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class ServiceAboutComponent {
  service = input.required<Service>();
  public dateStart?: string;
  public dateEnd?: string;
  public serviceDocumentsIds: number[] = [];

  constructor(
    protected router: Router,
    private readonly servicesService: ServicesService
  ) {}

  get activityStart(): string {
    if (this.dateStart)
      return this.dateStart.substring(0, this.dateStart.indexOf('T'));
    return '';
  }

  get activityEnd(): string {
    if (this.dateEnd)
      return this.dateEnd.substring(0, this.dateEnd.indexOf('T'));
    return '';
  }

  handleShowRegDocClick(doc: Document): void {
    this.router.navigate([`/reg-docs/${doc.id}`]);
  }

  editRegDoc(id: number): void {
    this.router.navigate([`/reg-docs/${id}`], {
      state: { openEditModal: true },
    });
  }

  deleteRegDoc(serviceId: number, documentId: number): void {
    this.serviceDocumentsIds = this.service()
      .documents!.map(doc => doc.id)
      .filter(docId => docId !== documentId);
    const data = { documentsIds: this.serviceDocumentsIds };
    this.servicesService.updateService(serviceId, data).subscribe({
      next: () => {
        this.service().documents = this.service().documents?.filter(
          doc => doc.id !== documentId
        );
      },
    });
  }

  protected readonly isNew = isNew;
  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;
  protected readonly parsePeriodHelper = parsePeriodHelper;
  protected readonly getDocumentActualVersionHelper =
    getDocumentActualVersionHelper;
}
