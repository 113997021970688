import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  size = input.required<'normal' | 'small' | 'smallest'>();
  iconSize = input<'small' | 'normal' | 'large'>('normal');
  type = input.required<'left' | 'right' | 'no'>();
  icon = input<
    | 'folder_open'
    | 'arrow_right_alt'
    | 'arrow_left_alt'
    | 'add_circle'
    | 'page_info'
    | 'check'
    | 'dashboard'
    | 'explore'
    | 'edit_document'
    | 'check_circle'
    | 'delete_forever'
    | 'keyboard_arrow_up'
    | 'keyboard_arrow_down'
    | 'help'
    | 'cancel'
    | 'print'
  >();
  display = input.required<
    'fill' | 'fill-secondary' | 'cell' | 'inverse' | 'outline' | 'overlay'
  >();
  color = input.required<
    | 'primary'
    | 'secondary'
    | 'cell'
    | 'cell-lowest'
    | 'inverse-container'
    | 'outline-low'
    | 'container-lowest'
    | 'neutral-40'
  >();
  sizeText = input.required<
    'bigger' | 'big' | 'default' | 'small' | 'smallest'
  >();
  fill = input<boolean>(false);
  usedBadge = input<boolean>();
  buttonContent = input<string>();
  badgeContent = input<string>();
  typing = input<string>('button');
}
