@if (services().length === 0) {
  <p class="text-bigger">Нет услуг</p>
} @else {
  @for (service of services(); track service.id) {
    <article class="service">
      <div class="service__row">
        <p class="service__text text-big">
          {{ service.title }}
          <span class="service-type">
            ({{ getServiceTypeTranslation(service.type) }})
          </span>
        </p>
        <div class="service__buttons">
          <app-icon-button
            size="small"
            type="circle"
            icon="edit_square"
            display="outline"
            color="outline-low"
            (click)="handleServiceEditClick(service.id)"></app-icon-button>
          <app-icon-button
            size="small"
            type="circle"
            icon="visibility"
            display="outline"
            color="outline-low"
            (click)="
              handleServiceVisibilityClick(service.id, 'actual')
            "></app-icon-button>
        </div>
      </div>
      <div class="service__row">
        <div class="service__badges">
          @for (badge of Array.from(service.territoriality); track badge.id) {
            <app-badge
              size="normal"
              display="tint"
              color="primary-container"
              size_text="default"
              badge_content="{{ badge.name }}"></app-badge>
          }
          @for (badge of Array.from(service.lifeSituations); track badge.id) {
            <app-badge
              size="normal"
              display="tint"
              color="secondary-container"
              size_text="default"
              badge_content="{{ badge.name }}"></app-badge>
          }
          @for (badge of Array.from(service.departments); track badge.id) {
            <app-badge
              size="normal"
              display="tint"
              color="primary"
              size_text="default"
              badge_content="{{ badge.name }}"></app-badge>
          }
          @for (
            badge of Array.from(service.applicantCategories);
            track badge.id
          ) {
            <app-badge
              size="normal"
              display="tint"
              color="secondary"
              size_text="default"
              badge_content="{{ badge.name }}"></app-badge>
          }
        </div>
        <p class="service__period text-default">
          <span class="material-symbols-outlined on-container-lowest">
            calendar_today
          </span>
          {{ parseActivity(service.activityStart, service.activityEnd) }}
        </p>
      </div>
    </article>
  }
}
