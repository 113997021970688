import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
} from '@angular/core';

import { BadgeComponent, CardComponent } from '@core/components';
import { Document, DocumentVersion } from '@core/models';
import {
  convertFileSizeHelper,
  getDocumentActualVersionHelper,
  getFileExtensionHelper,
  openFileInNewTabHelper,
  parsePeriodHelper,
} from '@core/utils';

@Component({
  selector: 'app-actual',
  standalone: true,
  imports: [BadgeComponent, CardComponent],
  templateUrl: './reg-doc-actual.component.html',
  styleUrl: './reg-doc-actual.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegDocActualComponent {
  public regDoc = input.required<Document>();

  constructor() {}

  public get activityStart(): string {
    const activityStart = getDocumentActualVersionHelper(
      this.regDoc()
    )?.activityStart;
    if (activityStart) {
      return new Date(activityStart).toLocaleDateString();
    }
    return '';
  }

  public get activityEnd(): string {
    const activityEnd = getDocumentActualVersionHelper(
      this.regDoc()
    )?.activityEnd;
    if (activityEnd) {
      return new Date(activityEnd).toLocaleDateString();
    }
    return '';
  }

  public getActivityByDocumentVersion(document: DocumentVersion): string {
    return parsePeriodHelper(document.activityStart, document.activityEnd);
  }

  protected readonly getFileExtension = getFileExtensionHelper;
  protected readonly handleOpenFileClick = openFileInNewTabHelper;
  protected readonly convertFileSizeHelper = convertFileSizeHelper;
  protected readonly getDocumentActualVersionHelper =
    getDocumentActualVersionHelper;
}
