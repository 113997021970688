import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateDocSampleDto, SearchDocsSampleDto } from '@core/models/dto';
import { Attachment, DocumentsTemplatesList, DocumentTemplate } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class DocsSampleService {
  constructor(private readonly http: HttpClient) {}

  getDocSamples(
    searchDto?: SearchDocsSampleDto
  ): Observable<DocumentsTemplatesList> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }
    let uri = '/documents/templates';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<DocumentsTemplatesList>(uri);
  }

  getDocSampleById(id: number): Observable<DocumentTemplate> {
    return this.http.get<DocumentTemplate>(`/documents/templates/${id}`);
  }

  createDocSample(form: FormData): Observable<DocumentTemplate> {
    return this.http.post<DocumentTemplate>(
      `/admin/documents/templates`,
      form,
      {
        headers: {
          contentType: 'multipart/form-data',
        },
      }
    );
  }

  updateDocSample(
    id: number,
    form: FormData
  ): Observable<DocumentTemplate> {
    return this.http.put<DocumentTemplate>(
      `/admin/documents/templates/${id}`,
      form,
      {
        headers: {
          contentType: 'multipart/form-data',
        },
      }
    );
  }

  deleteDocSample(id: number): Observable<DocumentTemplate> {
    return this.http.delete<DocumentTemplate>(
      '/admin/documents/templates/' + id
    );
  }
}
