<div class="autocomplete" [ngClass]="{autocomplete_fill: fill}">
    <input #inputElement type="text" [placeholder]="placeholder" [formControl]="inputControl"
           (focus)="onInputFocus()" (blur)="onInputBlur()" (keydown)="onKeydown($event)"
           class="autocomplete__input text-big" />
    <hr class="autocomplete__focus-line">
    <ul class="autocomplete__suggestions-list" *ngIf="showSuggestions">
      <li *ngFor="let suggestion of filteredSuggestions; let i = index"
          [class.active]="i === selectedIndex"
          (click)="onSelect(suggestion)"
          class="autocomplete__suggestions-item" >
        {{ getName(suggestion) }}
      </li>
    </ul>
    <button *ngIf="inputControl.value" (click)="inputControl.reset()" class="autocomplete__reset">
      <span class="material-symbols-outlined"> cancel </span>
    </button>
  </div>