<div class="top-menu">
  <div class="top-menu__buttons">
    <div class="top-menu__buttons-logo">
      <img
        src="assets/spravka-menu.svg"
        alt="logo"
        (click)="handleLogoClick()" />
    </div>
    <div class="top-menu__buttons-list">
      <div class="top-menu__department">
        <button class="top-menu__department-select" (click)="handleChangeDepartmentClick(true)">
          <div class="text">
            @if (user && user.usersDepartments) {
              {{user.usersDepartments.department.name}}
            }
            @else {
              Выбрать подразделение
            }
          </div>
        </button>

        @if (user && user.usersDepartments) {
          <button class="top-menu__department-clear" (click)="handleClearDepartment()">
            <span class="material-symbols-outlined"> cancel </span>
          </button>
        }
      </div>
    </div>
  </div>
  <div class="top-menu__search">
    <app-search
      class="top-menu__search-input"
      borderRadius="large"
      placeholder="Поиск"
      [control]="searchName"
      (changeEvent)="handleSearch()"
      (clearEvent)="handleClear()"
    ></app-search>
    @if (isSearched) {
      <div class="top-menu__search-results">
        <app-search-block [services]="searchServices"></app-search-block>
      </div>
    }
  </div>
  <div class="top-menu__user">
    <p class="top-menu__name subtitle-default">
      {{ name }}
      <span class="material-symbols-outlined" (click)="handleLogout()">
        logout
      </span>
    </p>
  </div>
</div>



<app-modal
  class="area_white_bg"
  id="change-subdivision"
  width="medium"
  heading="Изменить подразделение"
  (cancelAction)="handleResetSubdivisionsModal()">
  <div class="subdivision">
    <div class="subdivision_block">
      <h1>Выберите подразделение</h1>
      <app-search
        class="top-menu__search-input"
        borderRadius="large"
        placeholder="Поиск"
        [control]="searchSubdivisionName"
        (changeEvent)="handleSubdivisionsSearch()"
        (clearEvent)="handleClear()"></app-search>
      <div class="subdivision_block-list">
        @for (subdivision of subdivisions; track $index) {
          <div
            class="subdivision_block-list-element"
            (click)="handleSelectSubdivisionClick(subdivision)">
            {{ $index + 1 + '. ' + subdivision.name }}
            <span>({{ getSubdivisionTypeTranslation(subdivision) }})</span>
          </div>
        }
      </div>
    </div>
  </div>
</app-modal>
