<div class="wrapper"
     [style.height.px]="height()"
>
  <input type="date"
         class="input on-container text-big"
         [disabled]="disabled"
         [ngModel]="value()"
         (ngModelChange)="onInput($event)"
         (click)="handleClick($event)"
  >
</div>
