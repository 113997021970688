import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { RecoverPasswordDto } from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class RecoverService {
  constructor(private readonly http: HttpClient) {}

  recoverPassword(recoverDto: RecoverPasswordDto): Observable<void> {
    return this.http.post<void>('/auth/recover-password', recoverDto);
  }
}
