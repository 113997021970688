import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@core/services';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  userService.isAuthenticated.subscribe({
    next: async val => {
      if (!val) {
        userService.unsetUser();
        await router.navigate(['/login']);
      }
    },
  });
  return userService.isAuthenticated;
};
