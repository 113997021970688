<div
  class="wrapper"
  [ngClass]="{
      'input-complete': value().length,
      'input-error': error(),
      'input-disabled': disabled,
      'input-focused': focused(),
     }"
>
  <textarea type="text"
            class="textarea on-container text-big"
            [rows]="rows()"
            [disabled]="disabled"
            [placeholder]="placeholder()"
            (focus)="focused.set(true)"
            (blur)="focused.set(false)"
            [ngModel]="value()"
            (ngModelChange)="onInput($event)"
  ></textarea>
  <div class="actions">
    @if (error()) {
      <span class="material-symbols-outlined material-symbols-filled primary-color">
        warning
      </span>
    }
    
      <app-icon-button size="tiny"
                       type="square"
                       icon="cancel"
                       display="overlay"
                       color="container-lowest"
                       (click)="clear()"
                       class="icon"
                       [ngClass]="{
                        'icon-show': value()
                       }"
      ></app-icon-button>
    
  </div>
  
    <hr  [ngClass]="{
      'hr-active': focused()
    }">
  
</div>
@if (tooltip()) {
  <p class="tooltip text-small on-container-lowest">{{ tooltip() }}</p>
}
@if (error()) {
  <p class="tooltip text-small on-container-lowest primary-color">{{ error() }}</p>
}
