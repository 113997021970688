@if (regDoc) {
  <div class="reg-docs-container">
    <div class="reg-docs-container__header">
      <div class="reg-docs-container__heading-zone">
        <app-icon-button
          class="reg-docs-container__heading-button"
          size="small"
          type="square"
          icon="arrow_left_alt"
          display="overlay"
          color="container-lowest"
          (click)="navigate()">
        </app-icon-button>
        <p class="reg-docs-container__heading-title title-bold-h5">
          Регламентирующие документы
        </p>
      </div>
      <div class="reg-docs-container__buttons">
        <app-button
          size="smallest"
          type="left"
          icon="add_circle"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="big"
          buttonContent="Создать новую версию"
          (click)="handleAddNewVersionClick()">
        </app-button>
        <app-button
          size="smallest"
          type="left"
          icon="edit_document"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="big"
          buttonContent="Редактировать"
          (click)="handleUpdateRegDocClick()">
        </app-button>
        <app-button
          size="smallest"
          type="left"
          display="outline"
          color="outline-low"
          sizeText="big"
          buttonContent="Закрыть"
          (click)="navigate()">
        </app-button>
      </div>
    </div>
    <div class="reg-docs-container__tabs">
      <div class="container__tabs">
        @for (tab of tabs; track tab) {
          <button
            class="tab"
            [ngClass]="tab.class"
            (click)="handleTabClick(tab.class)">
            {{ tab.title }}
          </button>
        }
      </div>
    </div>
    @switch (currentTab) {
      @case ('tabs__actual') {
        <app-actual [regDoc]="regDoc"></app-actual>
      }
      @case ('tabs__history') {
        <app-history
          [documentHistory]="regDoc.versions"
          [title]="regDoc.title"></app-history>
      }
    }
  </div>
}

<app-modal
  class="reg-docs-modal"
  id="create-reg-doc-version-modal"
  submitButtonText="Добавить"
  heading="Новая версия регламентирующего документа"
  [requestConfirmToCancel]="createRegDocVersionForm.dirty"
  (submitAction)="onCreateNewVersionSubmit()">
  <form class="reg-docs-form" [formGroup]="createRegDocVersionForm">
    <fieldset class="reg-docs-form__information">
      <h5 class="reg-docs-form__information-text title-bold-h5">
        1. Общая информация
      </h5>
      <div class="main-info area_beige_bg">
        <div class="main-info__activity">
          <div class="main-info__activity-start">
            <p class="activity-start__title on-container">
              Дата начала активности
            </p>
            <core-date-input
              [formControlName]="'activityStart'"></core-date-input>
          </div>
          <div class="main-info__activity-end">
            <p class="activity-end__title on-container">
              Дата окончания активности
            </p>
            <core-date-input
              [formControlName]="'activityEnd'"></core-date-input>
          </div>
        </div>
      </div>
    </fieldset>
    <fieldset class="reg-docs-form__attachments">
      <h5 class="reg-docs-form__attachments-text title-bold-h5">2. Вложения</h5>
      <div
        class="reg-docs-form__upload container-low"
        (click)="fileInput.click()"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)">
        <input
          type="file"
          class="form-upload-hidden"
          (change)="onFileSelected($event)"
          #fileInput
          multiple />
        <div class="form-upload">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.66663 25.9999C6.82392 25.9999 5.25213 25.3607 3.95127 24.0823C2.65042 22.8039 2 21.2414 2 19.3948C2 17.7384 2.53291 16.2816 3.59873 15.0243C4.66456 13.7671 5.98635 13.0222 7.5641 12.7898C7.99146 10.7966 8.99359 9.16667 10.5705 7.9C12.1474 6.63333 13.9572 6 16 6C18.4142 6 20.4622 6.84088 22.144 8.52263C23.8257 10.2044 24.6666 12.2524 24.6666 14.6666V15.3333H25.0769C26.482 15.4427 27.6538 16.0077 28.5922 17.0282C29.5307 18.0487 29.9999 19.2615 29.9999 20.6666C29.9999 22.1538 29.4871 23.4145 28.4615 24.4487C27.4358 25.4828 26.1794 25.9999 24.6923 25.9999H17.4102C16.7367 25.9999 16.1666 25.7666 15.7 25.2999C15.2333 24.8333 15 24.2632 15 23.5897V16.2871L12.5333 18.7128L11.1282 17.3205L16 12.4487L20.8717 17.3205L19.4666 18.7128L16.9999 16.2871V23.5897C16.9999 23.6922 17.0427 23.7863 17.1282 23.8718C17.2136 23.9572 17.3077 24 17.4102 24H24.6666C25.6 24 26.3889 23.6777 27.0333 23.0333C27.6777 22.3889 28 21.6 28 20.6666C28 19.7333 27.6777 18.9444 27.0333 18.3C26.3889 17.6555 25.6 17.3333 24.6666 17.3333H22.6666V14.6666C22.6666 12.8222 22.0166 11.25 20.7166 9.94997C19.4166 8.64997 17.8444 7.99997 16 7.99997C14.1555 7.99997 12.5833 8.64997 11.2833 9.94997C9.9833 11.25 9.3333 12.8222 9.3333 14.6666H8.641C7.37776 14.6666 6.28843 15.1222 5.37303 16.0333C4.45766 16.9444 3.99997 18.0444 3.99997 19.3333C3.99997 20.6222 4.45552 21.7222 5.36663 22.6333C6.27775 23.5444 7.37774 24 8.66663 24H12V25.9999H8.66663Z"
              fill="#251917" />
          </svg>
          <p class="form-upload__text">
            Перетащите файлы в эту область или
            <span class="form-upload__span">выберите файлы на устройстве</span>
          </p>
        </div>
      </div>
      <div class="reg-docs-form__list">
        @if (files.length > 0) {
          @for (file of files; track file.name; let i = $index) {
            <div class="reg-docs-form__item">
              <button (click)="removeFile(i)">
                <span class="material-symbols-outlined on-container-lowest">
                  delete_forever
                </span>
              </button>
              <div class="item-form-content">
                <p class="item-form-content__title text-default">
                  {{ file.name }}
                </p>
                <div class="item-form-content__info">
                  <app-badge
                    size="small"
                    display="fill"
                    color="container-lowest"
                    size_text="small"
                    badge_content="Загружен"></app-badge>
                  <app-badge
                    size="small"
                    display="tint"
                    color="tertiary-container"
                    size_text="small"
                    [badge_content]="
                      file.name.substring(file.name.lastIndexOf('.') + 1)
                        | uppercase
                    "></app-badge>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </fieldset>

    <fieldset class="reg-docs-form__relations">
      <h5
        class="reg-docs-form__relations-text title-bold-h5 modal__title modal__title_info modal__title_relations">
        3. Связи с документами
      </h5>
      <div class="reg-docs-form__relations-select">
        <core-text-input
          placeholder="Поиск документа"
          leftIcon="search"
          [formControl]="regDocNameControl"></core-text-input>
        <div class="reg-doc">
          @for (doc of relatedDocuments; track doc) {
            <div class="reg-doc-element">
              <div class="reg-doc-element-title" (click)="selectRegDoc(doc)">
                {{ doc.title }}
              </div>
            </div>
          }
        </div>

        @if (documentsToAdd.length) {
          <div class="selected-reg-docs">
            @for (doc of documentsToAdd; track $index) {
              <div class="selected-reg-docs-element">
                <div class="selected-reg-docs-element-body">
                  <div class="selected-reg-docs-element-title">
                    {{ $index + 1 + '. ' + doc.title }}
                  </div>
                  @if (getDocumentActualVersionHelper(doc)) {
                    {{
                      parseActivity(
                        getDocumentActualVersionHelper(doc)?.activityStart,
                        getDocumentActualVersionHelper(doc)?.activityEnd
                      )
                    }}
                  }
                </div>

                <div class="selected-reg-docs-element-buttons">
                  <app-icon-button
                    size="small"
                    type="square"
                    icon="delete"
                    display="overlay"
                    color="cell"
                    (click)="removeRegDoc(doc)"></app-icon-button>
                </div>
              </div>
            }
          </div>
        }
        <!--                    <app-select type="normal" placeholder="Выбрать документы для связи" [options]="relatedDocuments">-->
        <!--                    </app-select>-->
      </div>
    </fieldset>
  </form>
</app-modal>

<app-modal
  class="reg-docs-modal"
  id="update-reg-doc-modal"
  submitButtonText="Изменить"
  heading="Изменить регламентирующий документ"
  [requestConfirmToCancel]="updateRegDocForm.dirty"
  (submitAction)="onEditFormSubmit()">
  <form class="reg-docs-form" [formGroup]="updateRegDocForm">
    <fieldset class="reg-docs-form__information">
      <h5 class="reg-docs-form__information-text title-bold-h5">
        1. Общая информация
      </h5>
      <div class="main-info area_beige_bg">
        <p class="main-info__title on-container subtitle-default">
          Название документа
        </p>
        <div class="main-info__search">
          <core-text-input
            placeholder="Полное наименование документа"
            [formControlName]="'title'"
            (click)="resetErrors()"
            [error]="titleError"></core-text-input>
        </div>
      </div>
    </fieldset>
  </form>
</app-modal>
