import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@core/services';
import { getFirstPermissionRouteHelper } from '@core/utils';

export const loginGuard =
  (redirectLink?: string) => async (): Promise<void> => {
    const isAuth = !!inject(UserService).currentUserSubject.getValue();
    if (isAuth)
      await inject(Router).navigate([
        await getFirstPermissionRouteHelper(redirectLink),
      ]);
  };
