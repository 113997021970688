import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [NgClass, CommonModule, ReactiveFormsModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() whiteBg: boolean = false;
  @Input() searchIcon: boolean = true;
  @Input() multiline: boolean = false;
  @Input() isShowClear: boolean = true;
  @Input() height: number = 24;
  @Input() type: string = 'text';
  @Input() width: string = 'regular';
  @Input() borderRadius: 'none' | 'medium' | 'large' | 'small' = 'medium';
  @Input() control: FormControl = new FormControl();
  @Output() changeEvent = new EventEmitter();
  @Output() clearEvent = new EventEmitter();

  public isFocused: boolean = false;

  constructor(
    private readonly router: Router,
    private ref: ChangeDetectorRef
  ) {}

  onInput(event: any) {
    if (event.target.value.length > 0) {
      this.control.markAsDirty();
    } else {
      this.control.markAsPristine();
    }
  }

  cleanInput() {
    this.control.reset();
    this.control.markAsPristine();
    this.changeEvent.emit();
    this.clearEvent.emit();
  }

  handleClick(type: string, e: Event) {
    if (type === 'date') {
      const datePicker: HTMLInputElement = e.target as HTMLInputElement;

      datePicker.showPicker();
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (!this.control.dirty && !this.control.value) {
        this.ref.markForCheck();
      }
    });
  }
}
