import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  concatMap,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { User, TokenModel } from '@core/models';
import { LoginDto, SignupDto } from '@core/models/dto';
import { JwtService } from './jwt.service';
import { PermissionsEnum } from '@core/utils';

@Injectable({ providedIn: 'root' })
export class UserService {
  public currentUserSubject = new BehaviorSubject<User | null>(null);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public permissionsSubject = new BehaviorSubject<PermissionsEnum[]>([]);
  public permissions = this.permissionsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public isAuthenticated = this.currentUser.pipe(map(user => !!user));

  constructor(
    private readonly http: HttpClient,
    private readonly jwtService: JwtService,
    private readonly router: Router
  ) {}

  public login(loginDto: LoginDto): Observable<TokenModel> {
    return this.http.post<TokenModel>('/auth/login', loginDto)
      .pipe(concatMap((token: TokenModel) => this.setAuth(token)));
  }

  public register(signupDto: SignupDto): Observable<TokenModel> {
    return this.http.post<TokenModel>('/auth/register', signupDto)
      .pipe(concatMap((token: TokenModel) => this.setAuth(token)));
  }

  public logout(): void {
    this.purgeAuth();
    this.unsetUser();
    void this.router.navigate(['/login']);
  }

  public getCurrentUser(): Observable<User> {
    return this.http.get<User>('/users').pipe(
      tap({
        next: user => this.setUser(user),
        error: () => this.logout(),
      }),
      shareReplay(1)
    );
  }

  public selectSubdivision(id: number): Observable<User> {
    return this.http.put<User>(`/users/${id}/subdivisions`, {}).pipe(
      tap({
        next: user => this.setUser(user),
        error: (err: Error) => console.error(err),
      }),
      shareReplay(1)
    );
  }

  public clearSubdivision(): Observable<User> {
    return this.http.delete<User>(`/users/subdivisions`, {}).pipe(
      tap({
        next: user => this.setUser(user),
        error: (err: Error) => console.error(err),
      }),
      shareReplay(1)
    );
  }

  public setUser(user: User): User {
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUserSubject.next(user);
    this.permissionsSubject.next(
      user.permissions?.map(permission => permission.data)
    );
    return user;
  }

  public unsetUser(): void {
    localStorage.removeItem('user');
  }

  public setAuth(tokenModel: TokenModel): Observable<TokenModel> {
    this.jwtService.saveToken(tokenModel.token);
    return this.getCurrentUser().pipe(
      tap((user: User) => this.setUser(user)),
      concatMap(() => of(tokenModel))
    );
  }

  public purgeAuth(): void {
    this.jwtService.destroyToken();
    this.currentUserSubject.next(null);
  }

  public async getPermissions(): Promise<PermissionsEnum[]> {
    return firstValueFrom(
      of(0).pipe(
        withLatestFrom(this.permissions),
        map(tuple => tuple[1]),
        take(1)
      )
    );
  }
}