import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { DocumentHistory, DocumentVersion } from '@core/models';
import { openFileInNewTabHelper, parsePeriodHelper } from '@core/utils';
import {
  BadgeComponent,
  ButtonComponent,
  DateInputComponent,
  IconButtonComponent,
} from '@core/components';

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [
    RouterLink,
    BadgeComponent,
    ButtonComponent,
    DateInputComponent,
    IconButtonComponent,
  ],
  templateUrl: './reg-doc-history.component.html',
  styleUrl: './reg-doc-history.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegDocHistoryComponent {
  public documentHistory: InputSignal<DocumentVersion[]> =
    input.required<DocumentVersion[]>();
  public title: InputSignal<string> = input.required<string>();
  public selected: DocumentHistory | null = null;

  constructor() {}

  handleRegDocClick(regDoc: DocumentVersion) {
    if (this.selected?.id === regDoc.id) {
      this.selected = null;
    } else {
      this.selected = regDoc;
    }
  }

  protected readonly parsePeriodHelper = parsePeriodHelper;
  protected readonly openFileInNewTabHelper = openFileInNewTabHelper;
}
