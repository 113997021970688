<div class="service-container__header">
  <div class="service-container__heading-zone">
    @for (button of buttons().slice(0, 1); track $index) {
      @if (button.click) {
        <app-button
          size="small"
          type="left"
          [display]="button.display"
          [color]="button.color"
          sizeText="small"
          [icon]="button.icon"
          (click)="button.click()"
        ></app-button>
      }
    }
    <h5 class="service-container__heading title-bold-h5">{{ title() }}</h5>
  </div>
  <div class="service-container__buttons">
    @for (button of buttons().slice(1); track $index) {
      @if (button.click) {
        <app-button
          size="smallest"
          type="left"
          [display]="button.display"
          [color]="button.color"
          sizeText="default"
          [icon]="button.icon"
          [buttonContent]="button.content"
          [routerLink]="button.routerLink"
          (click)="button.click()"></app-button>
      } @else {
        <app-button
          size="smallest"
          type="left"
          [display]="button.display"
          [color]="button.color"
          sizeText="default"
          [icon]="button.icon"
          [buttonContent]="button.content"
          [routerLink]="button.routerLink"></app-button>
      }
    }
  </div>
</div>
