import { CommonModule, NgClass, Location } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-menu-point',
  standalone: true,
  imports: [NgClass, CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './menu-point.component.html',
  styleUrl: './menu-point.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class MenuPointComponent implements OnInit {
  @Input({ required: true }) text: string = '';
  @Input() icon?: string;
  @Input() link?: string;
  @Input() shouldNavigate?: boolean = true;
  @Input() isActive?: boolean;
  @Input() type: 'admin' | 'client' = 'admin';
  @Input() wrap: 'wrap' | 'nowrap' = 'wrap';

  constructor(private location: Location) {
    this.location.onUrlChange(() => {
      this.handleUrlCheck();
    });
  }

  handleUrlCheck() {
    this.isActive = this.link === this.location.path();
    if (this.location.path().slice(0, 9) === this.link && '/services') {
      this.isActive = true;
    }

    if (this.location.path() === '' && this.link === '/') {
      this.isActive = true;
    }
  }

  ngOnInit() {
    if (this.shouldNavigate) {
      this.handleUrlCheck();
    } else {
      document.querySelectorAll('.menu-point').forEach(item => {
        item.classList.add('menu-point_no-navigate');
      });
      document.querySelectorAll('.menu-point__text').forEach(item => {
        item.classList.add('menu-point_no-navigate');
      });
    }
  }
}
