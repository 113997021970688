import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Classifier, ClassifiersWithTotalList } from '@core/models';
import {
  CreateClassifierDto,
  DeleteClassifierDto,
  SearchClassifiersDto,
  UpdateClassifierDto,
} from '@core/models/dto';
import { ClassifiersEnum } from '@core/utils';

@Injectable({ providedIn: 'root' })
export class ClassifiersService {
  constructor(private readonly http: HttpClient) {}

  getTopClassifiers(count: number = 5){
    const payload = Object.keys(ClassifiersEnum).reduce<any>((payload, type) => ({ ...payload, [type]: { from: 0, to: count }}), {});
    return this.getClassifiers(payload);
  }

  getClassifiers(
    searchClassifiersDto?: SearchClassifiersDto
  ): Observable<ClassifiersWithTotalList> {
    const params = new URLSearchParams();
    if (searchClassifiersDto) {
      for (const [key, value] of Object.entries(searchClassifiersDto)) {
        if(typeof value === 'string'){
          params.set(key, value)
        } else {
          params.set(key, `from:${value.from},to:${value.to}`);
        }
      }
    }
    const uri = '/classifiers?' + params.toString();
    return this.http.get<ClassifiersWithTotalList>(uri);
  }

  updateClassifiersPositions(
    type: ClassifiersEnum,
    positions: { id: number; position: number }[]
  ): Observable<Classifier[]> {
    return this.http.put<Classifier[]>(
      `/admin/classifiers/${type}/positions`,
      positions
    );
  }

  createClassifier(
    createClassifierDto: CreateClassifierDto
  ): Observable<Classifier> {
    return this.http.post<Classifier>(
      '/admin/classifiers',
      createClassifierDto
    );
  }

  getOneClassifier(type: string, id: number): Observable<Classifier> {
    return this.http.get<Classifier>(`/classifiers/${type}/${id}`);
  }

  updateClassifier(
    id: number,
    updateClassifierDto: UpdateClassifierDto
  ): Observable<Classifier> {
    return this.http.put<Classifier>(
      `/admin/classifiers/${id}`,
      updateClassifierDto
    );
  }

  deleteClassifier(
    id: number,
    deleteClassifierDto: DeleteClassifierDto
  ): Observable<Classifier> {
    return this.http.delete<Classifier>('/admin/classifiers/' + id, {
      body: deleteClassifierDto,
    });
  }
}
