<div class="container-login primary-container">
  <div class="container-login__block container-lowest">
    <h1>Создайте новый пароль</h1>
    <p class="container-login__sub-title">Вспомнили пароль? <a [routerLink]="'/login'" class="container-login__forgot_password">Войти</a></p>

    @if (showSuccess) {
      <div class="container-login__success-recover">
        Пароль успешно изменен!
      </div>

      <app-button class="container-login__button"
                  size="normal"
                  display="inverse"
                  type="right"
                  icon="arrow_right_alt"
                  color="inverse-container"
                  sizeText="big"
                  [usedBadge]="false"
                  buttonContent="Войти"
                  typing="submit"
                  [routerLink]="'/login'"
      />
    } @else {
      <form
        class="container-login__form"
        [formGroup]="restorePasswordForm"
        (ngSubmit)="onFormSubmit()"
        (keydown.enter)="onFormSubmit()"
      >
        <fieldset class="container-login__fieldset" [disabled]="isSubmitted">

          <div class="login-password-input">
            <input type="{{showPassword ? 'text' : 'password'}}"
                   class="login-input text-big"
                   placeholder="Пароль"
                   [formControl]="password"
                   (click)="resetError()"
            />
            <div class="show-icon" (click)="handleClickShowPassword('password')">
              <img src="../../../assets/icons/show.svg" alt="show"/>
            </div>
          </div>

          <div class="login-password-input">
            <input type="{{showPasswordRepeat ? 'text' : 'password'}}"
                   class="login-input text-big"
                   placeholder="Повторите пароль"
                   [formControl]="passwordRepeat"
                   (click)="resetError()"
            />
            <div class="show-icon" (click)="handleClickShowPassword('passwordRepeat')">
              <img src="../../../assets/icons/show.svg" alt="show"/>
            </div>
          </div>

          <p class="container-login__error">{{error}}</p>
          <ul class="container-login__list">
            <li>— Проверьте CAPS–Lock</li>
            <li>— Пароль чувствителен к регистру букв</li>
            <li>— Пароль от 6 символов</li>
          </ul>

          <app-button class="container-login__button"
                      size="normal"
                      display="inverse"
                      type="right"
                      icon="arrow_right_alt"
                      color="inverse-container"
                      sizeText="big"
                      [usedBadge]="false"
                      buttonContent="Сохранить и войти"
                      typing="submit"
          />
        </fieldset>
      </form>
    }
  </div>
<!--  <p class="container-login__text text-default on-container-lowest">-->
<!--    There is no reality except the one contained within us.<br/>-->
<!--    That is why so many people live such an unreal life.<br/>-->
<!--    2024.-->
<!--  </p>-->
  <div class="container-login__help">
    <app-icon-button class="container-login__help-button"
                     size="normal"
                     display="cell"
                     type="circle"
                     icon="help"
                     color="cell">
    </app-icon-button>
  </div>
</div>
