<li class="list-item">
    @if (icon) {
    <img src="{{icon}}" alt="icon" class="list-item__left-icon">
    }
    <div class="list-item__text-container">
        @if (topline) {
        <p class="on-container-low text-smallest">{{topline}}</p>
        }
        <p class="subtitle-default list-item__heading">{{heading}}</p>
        @if (subtext) {
        <p class="on-container-low text-small">{{subtext}}</p>
        }
    </div>
    <ng-content></ng-content>
</li>