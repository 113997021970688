import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '@core/components/inputs/input.component';
import { NgClass } from '@angular/common';
import { IconButtonComponent } from '@core/components';

@Component({
  selector: 'core-email-input',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    IconButtonComponent,
  ],
  templateUrl: './email-input.component.html',
  styleUrl: './email-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EmailInputComponent
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailInputComponent extends InputComponent<string>{
  placeholder = input<string>();
  tooltip = input<string>();
  height = input<number>(48);

  clear() {
    this.onInput(this.defaultValue());
  }

  override defaultValue(): string {
    return '';
  }
}
