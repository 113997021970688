import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentTransferMethod } from '@core/models';
import { Observable } from 'rxjs';
import {
  CreateDocumentTransferMethodDto,
  SearchDocsTransferMethodDto, UpdateDocumentTransferMethodDto,
} from '@core/models/dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentsTransferMethodsService {
  constructor(private readonly http: HttpClient) {}

  public getDocumentTransferMethods(searchDto?: SearchDocsTransferMethodDto): Observable<DocumentTransferMethod[]> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }
    let uri = '/documents/transfer-methods';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<DocumentTransferMethod[]>(uri);
  }

  public createDocumentTransferMethod(
    createDto: CreateDocumentTransferMethodDto
  ): Observable<DocumentTransferMethod> {
    return this.http.post<DocumentTransferMethod>(
      '/admin/documents/transfer-methods',
      createDto
    );
  }

  public updateDocumentTransferMethod(
    id: number,
    updateDocumentTransferMethodDto: UpdateDocumentTransferMethodDto
  ) {
    return this.http.put<DocumentTransferMethod>(
      `/admin/documents/transfer-methods/${id}`,
      updateDocumentTransferMethodDto
    );
  }
  public deleteDocumentTransferMethod(id: number) {
    return this.http.delete<DocumentTransferMethod>(`/admin/documents/transfer-methods/${id}`);
  }
}
