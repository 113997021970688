import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Service, ServicesList, Subdivision, User } from '@core/models';
import {
  ModalService,
  ServicesService,
  SubdivisionsService,
  UserService,
} from '@core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  IconButtonComponent,
  ModalComponent,
  SearchComponent,
} from '@core/components';
import { SearchBlockComponent } from '@core/components/overlay/client-top-menu/search-block/search-block.component';
import { getSubdivisionTypeTranslationsHelper } from '@core/utils';

interface SearchForm {
  name: FormControl<string | null>;
}

@Component({
  selector: 'app-client-menu',
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    SearchBlockComponent,
    ModalComponent,
    IconButtonComponent,
  ],
  templateUrl: './client-top-menu.component.html',
  styleUrl: './client-top-menu.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
})
export class ClientTopMenuComponent implements OnInit {
  user: User | null = null;
  name: string = '';
  destroyRef = inject(DestroyRef);
  searchForm: FormGroup<SearchForm>;
  isSearchBlocked = false;
  searchServices = signal<Service[]>([]);
  isSearched: boolean = false;
  subdivisions: Subdivision[] = [];
  public searchSubdivisionsForm = new FormGroup<SearchForm>({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly servicesService: ServicesService,
    private readonly modalService: ModalService,
    private readonly subdivisionsService: SubdivisionsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.searchForm = new FormGroup<SearchForm>({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });
  }

  get searchName(): FormControl<string | null> {
    return this.searchForm.controls.name;
  }

  get searchSubdivisionName(): FormControl<string | null> {
    return this.searchSubdivisionsForm.controls.name;
  }

  ngOnInit(): void {
    this.userService.currentUser
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: User | null) => {
        this.user = user;
        if (this.user)
          this.name = `${this.user.firstName} ${this.user.lastName[0]}. ${this.user.middleName[0]}.`;
      });
    this.router.events.subscribe(() => {
      this.isSearched = false;
      this.isSearchBlocked = false;
      this.searchForm.reset();
    });
  }

  public async handleLogoClick(): Promise<void> {
    await this.router.navigate(['/']);
  }

  public handleSearch(): void {
    const data = {
      name: (this.searchName?.value as string) ?? '',
      from: 0,
      to: 5,
    };
    if (!data.name.length) this.isSearched = false;
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.servicesService.getServices(data).subscribe({
        next: (services: ServicesList) => {
          this.searchServices.set(services.active);
          this.isSearchBlocked = false;
          this.isSearched = !!data.name.length;
        },
        error: (err: Error) => {
          console.error(err);
          this.isSearchBlocked = false;
          this.isSearched = false;
        },
      });
    }
  }

  public handleLogout() {
    this.userService.logout()
  }

  public handleClear(): void {
    this.isSearched = false;
  }

  public handleSubdivisionsSearch(): void {
    if (
      this.searchSubdivisionName.value === null ||
      this.searchSubdivisionName.value?.length === 0
    ) {
      this.handleChangeDepartmentClick(false);
      return;
    }

    if (this.isSearchBlocked) {
      this.handleClear();
      return;
    }

    this.isSearchBlocked = true;
    this.subdivisionsService
      .getSubdivisions({
        name: this.searchSubdivisionName.value,
        from: 0,
        to: 5,
      })
      .subscribe({
        next: (subdivisions: Subdivision[]) => {
          this.subdivisions = subdivisions;
          this.isSearchBlocked = false;
          this.changeDetector.detectChanges();
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  public async handleChangeDepartmentClick(
    isOpenModal: boolean
  ): Promise<void> {
    this.subdivisionsService
      .getSubdivisions({
        from: 0,
        to: 10,
      })
      .subscribe((subdivisions: Subdivision[]) => {
        this.subdivisions = subdivisions;
        this.changeDetector.detectChanges();
        if (isOpenModal) {
          this.modalService.open('change-subdivision');
        }
      });
  }



  public getSubdivisionTypeTranslation = getSubdivisionTypeTranslationsHelper;

  public handleSelectSubdivisionClick(subdivision: Subdivision): void {
    this.userService.selectSubdivision(subdivision.id).subscribe({
      next: () => {
        if (this.user?.usersDepartments?.department) {
          this.user.usersDepartments.department.name = subdivision.name;
          this.changeDetector.detectChanges();
        }
        this.modalService.close();
        this.handleResetSubdivisionsModal();
      },
    });
  }

  public handleClearDepartment(){
    this.userService.clearSubdivision().subscribe({
      next: () => {
        this.changeDetector.detectChanges();
      }
    });
  }

  public handleResetSubdivisionsModal(): void {
    this.isSearched = false;
    this.isSearchBlocked = false;
    this.searchSubdivisionsForm.reset();
  }
}
