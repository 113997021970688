import {
  ChangeDetectionStrategy,
  Component, input,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IconButtonComponent } from '@core/components';
import { InputComponent } from '@core/components/inputs/input.component';

@Component({
  selector: 'core-date-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    IconButtonComponent,
    FormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    }
  ],
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent extends InputComponent<Date>{
  height = input<number>(48);

  handleClick(e: Event) {
    const datePicker: HTMLInputElement = e.target as HTMLInputElement;

    datePicker.showPicker();
  }

  override defaultValue(): Date {
    return new Date();
  }
}
