import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Permission } from '@core/models';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private readonly http: HttpClient) {}

  getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>('/admin/permissions');
  }
}
