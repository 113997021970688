import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { AlertComponent } from '@core/components';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private isShowing: boolean = false;
  private element: HTMLElement = document.getElementById(
    'alert'
  ) as HTMLElement;
  private currentTitleSubject = new BehaviorSubject<string>('Успешно');
  public currentTitle = this.currentTitleSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private currentDataSubject = new BehaviorSubject<string>('');
  public currentData = this.currentDataSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(private readonly injector: Injector) {}

  public open(data: string, title: string = 'Успешно'): void {
    if (this.isShowing) return;
    this.setTitle(title);
    this.setData(data);
    this.injector.get<AlertComponent>(AlertComponent).open();
    this.element.style.display = 'block';
    this.isShowing = true;
  }

  public close(): void {
    this.element.style.display = 'none';
    this.isShowing = false;
  }

  public setTitle(title: string): void {
    this.currentTitleSubject.next(title);
  }

  public setData(data: string): void {
    this.currentDataSubject.next(data);
  }

  public toggle(
    data: string,
    title: string = 'Успешно',
    timeout: number = 1000
  ): void {
    this.open(data, title);
    setTimeout(() => this.close(), timeout);
  }
}
