@if (display() === 'outline') {
  <span [class]="'flex-span ' + size() + ' ' + color() + ' outline-' + color()">
    @if (used_left_icon()) {
      <button
        [class]="size() + '-' + display() + '-icon-left material-symbols-outlined'"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
      >
        {{ left_icon() }}
      </button>
    }
    <span [class]="size() + '-' + display() + '-inner-text subtitle-' + size_text()">
      {{ badge_content() }}
    </span>
    @if (used_right_icon()) {
      <button
        [class]="size() + '-' + display() + '-icon-right material-symbols-outlined'"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
        (click)="handleCancel()"
      >
        {{ right_icon() }}
      </button>
    }
  </span>
} @else {
  <span [class]="'flex-span ' + size() + ' ' + color()">
    @if (used_left_icon()) {
      <button
        [class]="size() + '-' + display() + '-icon-left material-symbols-outlined'"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
      >
        {{ left_icon() }}
      </button>
    }
    <span [class]="size() + '-' + display() + '-inner-text subtitle-' + size_text()">
      {{ badge_content() }}
    </span>
    @if (used_right_icon()) {
      <button
        [class]="size() + '-' + display() + '-icon-right material-symbols-outlined'"
        [ngClass]="{
          'material-symbols-filled': fill()
        }"
        (click)="handleCancel()"
      >
        {{ right_icon() }}
      </button>
    }
  </span>
}
