import { Component, DestroyRef, inject, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  ButtonComponent,
  IconButtonComponent,
  ModalComponent,
} from '@core/components';
import { RestorePasswordService } from '@core/services';

interface RecoverForm {
  password: FormControl<string>;
  passwordRepeat: FormControl<string>;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    ModalComponent,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './restore-password.component.html',
  styleUrl: './restore-password.component.scss',
})
export class RestorePasswordComponent {
  @Input() userId!: string;
  @Input() key!: string;

  destroyRef = inject(DestroyRef);
  isSubmitted = false;
  restorePasswordForm: FormGroup;
  showSuccess = false;
  error = '';
  showPassword = false;
  showPasswordRepeat = false;

  constructor(
    private readonly restorePasswordService: RestorePasswordService,
    private readonly router: Router
  ) {
    const query = this.router.parseUrl(this.router.url).queryParams;
    if (!query || !query['userId'] || !query['key']) {
      this.router.navigate(['/404']);
    }
    this.userId = query['userId'];
    this.key = query['key'];
    this.restorePasswordService
      .checkIsRestorable({ userId: +this.userId, key: this.key })
      .subscribe({
        next: (res: boolean) => {
          if (!res) this.router.navigate(['/404']);
        },
        error: () => this.router.navigate(['/404']),
      });
    this.restorePasswordForm = new FormGroup<RecoverForm>({
      password: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      passwordRepeat: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  get password(): FormControl<string> {
    return this.restorePasswordForm.controls['password'] as FormControl<string>;
  }

  get passwordRepeat(): FormControl<string> {
    return this.restorePasswordForm.controls[
      'passwordRepeat'
    ] as FormControl<string>;
  }

  onFormSubmit() {
    if (
      this.password.value.length < 6 ||
      this.passwordRepeat.value.length < 6
    ) {
      this.error = 'Пароль не должен быть меньше 6 символов';
      this.restorePasswordForm.reset();
      return;
    }

    if (this.password.value !== this.passwordRepeat.value) {
      this.error = 'Пароли не совпадают';
      this.restorePasswordForm.reset();
      return;
    }
    this.isSubmitted = true;

    this.restorePasswordService
      .restorePassword({
        key: this.key,
        userId: this.userId,
        password: this.password.value,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.router.navigate(['/login']),
        error: (err: Error & { statusCode: number }) => {
          if (err.statusCode === 409) {
            this.error = 'Новый пароль должен отличаться от старого';
          } else {
            this.error = 'Ошибка сервера, попробуйте позже';
          }
          this.isSubmitted = false;
          this.restorePasswordForm.reset();
        },
      });
  }

  resetError(): void {
    this.error = '';
  }

  handleClickShowPassword(type: string): void {
    switch (type) {
      case 'password':
        this.showPassword = !this.showPassword;
        break;
      case 'passwordRepeat':
        this.showPasswordRepeat = !this.showPasswordRepeat;
        break;
    }
  }
}
