import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { TopMenuComponent } from '@core/components/overlay/top-menu/top-menu.component';
import { LeftMenuComponent } from '@core/components/overlay/left-menu/left-menu.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'core-overlay-admin',
  standalone: true,
  imports: [
    LeftMenuComponent,
    TopMenuComponent,
    RouterOutlet
  ],
  templateUrl: './overlay-admin.component.html',
  styleUrl: './overlay-admin.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayAdminComponent {}

