import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UserService } from '@core/services';
import { PermissionsEnum } from '@core/utils';

export const permissionGuard =
  (permission: PermissionsEnum): CanActivateFn =>
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const userService = inject(UserService);

    return userService.permissions.pipe(
      map((permissions: PermissionsEnum[]) => {
        if (!permissions.length) {
          userService.logout();
        }
        return permissions.includes(permission);
      })
    );
  };
