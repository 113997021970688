@if (type() === 'normal') {
  <div class="{{ classes }}">
    @if (label()) {
      <label class="input-label">{{ label() }}</label>
    }
    <input
      type="{{ inputType() }}"
      class="input text-big"
      [ngClass]="isError ? 'input__warning' : 'on-container'"
      [placeholder]="placeholder()"
      (focus)="isFocused = true"
      (blur)="isFocused = false"
      [formControl]="control"
      [formControlName]="formControlName" />
  </div>
} @else if (type() === 'checkbox') {
  <div class="{{ classes }}">
    <label class="checkbox-input-label input-label-{{ labelVersion() }}">
      {{ label() }}
      <div class="checkbox">
        <input
          class="checkbox__container"
          type="checkbox"
          [formControl]="control"
          (click)="changeEvent.emit()" />
      </div>
    </label>
  </div>
} @else if (type() === 'mail') {
  <hr
    class="input__focused-line"
    [ngClass]="isFocused && !isError ? 'input__focused-line_active' : ''" />
  @if (!isError) {
    <img
      src="../../../assets/icons/{{
        isFocused ? 'mail-focused.svg' : 'mail-black.svg'
      }}"
      alt=">"
      class="input__mail-icon" />
  } @else {
    <img
      src="../../../assets/icons/warning-icon.svg"
      alt=">"
      class="input__mail-icon" />
  }
  <input
    type="text"
    class="input text-big"
    [ngClass]="isError ? 'input__warning' : 'on-container'"
    [defaultValue]="'Input'"
    (focus)="isFocused = true"
    (blur)="isFocused = false" />
  <div class="input__icons">
    <button class="input__cross-icon"></button>
    @if (!isError) {
      <button class="input__pointer-icon"></button>
    }
  </div>
  <p
    class="text-smallest on-container-lowest"
    [ngClass]="isError ? 'input__warning_subtext' : ''">
    Supporting text
  </p>
  <button (click)="isError = !isError">toggleError</button>
} @else if (type() === 'password') {
  <div class="{{ classes }}">
    @if (label()) {
      <label class="input-label">{{ label() }}</label>
    }
    <input
      type="{{ inputType() }}"
      class="input text-big"
      [ngClass]="isError ? 'input__warning' : 'on-container'"
      [placeholder]="placeholder()"
      (focus)="isFocused = true"
      (blur)="isFocused = false"
      [formControl]="control" />
  </div>
}
