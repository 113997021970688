import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule, NgClass, UpperCasePipe } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { debounceTime } from 'rxjs';

import {
  BadgeComponent,
  ButtonComponent,
  SearchComponent,
  IconButtonComponent,
  ModalComponent,
  InputComponent,
  SelectComponent,
  AlertComponent,
  CardComponent,
} from '@core/components';
import { AlertService, ModalService, RegDocsService } from '@core/services';
import { Document, DocumentsList, DocumentVersion } from '@core/models';
import { CreateDocSampleDto } from '@core/models/dto';
import {
  getDocumentActualVersionHelper,
  ParseDataToFormHelper,
  parsePeriodHelper,
} from '@core/utils/helpers';
import { TextInputComponent } from '@core/components/inputs/text-input/text-input.component';
import { DateInputComponent } from '@core/components/inputs/date-input/date-input.component';
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component';
import { SelectedDocumentsComponent } from '@core/components/selected-documents/selected-documents.component';

interface CreateRegDocsForm {
  title: FormControl<string>;
  activityStart: FormControl<Date | null>;
  activityEnd: FormControl<Date | null>;
}

type Tab = {
  title: string;
  class: string;
};

interface SearchForm {
  name: FormControl<string>;
}

@Component({
  selector: 'app-services-page',
  standalone: true,
  imports: [
    BadgeComponent,
    ButtonComponent,
    IconButtonComponent,
    SearchComponent,
    NgClass,
    IconButtonComponent,
    ModalComponent,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    UpperCasePipe,
    SelectComponent,
    TextInputComponent,
    DateInputComponent,
    RouterLink,
    CardComponent,
    CommonModule,
    AutocompleteComponent,
    SelectedDocumentsComponent
  ],
  templateUrl: './reg-docs-page.component.html',
  styleUrl: './reg-docs-page.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  providers: [AlertService, AlertComponent],
})
export class RegDocsPageComponent implements AfterViewInit {
  public isCreate: boolean = true;
  public selectedDocument!: Document;
  public createRegDocsForm: FormGroup;
  public searchForm: FormGroup<SearchForm>;
  public isSearchBlocked: boolean = false;
  public regDocs: Document[] = [];
  public archivedRegDocs: Document[] = [];
  public needAttentionRegDocs: Document[] = [];
  public needAttentionRegDocsTotal: number = 0;
  public files: File[] = [];
  public fileUploadError = '';
  public titleError: string = '';
  public totalCount: number = 0;
  public relatedDocuments: Document[] = [];

  public tabs: Tab[] = [
    {
      title: 'Актуальные',
      class: 'tabs__actual',
    },
    {
      title: 'Архив',
      class: 'tabs__archive',
    },
    {
      title: 'Требуют внимания',
      class: 'tabs__need-attention',
    },
  ];
  public currentTab: string = this.tabs[0].class;

  parseActivity = parsePeriodHelper;

  constructor(
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly regDocsService: RegDocsService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.createRegDocsForm = new FormGroup<CreateRegDocsForm>({
      title: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      activityStart: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
      activityEnd: new FormControl(null, {
        validators: [Validators.required],
        nonNullable: false,
      }),
    });
    this.searchForm = new FormGroup({
      name: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ regDocs }) => {
      this.regDocs = regDocs.active.data ?? [];
      this.totalCount = regDocs.active.total;
      this.archivedRegDocs = regDocs.archive.data ?? [];
      this.needAttentionRegDocs = regDocs.needAttention.data ?? [];
      this.needAttentionRegDocsTotal = regDocs.needAttention.total ?? 0;
    });

    this.searchForm
      .get('name')!
      .valueChanges.pipe(debounceTime(500))
      .subscribe(value => {
        this.handleSearch(value);
      });
  }

  handleTabClick(tabName: string) {
    document.querySelectorAll('.tab').forEach(tab => {
      tab.classList.remove('tab_active');
    });
    switch (tabName) {
      case 'tabs__need-attention':
        document
          .querySelector('.tabs__need-attention')
          ?.classList.add('tab_active');
        this.currentTab = 'tabs__need-attention';
        break;
      case 'tabs__actual':
        document.querySelector('.tabs__actual')?.classList.add('tab_active');
        this.currentTab = 'tabs__actual';
        break;
      case 'tabs__archive':
        document.querySelector('.tabs__archive')?.classList.add('tab_active');
        this.currentTab = 'tabs__archive';
        break;
    }
  }

  onAddClick(): void {
    this.isCreate = true;
    this.searchDocuments();
    this.modalService.open('create-reg-doc-modal');
  }

  get name(): FormControl<string> {
    return this.searchForm.controls.name;
  }

  private checkErrors(): boolean {
    const values = this.createRegDocsForm.value;
    let result = true;

    if(this.files.length == 0){
      this.fileUploadError = 'Список вложений не может быть пустым';
      result = false;
    }

    if (
      values.title.length === 0 ||
      values.title.replace(' ', '').length === 0
    ) {
      this.titleError = 'Полное наименование не должно быть пустым';
      result = false;
    }
    return result;
  }

  public resetErrors(): void {
    this.titleError = '';
  }

  public onFormSubmit() {
    const values = this.createRegDocsForm.value;
    if (!this.checkErrors()) return;
    if (this.isCreate) {
      const requestData = {
        title: values.title,
        note: '',
        activityStart: values.activityStart
          ? new Date(values.activityStart).toISOString()
          : null,
        activityEnd: values.activityEnd
          ? new Date(values.activityEnd).toISOString()
          : null,
        documentsIds: this.selectedDocuments.map(doc => doc.id),
      };
      const form = ParseDataToFormHelper<CreateDocSampleDto>(
        requestData,
        this.files
      );
      this.regDocsService.createRegDoc(form).subscribe({
        next: (result: Document) => {
          this.regDocs = [result].concat(this.regDocs);
          this.modalService.close();
          this.alertService.toggle('Документ добавлен');
          this.createRegDocsForm.reset();
        },
        error: err => console.error(err),
      });
    } else {
      const requestData = {
        title: values.title,
      };
      this.regDocsService
        .updateRegDoc(this.selectedDocument.id, requestData)
        .subscribe({
          next: (result: Document) => {
            const index = this.regDocs.findIndex(
              regDoc => regDoc.id === result.id
            );
            if (index >= 0) {
              this.regDocs = this.regDocs.filter(
                regDoc => regDoc.id !== result.id
              );
            }
            this.regDocs = [result].concat(this.regDocs);
            this.modalService.close();
            this.resetErrors();
            this.alertService.toggle('Документ изменен');
            this.createRegDocsForm.reset();
          },
          error: err => console.error(err),
        });
    }
  }

  public onFileSelected(event: any): void {
    this.files = [...event.target.files];
    this.fileUploadError = '';
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files: File[] = Array.from(event.dataTransfer.files);

      for (const file of files) {
        if (!this.files.some((e: File) => e.name === file.name)) {
          this.files.push(file);
        }
      }
    }
  }

  public removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  public handleUpdateClick(document: Document): void {
    this.isCreate = false;
    this.selectedDocument = document;
    const version = this.getActualVersion(document) as DocumentVersion;
    this.createRegDocsForm.setValue({
      title: document.title,
      activityStart: version.activityStart,
      activityEnd: version.activityEnd,
    });
    this.resetErrors();
    this.modalService.open('create-reg-doc-modal');
  }

  public handleDeleteClick(id: number, name: string): void {
    const isAccepted = confirm(
      `Вы действительно хотите удалить документ "${name}"?`
    );

    if (isAccepted) {
      this.regDocsService.deleteRegDoc(id).subscribe({
        next: () => {
          this.regDocs = this.regDocs.filter(document => document.id !== id);
          this.archivedRegDocs = this.archivedRegDocs.filter(
            document => document.id !== id
          );
          this.alertService.toggle('Документ удален');
        },
        error: err => console.error(err),
      });
    } else return;
  }

  public handleSearch(query: string): void {
    if (!this.isSearchBlocked) {
      this.isSearchBlocked = true;
      this.regDocsService
        .getRegDocs({
          name: query,
        })
        .subscribe({
          next: (regDocsList: DocumentsList) => {
            this.regDocs = regDocsList.active.data;
            this.totalCount = regDocsList.active.total;
            this.archivedRegDocs = regDocsList.archive.data;
            this.isSearchBlocked = false;
          },
          error: (err: Error) => {
            console.error(err);
            this.isSearchBlocked = false;
          },
        });
    }
  }

  ngAfterViewInit() {
    document
      .querySelector('.' + this.tabs[0].class)
      ?.classList.add('tab_active');
  }

  handlePressEnter(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.onFormSubmit();
  }

  public handleShowClick(id: number): void {}
  public handleEditClick(id: number): void {}

  public getActualVersion = getDocumentActualVersionHelper;
  protected readonly getDocumentActualVersionHelper =
    getDocumentActualVersionHelper;


  suggestionDocuments = new Array<Document>();
  selectedDocuments = new Array<Document>();
  
  searchDocuments(name: string = ''){
    this.regDocsService
      .getRegDocs({
          name,
          from: 0,
          to: 5,
      })
      .subscribe({
          next: (documents: DocumentsList) => {
            const selectedIds = this.selectedDocuments.map((doc) => doc.id);
            this.suggestionDocuments = this.relatedDocuments = documents.active.data.filter(
              doc => !selectedIds.includes(doc.id)
            );
          },
          error: (err: Error) => console.error(err),
      });
  }
  
  getDocumentName(doc: Document){
    return doc.title;
  }
  
  addDocument(doc: Document){
    if (this.selectedDocuments.indexOf(doc) < 0) {
      this.selectedDocuments.push(doc);
      this.suggestionDocuments.splice(this.suggestionDocuments.indexOf(doc), 1);
    }
  }
  
  removeDocument(doc: Document){
    if(this.selectedDocuments.indexOf(doc) >= 0){
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(doc), 1);
      this.suggestionDocuments.push(doc);
    }
  }
}
