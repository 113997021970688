import { Injectable, Injector, ViewChild } from '@angular/core';
import { LoaderComponent } from '@core/components';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isShowing: boolean = false;
  private element: HTMLElement = document.getElementById(
    'loader'
  ) as HTMLElement;

  constructor(private readonly injector: Injector) {}

  public open(): void {
    if (this.isShowing) return;
    this.injector.get<LoaderComponent>(LoaderComponent).open();
    this.element.style.display = 'block';
    this.isShowing = true;
  }

  public close(): void {
    this.element.style.display = 'none';
    this.isShowing = false;
  }
}
