<div class="services-ui">
  <div class="top-div">
    <h1 class="title-bold-h1">Услуги</h1>
    <div class="top-div__buttons">
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="inverse"
        color="inverse-container"
        sizeText="default"
        buttonContent="Создать из услуги"
        (click)="onCloneClick()"></app-button>
      <app-button
        size="normal"
        type="left"
        icon="add_circle"
        [fill]="true"
        display="fill"
        color="primary"
        sizeText="default"
        buttonContent="Добавить"
        (click)="handleModalOpen('create-service-modal')"></app-button>
    </div>
  </div>
  @if (isFiltered) {
    <div class="search-zone-with-filter">
      <div class="search-zone-with-filter__button">
        <app-button
          size="normal"
          type="left"
          icon="page_info"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          buttonContent="Фильтры"
          (click)="handleModalOpen('filter-service-modal')"></app-button>
      </div>
      <div class="search-zone-with-filter__button">
        <app-button
          size="normal"
          type="left"
          icon="delete_forever"
          [fill]="true"
          display="inverse"
          color="primary"
          sizeText="default"
          buttonContent="Сбросить"
          (click)="handleFiltersResetClick()"></app-button>
      </div>
      <div class="search-zone-with-filter__input area_white_bg">
        <core-text-input
          placeholder="Поиск услуги"
          leftIcon="search"
          [formControl]="control('name', 'search')"></core-text-input>
      </div>
    </div>
  } @else {
    <div class="search-zone">
      <div class="search-zone__button">
        <app-button
          size="normal"
          type="left"
          icon="page_info"
          [fill]="true"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          buttonContent="Фильтры"
          (click)="handleModalOpen('filter-service-modal')"></app-button>
      </div>
      <div class="search-zone__input area_white_bg">
        <core-text-input
          placeholder="Поиск услуги"
          leftIcon="search"
          [formControl]="control('name', 'search')"></core-text-input>
      </div>
    </div>
  }
  <div class="tabs-and-filter">
    <div class="tabs">
      @for (tab of tabs; track tab) {
        <button
          class="tab"
          [ngClass]="tab.class"
          (click)="handleTabClick(tab.class)">
          {{ tab.title }}
        </button>
      }
    </div>
    <!-- <div class="tabs-and-filter-dropdown">
      @if (!isOrderOpened) {
        <button class="filter" (click)="handleChangeOrderClick()">
          {{ selectedOrder.name }}
          <span class="material-symbols-outlined"> keyboard_arrow_down </span>
        </button>
      } @else {
        <button class="filter" (click)="handleChangeOrderClick(selectedOrder)">
          {{ selectedOrder.name }}
          <span class="material-symbols-outlined"> keyboard_arrow_up </span>
        </button>
        <div class="tabs-and-filter-dropdown-content">
          @for (order of orders; track order) {
            <button class="filter" (click)="handleChangeOrderClick(order)">
              {{ order.name }}
            </button>
          }
        </div>
      }
    </div> -->
  </div>
</div>
<div class="services">
  @switch (currentTab) {
    @case ('tabs__archive') {
      <app-services-page-list
        [services]="archivedServices"
        (edit)="openUpdateModal($event)"></app-services-page-list>
    }
    @case ('tabs__actual') {
      <app-services-page-list
        [services]="services"
        (edit)="openUpdateModal($event)"></app-services-page-list>
    }
    @case ('tabs__need-attention') {
      <app-attention [attentionServices]="needAttention"></app-attention>
    }
  }
</div>

<app-modal
  class="area_white_bg"
  id="filter-service-modal"
  width="mini"
  heading="Фильтры услуг"
  [requestConfirmToCancel]="searchForm.dirty"
  (submitAction)="handleSearchSubmitClick()"
  (cancelAction)="handleSearchResetClick()">
  <div class="modal__general-period" style="margin-top: 0">
    <p class="modal__input-title title-grid1">Дата начала активности</p>
    <core-date-input
      class="input-grid1"
      [formControl]="control('activityStart', 'search')"></core-date-input>
    <p class="modal__input-title title-grid2">Дата окончания активности</p>
    <core-date-input
      class="input-grid2"
      [formControl]="control('activityEnd', 'search')"></core-date-input>
  </div>
  <div class="modal__search_general">
    <app-input
      type="checkbox"
      label="Не предоставляется для ТОСП"
      [control]="control('isNotForTosp', 'search')"
      (click)="resetErrors()"
      labelVersion="regular"></app-input>
  </div>
  <div class="modal__search_general">
    <app-input
      type="checkbox"
      label="Не предоставляется для УРМ"
      [control]="control('isNotForUrm', 'search')"
      (click)="resetErrors()"
      labelVersion="regular"></app-input>
  </div>

  <div class="modal__search_title">Способы предоставления</div>
  <div class="modal__search_general">
    <app-input
      type="checkbox"
      label="Федеральный"
      (click)="resetErrors()"
      labelVersion="regular"
      (changeEvent)="
        handleSelectServiceType(ServicesTypesEnum.Federal)
      "></app-input>
  </div>
  <div class="modal__search_general">
    <app-input
      type="checkbox"
      label="Региональный"
      (click)="resetErrors()"
      labelVersion="regular"
      (changeEvent)="
        handleSelectServiceType(ServicesTypesEnum.Regional)
      "></app-input>
  </div>
  <div class="modal__search_general">
    <app-input
      type="checkbox"
      label="Муниципальный"
      (click)="resetErrors()"
      labelVersion="regular"
      (changeEvent)="
        handleSelectServiceType(ServicesTypesEnum.Municipal)
      "></app-input>
  </div>

  @for (classifierType of classifiersTypes; track classifierType.key) {
    <div class="modal__classifier-element">
      <div class="modal__classifier-element-header">
        <div class="modal__classifier-element-header-title">
          <span>{{ classifierType.name }}</span>

          <app-badge
            class="modal__classifier-element-header-title-badge"
            size="small"
            display="tint"
            color="secondary-container"
            size_text="default"
            badge_content="{{ countFilters.get(classifierType.key) ?? 0 }}" />
        </div>
        <div class="modal__classifier-element-header-open-icon">
          @if (classifierType.opened) {
            <app-icon-button
              size="normal"
              type="square"
              icon="keyboard_arrow_up"
              display="fill"
              color="container-lowest"
              (click)="toggleOpened(classifierType.key)">
            </app-icon-button>
          } @else {
            <app-icon-button
              size="normal"
              type="square"
              icon="keyboard_arrow_down"
              display="fill"
              color="container-lowest"
              (click)="toggleOpened(classifierType.key)">
            </app-icon-button>
          }
        </div>
      </div>
      <div style="display: {{ classifierType.opened ? 'block' : 'none' }}">
        @if (getClassifiersListByType(classifierType.key).length) {
          <div class="modal__classifier-element-list">
            @for (
              classifier of getClassifiersListByType(classifierType.key);
              track classifier
            ) {
              <div class="modal__classifier-element-list-row">
                <app-input
                  type="checkbox"
                  label="{{ classifier.name }}"
                  labelVersion="regular"
                  (changeEvent)="
                    handleSelectSearchClassifierClick(
                      classifierType.key,
                      classifier
                    )
                  "></app-input>
              </div>
            }
            @if (
              getClassifiersTotalByType(classifierType.key) >
              getClassifiersListByType(classifierType.key).length
            ) {
              <app-button
                size="normal"
                type="left"
                icon="add_circle"
                [fill]="true"
                display="fill"
                color="neutral-40"
                sizeText="default"
                buttonContent="Показать больше"
                (click)="showMoreClick(classifierType)">
              </app-button>
            }
          </div>
        } @else {
          <p>Классификаторы не найдены</p>
        }
      </div>
    </div>
  }
</app-modal>

<app-modal
  id="create-service-modal"
  heading="Новая услуга"
  [requestConfirmToCancel]="createServiceForm.dirty"
  (submitAction)="onFormSubmit()"
  (cancelAction)="resetForm()">
  <div class="modal">
    <div class="tabs tabs_border">
      <button
        class="tab"
        [ngClass]="{ tab_active: isAbout }"
        (click)="isAbout = true">
        Об услуге
      </button>
      <button
        class="tab"
        [ngClass]="{ tab_active: !isAbout }"
        (click)="isAbout = false">
        Справка
      </button>
    </div>
    @if (isAbout) {
      <form [formGroup]="createServiceForm">
        <h5
          class="title-bold-h5 modal__title modal__title_info modal__title_relations">
          1. Общая информация
        </h5>
        <div class="modal__general csm_container area_beige_bg">
          <p class="modal__input-title">Полное наименование услуги</p>
          <div class="modal__search_general modal__search_general_multiline">
            <core-textarea-input
              placeholder="Полное наименование услуги"
              [formControlName]="'name'"
              (click)="resetErrors()"
              [error]="nameError"></core-textarea-input>
          </div>
          <p class="modal__input-title">Краткое наименование</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Краткое наименование"
              [formControlName]="'title'"
              (click)="resetErrors()"
              [error]="titleError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <p class="modal__input-title">Номер СИЭР</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Номер СИЭР"
              [formControlName]="'sierNumber'"
              (click)="resetErrors()"
              [error]="titleError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <div class="modal__general-period">
            <p class="modal__input-title title-grid1">Дата начала активности</p>
            <core-date-input
              class="input-grid1"
              [formControlName]="'activityStart'"></core-date-input>
            <p class="modal__input-title title-grid2">
              Дата окончания активности
            </p>
            <core-date-input
              class="input-grid2"
              [formControlName]="'activityEnd'"></core-date-input>
          </div>
          <p class="modal__input-title">Способ предоставления</p>
          <div class="modal__general-service-types">
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Federal
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Federal,
                  'create'
                )
              ">
              Федеральный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Regional
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Regional,
                  'create'
                )
              ">
              Региональный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Municipal
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Municipal,
                  'create'
                )
              ">
              Муниципальный
            </div>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для ТОСП"
              [control]="control('isNotForTosp')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для УРМ"
              [control]="control('isNotForUrm')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
        </div>

        <div class="modal__relations">
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            2. Связи с документами
          </h5>
          <div class="modal__relations-select-container">
            <app-autocomplete
              [fill]="true"
              placeholder="Выбрать документы для связи"
              [suggestions]="suggestionDocuments"
              [getName]="getDocumentName"
              (inputValueChange)="searchDocuments($event)"
              (selected)="addDocument($event)"
            ></app-autocomplete>

            @if (documentsToAdd.length) {
              <div class="modal__relations-list">
                <app-selected-documents
                  [items]="documentsToAdd"
                  (remove)="removeDocument($event)"
                ></app-selected-documents>
              </div>
            }
          </div>
        </div>

        <div class="modal__classifiers">
          <h5 class="title-bold-h5 modal__title modal__title_info">
            3. Классификаторы
          </h5>
          @if (lifeSituations.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              <div *ngIf="selectedClassifiers.get('lifeSituations').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('lifeSituations')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['lifeSituations']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                 (selected)="addClassifier('lifeSituations', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (applicantCategories.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              <div *ngIf="selectedClassifiers.get('applicantCategories').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('applicantCategories')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['applicantCategories']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                 (selected)="addClassifier('applicantCategories', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (departments.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              <div *ngIf="selectedClassifiers.get('departments').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('departments')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('departments', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['departments']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('departments', $event)"
                 (selected)="addClassifier('departments', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (territoriality.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              <div *ngIf="selectedClassifiers.get('territoriality').length" class="badges">
                 <div *ngFor="let classifier of selectedClassifiers.get('territoriality')" class="badge">
                   <app-badge
                     badge_content="{{ classifier.name }}"
                     size="normal"
                     display="fill"
                     color="inverse-container"
                     size_text="default"
                     right_icon="cancel"
                     [fill]="true"
                     [used_right_icon]="true"
                     (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                    ></app-badge>
                 </div>
              </div>

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          }
        </div>
      </form>
    }

    @if (!isAbout) {
      <div class="ref-info">
        <nav class="left-navigation left-navigation_wysiwyg">
          <app-menu-point
            text="Общее описание"
            [shouldNavigate]="false"
            [isActive]="activeArray[0]"
            (click)="handleMenuItemClick(0)"></app-menu-point>

          <app-menu-point
            text="Порядок действий"
            [shouldNavigate]="false"
            [isActive]="activeArray[1]"
            (click)="handleMenuItemClick(1)"></app-menu-point>

          <app-menu-point
            text="Способ передачи заявления и документов"
            [shouldNavigate]="false"
            [isActive]="activeArray[2]"
            (click)="handleMenuItemClick(2)"></app-menu-point>

          <app-menu-point
            text="Уровень предоставления"
            [shouldNavigate]="false"
            [isActive]="activeArray[3]"
            (click)="handleMenuItemClick(3)"></app-menu-point>

          <app-menu-point
            text="Категория заявителей"
            [shouldNavigate]="false"
            [isActive]="activeArray[4]"
            (click)="handleMenuItemClick(4)"></app-menu-point>

          <app-menu-point
            text="Гражданство заявителя"
            [shouldNavigate]="false"
            [isActive]="activeArray[5]"
            (click)="handleMenuItemClick(5)"></app-menu-point>

          <app-menu-point
            text="Требование проживания"
            [shouldNavigate]="false"
            [isActive]="activeArray[6]"
            (click)="handleMenuItemClick(6)"></app-menu-point>

          <app-menu-point
            text="Результат приема заявления"
            [shouldNavigate]="false"
            [isActive]="activeArray[7]"
            (click)="handleMenuItemClick(7)"></app-menu-point>

          <app-menu-point
            text="Результат предоставления услуги"
            [shouldNavigate]="false"
            [isActive]="activeArray[8]"
            (click)="handleMenuItemClick(8)"></app-menu-point>

          <app-menu-point
            text="Поступление результата в МФЦ"
            [shouldNavigate]="false"
            [isActive]="activeArray[10]"
            (click)="handleMenuItemClick(10)"></app-menu-point>

          <app-menu-point
            text="Дополнительные сведения"
            [shouldNavigate]="false"
            [isActive]="activeArray[11]"
            (click)="handleMenuItemClick(11)"></app-menu-point>
        </nav>
        <div class="texts-container texts-container_wysiwyg">
          <app-wysiwyg
            heading="Общее описание"
            [control]="control('information')"
            style="{{ getWysiwygDisplay(0) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Порядок действий"
            [control]="control('informationProcedure')"
            style="{{ getWysiwygDisplay(1) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Способ передачи заявления и документов"
            [control]="control('informationTransferMethod')"
            style="{{ getWysiwygDisplay(2) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Уровень предоставления"
            [control]="control('informationProvisionMethod')"
            style="{{ getWysiwygDisplay(3) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Категория заявителей"
            [control]="control('informationApplicantCategory')"
            style="{{ getWysiwygDisplay(4) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Гражданство заявителя"
            [control]="control('informationApplicantNationality')"
            style="{{ getWysiwygDisplay(5) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Требование проживания"
            [control]="control('informationResidenceRequirement')"
            style="{{ getWysiwygDisplay(6) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат приема заявления"
            [control]="control('informationApplicationResult')"
            style="{{ getWysiwygDisplay(7) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат предоставления услуги"
            [control]="control('informationServiceResult')"
            style="{{ getWysiwygDisplay(8) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Поступление результата в МФЦ"
            [control]="control('informationReceiptOfTheResult')"
            style="{{ getWysiwygDisplay(10) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Дополнительные сведения"
            [control]="control('informationAdditional')"
            style="{{ getWysiwygDisplay(11) }}"></app-wysiwyg>
        </div>
      </div>
    }
  </div>
</app-modal>

<app-modal
  id="clone-service-modal"
  heading="Скопировать из услуги"
  [requestConfirmToCancel]="cloneServiceForm.dirty"
  (submitAction)="onCloneSubmitClick()"
  (cancelAction)="resetForm()"
  submitButtonText="Копировать">
  <div class="modal" [ngClass]="{ 'clone-modal': !serviceToClone }">
    @if (!serviceToClone) {
      <h5
        class="title-bold-h5 modal__title modal__title_info modal__title_relations">
        Выберите услугу
      </h5>
      <core-text-input
        placeholder="Поиск услуги"
        leftIcon="search"
        [formControl]="cloneSearchControl"></core-text-input>
      <ol class="clone-list">
        @for (service of servicesToClone; track service) {
          <li
            class="clone-list-element"
            (click)="handleSelectCloneServiceClick(service)">
            {{ service.name }}
          </li>
        }
      </ol>
    } @else {
      <form [formGroup]="cloneServiceForm">
        <h5
          class="title-bold-h5 modal__title modal__title_info modal__title_relations">
          1. Общая информация
        </h5>
        <div class="modal__general csm_container area_beige_bg">
          <p class="modal__input-title">Полное наименование услуги</p>
          <div class="modal__search_general modal__search_general_multiline">
            <core-textarea-input
              placeholder="Полное наименование услуги"
              [formControlName]="'name'"
              (click)="resetErrors()"
              [error]="nameError"
              (keydown.enter)="handlePressEnter($event)"></core-textarea-input>
          </div>
          <p class="modal__input-title">Краткое наименование</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Краткое наименование"
              [formControlName]="'title'"
              (click)="resetErrors()"
              [error]="titleError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <p class="modal__input-title">Номер СИЭР</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Номер СИЭР"
              [formControlName]="'sierNumber'"
              (click)="resetErrors()"
              [error]="sierError"
              (keydown.enter)="handlePressEnter($event)">
            </core-text-input>
          </div>
          <div class="modal__general-period">
            <p class="modal__input-title title-grid1">Дата начала активности</p>
            <core-date-input
              class="input-grid1"
              [formControlName]="'activityStart'"></core-date-input>
            <p class="modal__input-title title-grid2">
              Дата окончания активности
            </p>
            <core-date-input
              class="input-grid2"
              [formControlName]="'activityEnd'"></core-date-input>
          </div>
          <p class="modal__input-title">Способ предоставления</p>
          <div class="modal__general-service-types">
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Federal
              }"
              (click)="
                handleChangeServiceTypeClick(ServicesTypesEnum.Federal, 'clone')
              ">
              Федеральный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Regional
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Regional,
                  'clone'
                )
              ">
              Региональный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Municipal
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Municipal,
                  'clone'
                )
              ">
              Муниципальный
            </div>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для ТОСП"
              [control]="control('isNotForTosp', 'cloneService')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для УРМ"
              [control]="control('isNotForUrm', 'cloneService')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
        </div>

        <div class="modal__relations">
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            2. Связи с документами
          </h5>
          <div class="modal__relations-select-container">
            <app-autocomplete
              placeholder="Выбрать документы для связи"
              [suggestions]="suggestionDocuments"
              [getName]="getDocumentName"
              [fill]="true"
              (inputValueChange)="searchDocuments($event)"
              (selected)="addDocument($event)"
            ></app-autocomplete>

            @if (documentsToAdd.length) {
              <div class="modal__relations-list">
                <app-selected-documents
                  [items]="documentsToAdd"
                  (remove)="removeDocument($event)"
                ></app-selected-documents>
              </div>
            }
          </div>
        </div>

        <div class="modal__classifiers">
          <h5 class="title-bold-h5 modal__title modal__title_info">
            3. Классификаторы
          </h5>
          @if (lifeSituations.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              <div *ngIf="selectedClassifiers.get('lifeSituations').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('lifeSituations')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['lifeSituations']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                 (selected)="addClassifier('lifeSituations', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (applicantCategories.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              <div *ngIf="selectedClassifiers.get('applicantCategories').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('applicantCategories')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['applicantCategories']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                 (selected)="addClassifier('applicantCategories', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (departments.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              <div *ngIf="selectedClassifiers.get('departments').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('departments')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('departments', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['departments']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('departments', $event)"
                 (selected)="addClassifier('departments', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (territoriality.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              <div *ngIf="selectedClassifiers.get('territoriality').length" class="badges">
                 <div *ngFor="let classifier of selectedClassifiers.get('territoriality')" class="badge">
                   <app-badge
                     badge_content="{{ classifier.name }}"
                     size="normal"
                     display="fill"
                     color="inverse-container"
                     size_text="default"
                     right_icon="cancel"
                     [fill]="true"
                     [used_right_icon]="true"
                     (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                    ></app-badge>
                 </div>
              </div>

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          }
        </div>
      </form>

      <div class="element">
        <app-button
          size="small"
          type="left"
          display="fill"
          color="outline-low"
          icon="arrow_left_alt"
          sizeText="default"
          buttonContent="Выбрать другую услугу"
          (click)="handleResetCloneServiceClick()">
        </app-button>
      </div>
    }
  </div>
</app-modal>

<app-modal
  id="update-service-modal"
  heading="Обновить услугу"
  [requestConfirmToCancel]="updateServiceForm.dirty"
  (submitAction)="handleUpdateService()"
  (cancelAction)="resetForm()">
  <div class="modal">
    <div class="tabs tabs_border">
      <button
        class="tab"
        [ngClass]="{ tab_active: isAbout }"
        (click)="isAbout = true">
        Об услуге
      </button>
      <button
        class="tab"
        [ngClass]="{ tab_active: !isAbout }"
        (click)="isAbout = false">
        Справка
      </button>
    </div>
    @if (isAbout) {
      <form [formGroup]="updateServiceForm">
        <h5
          class="title-bold-h5 modal__title modal__title_info modal__title_relations">
          1. Общая информация
        </h5>
        <div class="modal__general csm_container area_beige_bg">
          <p class="modal__input-title">Полное наименование услуги</p>
          <div class="modal__search_general modal__search_general_multiline">
            <core-textarea-input
              placeholder="Полное наименование услуги"
              [formControlName]="'name'"
              (click)="resetErrors()"
              [error]="nameError"
              (keydown.enter)="handlePressEnter($event)"></core-textarea-input>
          </div>
          <p class="modal__input-title">Краткое наименование</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Краткое наименование"
              [formControlName]="'title'"
              (click)="resetErrors()"
              [error]="titleError"
              (keydown.enter)="handlePressEnter($event)"></core-text-input>
          </div>
          <p class="modal__input-title">Номер СИЭР</p>
          <div class="modal__search_general">
            <core-text-input
              placeholder="Номер СИЭР"
              [formControlName]="'sierNumber'"
              (click)="resetErrors()"
              [error]="sierError"
              (keydown.enter)="handlePressEnter($event)">
            </core-text-input>
          </div>
          <div class="modal__general-period">
            <p class="modal__input-title title-grid1">Дата начала активности</p>
            <core-date-input
              class="input-grid1"
              [formControlName]="'activityStart'"></core-date-input>
            <p class="modal__input-title title-grid2">
              Дата окончания активности
            </p>
            <core-date-input
              class="input-grid2"
              [formControlName]="'activityEnd'"></core-date-input>
          </div>
          <p class="modal__input-title">Способ предоставления</p>
          <div class="modal__general-service-types">
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Federal
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Federal,
                  'update'
                )
              ">
              Федеральный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Regional
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Regional,
                  'update'
                )
              ">
              Региональный
            </div>
            <div
              [ngClass]="{
                'modal__general-service-types-element': true,
                selected: selectedServiceType === ServicesTypesEnum.Municipal
              }"
              (click)="
                handleChangeServiceTypeClick(
                  ServicesTypesEnum.Municipal,
                  'update'
                )
              ">
              Муниципальный
            </div>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для ТОСП"
              [control]="control('isNotForTosp', 'update')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
          <div class="modal__search_general">
            <app-input
              type="checkbox"
              label="Не предоставляется для УРМ"
              [control]="control('isNotForUrm', 'update')"
              (click)="resetErrors()"
              labelVersion="regular"></app-input>
          </div>
        </div>

        <div class="modal__relations">
          <h5
            class="title-bold-h5 modal__title modal__title_info modal__title_relations">
            2. Связи с документами
          </h5>
          <div class="modal__relations-select-container">
            <app-autocomplete
              placeholder="Выбрать документы для связи"
              [suggestions]="suggestionDocuments"
              [getName]="getDocumentName"
              [fill]="true"
              (inputValueChange)="searchDocuments($event)"
              (selected)="addDocument($event)"
            ></app-autocomplete>

            @if (documentsToAdd.length) {
              <div class="modal__relations-list">
                <app-selected-documents
                  [items]="documentsToAdd"
                  (remove)="removeDocument($event)"
                ></app-selected-documents>
              </div>
            }
          </div>
        </div>

        <div class="modal__classifiers">
          <h5 class="title-bold-h5 modal__title modal__title_info">
            3. Классификаторы
          </h5>
          @if (lifeSituations.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Жизненные ситуации
              </h5>

              <div *ngIf="selectedClassifiers.get('lifeSituations').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('lifeSituations')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('lifeSituations', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['lifeSituations']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('lifeSituations', $event)"
                 (selected)="addClassifier('lifeSituations', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (applicantCategories.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Категория заявителей
              </h5>

              <div *ngIf="selectedClassifiers.get('applicantCategories').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('applicantCategories')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('applicantCategories', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['applicantCategories']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('applicantCategories', $event)"
                 (selected)="addClassifier('applicantCategories', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (departments.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Ведомства
              </h5>

              <div *ngIf="selectedClassifiers.get('departments').length" class="badges">
                <div *ngFor="let classifier of selectedClassifiers.get('departments')" class="badge">
                  <app-badge
                    badge_content="{{ classifier.name }}"
                    size="normal"
                    display="fill"
                    color="inverse-container"
                    size_text="default"
                    right_icon="cancel"
                    [fill]="true"
                    [used_right_icon]="true"
                    (cancelClickDetection)="removeClassifier('departments', classifier)"
                   ></app-badge>
                </div>
             </div>
             
             <div class="classifiers-selector">
               <app-autocomplete
                 placeholder="Найти классификатор..."
                 [suggestions]="suggestionClassifiers['departments']"
                 [getName]="getClassifierName"
                 (inputValueChange)="searchClassifiers('departments', $event)"
                 (selected)="addClassifier('departments', $event)"
               ></app-autocomplete>
             </div>
            </div>
          }
          @if (territoriality.length) {
            <div class="csm_container">
              <h5 class="modal__input-title modal__input-title_relations">
                Территориальность
              </h5>

              <div *ngIf="selectedClassifiers.get('territoriality').length" class="badges">
                 <div *ngFor="let classifier of selectedClassifiers.get('territoriality')" class="badge">
                   <app-badge
                     badge_content="{{ classifier.name }}"
                     size="normal"
                     display="fill"
                     color="inverse-container"
                     size_text="default"
                     right_icon="cancel"
                     [fill]="true"
                     [used_right_icon]="true"
                     (cancelClickDetection)="removeClassifier('territoriality', classifier)"
                    ></app-badge>
                 </div>
              </div>

              <div class="classifiers-selector">
                <app-autocomplete
                  placeholder="Найти классификатор..."
                  [suggestions]="suggestionClassifiers['territoriality']"
                  [getName]="getClassifierName"
                  (inputValueChange)="searchClassifiers('territoriality', $event)"
                  (selected)="addClassifier('territoriality', $event)"
                ></app-autocomplete>
              </div>
            </div>
          }
        </div>
      </form>
    }

    @if (!isAbout) {
      <div class="ref-info">
        <nav class="left-navigation left-navigation_wysiwyg">
          <app-menu-point
            text="Общее описание"
            [shouldNavigate]="false"
            [isActive]="activeArray[0]"
            (click)="handleMenuItemClick(0)"></app-menu-point>

          <app-menu-point
            text="Порядок действий"
            [shouldNavigate]="false"
            [isActive]="activeArray[1]"
            (click)="handleMenuItemClick(1)"></app-menu-point>

          <app-menu-point
            text="Способ передачи заявления и документов"
            [shouldNavigate]="false"
            [isActive]="activeArray[2]"
            (click)="handleMenuItemClick(2)"></app-menu-point>

          <app-menu-point
            text="Уровень предоставления"
            [shouldNavigate]="false"
            [isActive]="activeArray[3]"
            (click)="handleMenuItemClick(3)"></app-menu-point>

          <app-menu-point
            text="Категория заявителей"
            [shouldNavigate]="false"
            [isActive]="activeArray[4]"
            (click)="handleMenuItemClick(4)"></app-menu-point>

          <app-menu-point
            text="Гражданство заявителя"
            [shouldNavigate]="false"
            [isActive]="activeArray[5]"
            (click)="handleMenuItemClick(5)"></app-menu-point>

          <app-menu-point
            text="Требование проживания"
            [shouldNavigate]="false"
            [isActive]="activeArray[6]"
            (click)="handleMenuItemClick(6)"></app-menu-point>

          <app-menu-point
            text="Результат приема заявления"
            [shouldNavigate]="false"
            [isActive]="activeArray[7]"
            (click)="handleMenuItemClick(7)"></app-menu-point>

          <app-menu-point
            text="Результат предоставления услуги"
            [shouldNavigate]="false"
            [isActive]="activeArray[8]"
            (click)="handleMenuItemClick(8)"></app-menu-point>

          <app-menu-point
            text="Уровень предоставления"
            [shouldNavigate]="false"
            [isActive]="activeArray[9]"
            (click)="handleMenuItemClick(9)"></app-menu-point>

          <app-menu-point
            text="Поступление результата в МФЦ"
            [shouldNavigate]="false"
            [isActive]="activeArray[10]"
            (click)="handleMenuItemClick(10)"></app-menu-point>

          <app-menu-point
            text="Дополнительные сведения"
            [shouldNavigate]="false"
            [isActive]="activeArray[11]"
            (click)="handleMenuItemClick(11)"></app-menu-point>
        </nav>
        <div class="texts-container texts-container_wysiwyg">
          <app-wysiwyg
            heading="Общее описание"
            [control]="control('information')"
            style="{{ getWysiwygDisplay(0) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Порядок действий"
            [control]="control('informationProcedure')"
            style="{{ getWysiwygDisplay(1) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Способ передачи заявления и документов"
            [control]="control('informationTransferMethod')"
            style="{{ getWysiwygDisplay(2) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Уровень предоставления"
            [control]="control('informationProvisionMethod')"
            style="{{ getWysiwygDisplay(3) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Категория заявителей"
            [control]="control('informationApplicantCategory')"
            style="{{ getWysiwygDisplay(4) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Гражданство заявителя"
            [control]="control('informationApplicantNationality')"
            style="{{ getWysiwygDisplay(5) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Требование проживания"
            [control]="control('informationResidenceRequirement')"
            style="{{ getWysiwygDisplay(6) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат приема заявления"
            [control]="control('informationApplicationResult')"
            style="{{ getWysiwygDisplay(7) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Результат предоставления услуги"
            [control]="control('informationServiceResult')"
            style="{{ getWysiwygDisplay(8) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Поступление результата в МФЦ"
            [control]="control('informationReceiptOfTheResult')"
            style="{{ getWysiwygDisplay(10) }}"></app-wysiwyg>
          <app-wysiwyg
            heading="Дополнительные сведения"
            [control]="control('informationAdditional')"
            style="{{ getWysiwygDisplay(11) }}"></app-wysiwyg>
        </div>
      </div>
    }
  </div>
</app-modal>
