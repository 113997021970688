@if (isShowing) {
    <div id="loader">
      <div class="alert">
        <!-- <img src="assets/icons/accept-filled-icon.svg" alt="OK"> -->
        <div class="info">
          <div class="info__header" id="loader-title">{{ title }}</div>
        </div>
        <div class="loader"></div>
      </div>
    </div>
  }
  