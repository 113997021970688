import { CommonModule,  } from '@angular/common';
import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { Document } from '@core/models';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'app-selected-documents',
  standalone: true,
  imports: [
    CommonModule,
    IconButtonComponent,
  ],
  templateUrl: './selected-documents.component.html',
  styleUrl: './selected-documents.component.scss',
})
export class SelectedDocumentsComponent {
  @Input() items = new Array<Document>();
  @Output() remove = new EventEmitter<Document>();

  getActivity(document: Document): string {
    const activeVersion = document.versions.find((v) => v.actual);
    if(activeVersion && activeVersion.activityStart && activeVersion.activityEnd){
      return `с ${new Date(activeVersion.activityStart).toLocaleDateString()} до ${new Date(activeVersion.activityEnd).toLocaleDateString()}`
    }
    return 'Неограниченное действие';
  }

}
