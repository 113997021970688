import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@core/models';
import {
  CreateUserDto,
  SearchUsersDto,
  UpdateUserDto,
} from '@core/models/dto';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private readonly http: HttpClient) {}

  getUsers(searchDto: SearchUsersDto): Observable<User[]> {
    const params = new URLSearchParams();
    if (searchDto?.name) {
      params.set('name', searchDto.name);
    }

    let uri = '/admin/users';
    if (params.size) uri += `?${params.toString()}`;
    return this.http.get<User[]>(uri);
  }

  createUser(createUserDto: CreateUserDto): Observable<User> {
    return this.http.post<User>(`/admin/users`, createUserDto);
  }

  updateUser(id: number, updateUserDto: UpdateUserDto): Observable<User> {
    return this.http.put<User>(`/admin/users/${id}`, updateUserDto);
  }

  deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(`/admin/users/${id}`);
  }
}
